import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

declare function showDialog6(): any;
declare function showDialog3(): any;
@Component({
  selector: 'app-branchadd',
  templateUrl: './branchadd.component.html',
  styleUrls: ['./branchadd.component.scss']
})
export class BranchaddComponent implements OnInit {

  branch_name = new FormControl('');
  email = new FormControl('');
  mobile_no = new FormControl('');
  gst_no = new FormControl('');
  branch_type = new FormControl('');
  address1 = new FormControl('');
  address2 = new FormControl('');
  dist = new FormControl('');
  state = new FormControl('');
  pin_code = new FormControl('');

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  branchTypeList: any;
  roletooltip: any;
  roleList: any;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
  ) {
    this.branchTypeList = [];
    this.roleList = [];
    this.getBranchTypeList();
   }

  ngOnInit(){
  }

  getBranchTypeList(){
    this.spinner.show();
    this.dataService.GetList('branch-types').subscribe((data) => {
      const anyData = data as any;
      this.branchTypeList = anyData.branches;
      this.spinner.hide();
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }



  onSubmit(form: NgForm) {
    if(!this.branch_name.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Branch Name is Required'
      });
    }else if(!this.mobile_no.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Mobile Number is Required'
      });
    }else if(!this.address1.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Address 1 is Required'
      });
    }else if(!this.dist.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'District is Required'
      });
    }else if(!this.state.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'State is Required'
      });
    }else{
      const datatosend = {
        "branch_name":this.branch_name.value,
        "gst_no":this.gst_no.value,
        "email":this.email.value,
        "mobile_no":this.mobile_no.value,
        "branch_type": this.branch_type.value,
          "address":
            {
            "address1":this.address1.value,
            "address2":this.address2.value,
            "state":this.state.value,
            "pin_code":this.pin_code.value,
            "dist":this.dist.value
            }
          
        }
        this.spinner.show();
            this.dataService.Create('branchs',datatosend).subscribe((data) => {
              this.spinner.hide();
              const anyData = data as any;
              if(anyData.status === 200){
                form.reset();
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Branch Created Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
              // showDialog6();
            }, error => {
              this.spinner.hide();
              Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'Please Try Again'
              });
            });
    }
  }



}
