import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

declare function showDialog6(): any;
declare function showDialog3(): any;

@Component({
  selector: 'app-addoperator',
  templateUrl: './addoperator.component.html',
  styleUrls: ['./addoperator.component.scss']
})
export class AddoperatorComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;

  first_name = new FormControl('');
  last_name = new FormControl('');
  gender = new FormControl('');
  mobile_no = new FormControl('');
  email = new FormControl('');
  dob = new FormControl('');
  password = new FormControl('');
  address1 = new FormControl('');
  address2 = new FormControl('');
  dist = new FormControl('');
  state = new FormControl('');
  pin_code = new FormControl('');
  branch = new FormControl('');
  branch_type = new FormControl('');
  operator_type = new FormControl('');
  status = new FormControl(''); 
  user_mobile: string;
  user_otp: string;

  branchList: any;
  branchTypeList: any;
  roleList: any;

  roleTooltip: any;

  

   constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
  ) {
    this.branchList = [];
    this.roleList = [];
    this.getBranchList();
    this.getRoleList();
    // this.getBranchTypeList();
   }

  ngOnInit(){
   
  }

  setTooltip(){
    this.roleTooltip = '';
    this.roleList.forEach(element => {
      if(element.id == this.operator_type.value){        
        this.roleTooltip = element.description;
      }
    });
  }

  getBranchList(){
    this.spinner.show();
    this.dataService.GetList('branchs').subscribe((data) => {
      const anyData = data as any;
      this.branchList = anyData.branchs;
      this.spinner.hide();
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

  getBranchTypeList(){
    this.spinner.show();
    this.dataService.GetList('branchs/branch-types').subscribe((data) => {
      const anyData = data as any;
      this.branchTypeList = anyData.branches;
      this.spinner.hide();
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

  getRoleList(){
    this.spinner.show();
    this.dataService.GetList('operators/operator/roles').subscribe((data) => {
      const anyData = data as any;
      this.roleList = anyData.roles;
      this.spinner.hide();
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }





  onSubmit(form: NgForm) {
    // alert(this.first_name.value);

    if(!this.first_name.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'First Name is Required'
      });
    }else if(!this.mobile_no.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Mobile No. is Required'
      });
    }else if(!this.email.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Email is Required'
      });
    }else if(!this.password.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Password is Required'
      });
    }else if(!this.address1.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Address 1 is required'
      });
    }else if(!this.dist.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'District is Required'
      });
    }else if(!this.state.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'State is Required'
      });
    }else if(!this.branch.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Select Branch'
      });
    }else if(!this.operator_type.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Select Operator Type'
      });
    }else {
      // this.spinner.show();
                // this.dataService.Create('verify-mobile-by-otp', otpdata).subscribe((data) => {
                //   const otpData = data as any;
                //   this.spinner.hide();
                //   if(otpData.status === 200){
                    const datatosend = {
                      "first_name": this.first_name.value,
                      "last_name": this.last_name.value,
                      "gender": this.gender.value,
                      "password": this.password.value,
                      "email": this.email.value,
                      "dob": this.dob.value,
                      "mobile_no":this.mobile_no.value,
                      "operator_type": this.operator_type.value,
                      "branch_id": this.branch.value,
                      "address":
                        {
                        "address1":this.address1.value,
                        "address2":this.address2.value,
                        "state":this.state.value,
                        "pin_code":this.pin_code.value,
                        "dist": this.dist.value
                        }
                      
                    }
                    this.spinner.show();
                    this.dataService.Create('operators',datatosend).subscribe((data) => {
                      this.spinner.hide();
                      const anyData = data as any;
                      if(anyData.status === 200){
                        form.reset();
                        Swal.fire({
                          icon: 'success',
                          title: 'Success',
                          text: 'Your Account Created Successfully'
                        });
                      }else{
                        Swal.fire({
                          icon: 'error',
                          title: 'Error',
                          text: anyData.error[Object.keys(anyData.error)[0]]
                        });
                      }
                      
                    }, error => {
                      this.spinner.hide();
                      Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Network Error, Please try again'
                      });
                    });
                  // }else{
                  //   Swal.fire({
                  //     icon: 'error',
                  //     title: 'Error',
                  //     text: otpData.error[Object.keys(otpData.error)[0]]
                  //   });
                  // }
                // }, error => {
                //   this.spinner.hide();
                //   Swal.fire({
                //     icon: 'error',
                //     title: 'Error',
                //     text: 'Network Error, Please try again'
                //   });
                // });

    // Swal.fire({
    //   title: 'Enter Valid Mobile No.',
    //   text: 'An OTP will be send to this Mobile',
    //   input: 'text',
    //   inputAttributes: {
    //     autocapitalize: 'off'
    //   },
    //   showCancelButton: true,
    //   confirmButtonText: 'Send OTP',
    //   showLoaderOnConfirm: true,
    //   allowOutsideClick: false,
    //   preConfirm: (mobile) => {
    //     this.user_mobile = mobile;
        
    //     const mobiledata = {
    //       "first_name" : this.first_name.value,
    //       "mobile_no" : mobile,
    //       "type" : "operator"
    //     };
    //     this.spinner.show();
    //     this.dataService.Create('send-otp-by-mobile', mobiledata).subscribe((data) => {
    //       const mobileData = data as any;
    //       this.spinner.hide();
    //       if(mobileData.status === 200){
    //         Swal.fire({
    //           title: 'Enter OTP',
    //           text: 'Check your mobile for OTP',
    //           input: 'text',
    //           inputAttributes: {
    //             autocapitalize: 'off'
    //           },
    //           timer: 300000,
    //           timerProgressBar: true,
    //           showCancelButton: false,
    //           confirmButtonText: 'Verify',
    //           showLoaderOnConfirm: true,
    //           allowOutsideClick: false,
    //           preConfirm: (otp) => {
    //             // this.dataService.GetList
    //             this.user_otp = otp;
    //             const otpdata = {
    //               "mobile_no" : this.user_mobile,
    //               "otp" : otp
    //             };
                
    //           }
              
    //         })
    //       }else{        
    //         Swal.fire({
    //           icon: 'error',
    //           title: 'Error',
    //           text: mobileData.error[Object.keys(mobileData.error)[0]]
    //         });

    //       }
    //     }, error => {
    //       this.spinner.hide();
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Error',
    //         text: 'Network Error, Please try again'
    //       });
    //     });

    //     // return fetch('https://twenty20.w3weavers.com/api/send-otp-by-email',
    //     //   {
    //     //     headers : {
    //     //       'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE',
    //     //       'Accept': 'application/json',
    //     //       'Content-Type': 'application/json'
    //     //     },
    //     //     method: "POST",
    //     //     body: JSON.stringify(datatosend)
    //     //   }
    //     // )
    //     //   .then(response => {
    //     //     // console.log(response[0]);
    //     //     if (!response.ok) {
    //     //       throw new Error(response.statusText+'Check Email')
    //     //     }
    //     //      return response.json()
    //     //   })
    //     //   .catch(error => {
    //     //     Swal.showValidationMessage(
    //     //       `Request failed: ${error}`
    //     //     )
    //     //   })
    //   }
    // })
    // .then((result) => {
      // if (result.isConfirmed) {

        // Swal.fire({
        //   title: 'Enter OTP',
        //   text: 'Check your email for OTP',
        //   input: 'text',
        //   inputAttributes: {
        //     autocapitalize: 'off'
        //   },
        //   timer: 300000,
        //   timerProgressBar: true,
        //   showCancelButton: false,
        //   confirmButtonText: 'Verify',
        //   showLoaderOnConfirm: true,
        //   allowOutsideClick: false,
        //   preConfirm: (login) => {
        //     // this.dataService.GetList
        //     const datatosend = {
        //       "email" : this.user_email,
        //       "otp" : login
        //     };
        //     return fetch('https://twenty20.w3weavers.com/api/verify-email-by-otp',
        //       {
        //         headers : {
        //           'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE',
        //           'Accept': 'application/json',
        //           'Content-Type': 'application/json'
        //         },
        //         method: "POST",
        //         body: JSON.stringify(datatosend)
        //       }
        //     )
        //       .then(response => {
        //         console.log(response.status);
        //         if (!response.ok) {
        //           throw new Error(response.statusText)
        //         }
        //         return response.json()
        //       })
        //       .catch(error => {
        //         Swal.showValidationMessage(
        //           `Request failed: ${error}`
        //         )
        //       })
        //   }
          
        // })
        // .then((result) => {
          // if (result.isConfirmed) {


            
    // const datatosend = {
    //   "f_name": this.first_name.value,
    //   "l_name": this.last_name.value,
    //   "gender": this.gender.value,
    //   "password": this.password.value,
    //   "dob": this.dob.value,
    //   "email":this.user_email,
    //   "mobile_no": this.mobile_no.value,
    //   "status": this.status.value,
    //   "operator_type": this.operator_type.value,
    //   "branch_id": this.branch.value,
    //   "address":
    //     {
    //     "address1":this.address1.value,
    //     "address2":this.address2.value,
    //     "state":this.state.value,
    //     "pin_code":this.pin_code.value,
    //     "dist": this.dist.value
    //     }
      
    // }
    // this.spinner.show();
    // this.dataService.Create('operators123',datatosend).subscribe((data) => {
    //   this.spinner.hide();
    //   const anyData = data as any;
    //   if(anyData.status === 200){
    //     Swal.fire({
    //       icon: 'success',
    //       title: 'Success',
    //       text: 'Your Account Created Successfully'
    //     });
    //   }else{
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Error',
    //       text: 'Duplicate Email or Mobile Number'
    //     });
    //   }
      
    // }, error => {
    //   this.spinner.hide();
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Error',
    //     text: 'Network Error, Please try again'
    //   });
    // });
          // }
        // })

        // Swal.fire({
        //   title: `${result.value.login}'s avatar`,
        //   imageUrl: result.value.avatar_url
        // })
      // }
    // })
  }







    // const datatosend = {
    //   "f_name":form.value.f_name,
    //   "l_name":form.value.l_name,
    //   "gender":form.value.gender,
    //   "password":form.value.password,
    //   "dob":form.value.dob,
    //   "email":form.value.email,
    //   "mobile_no":form.value.mobile_no,
    //   "status":form.value.status,
    //   "operator_type":form.value.operator_type,
    //   "branch_id": form.value.branch_id,
    //   "addresses":[
    //     {
    //     "address1":form.value.addraddress1,
    //     "address2":form.value.addraddress2,
    //     "state":form.value.addrstate,
    //     "pin_code":form.value.addrpin_code,
    //     "dist":form.value.addrdist,
    //     "mobile_no":form.value.addrmobile_no
    //     }
    //   ]
    // }
    // this.spinner.show();
    //     this.dataService.Create('operators',datatosend).subscribe((data) => {
    //       this.spinner.hide();
    //       showDialog6();
    //     }, error => {
    //       this.spinner.hide();
    //       showDialog3();
    //     });

  }



}
