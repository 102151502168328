import { DataService } from './../../services/data.service';

import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { EnvService } from 'src/app/services/env.service';


declare function showDialog6(): any;
declare function showDialog3(): any;
declare function showDialog11(): any;
declare function price_in_words(invoice_amount): any;


@Component({
  selector: 'app-billingmlm',
  templateUrl: './billingmlm.component.html',
  styleUrls: ['./billingmlm.component.scss']
})
export class BillingmlmComponent {


  productsList: any;
  selectedProductList: any;
  selectedKeywordList: any;
  selectedProductId: any;
  selectedStocksList : any;
  price_in_word: any;
  today_date = new Date();

  user_used_bv_amount : any = 0;
  verified_bv_amount: any = 0;
  user_cash_amount: any;
  user_first_name: any;
  user_id: any;
  user_lft: any;
  user_rgt: any;
  user_level_at: any;
  user_parent_id: any;
  user_active: any;
  user_referal_code: any;
  user_invoice_no: any;
  is_submit: boolean = true;
  is_print: boolean = false;
  invoice_remarks: string = "";



  is_preview: boolean = false;
  is_user_searched: boolean = false;

  // itemsForm : FormGroup;
  itemsList: any;
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  // name = 'Angular';
  categoryList: any;
  category_id: any;
  subcategory_id: any;
  subcategoryList: any;
  productList: any;
  brandList: any;
  supplierList: any;
  selectedItemList: any;
  mlm_user_mobile_no: any;

  invoice_name: string;
  invoice_mobile: string;
  invoice_email: string;
  invoice_address: string;
  invoice_user_detail: any;
  invoice_usable_bv_amount: any = 0;
  invoice_used_bv_amount: any;

  branch_address: any;
  branch_mobile: any;
  branch_email: any;
  branch_gst: any;
  branch_detail: any;

  total_selling_price: number = 0;
  total_net_price: number = 0;
  total_bv: number = 0;
  billing_mobile_no: string;
  total_cgst: number = 0;
  total_sgst: number = 0;
  total_discount: number = 0;
  total_mrp: number = 0;

  itemqty: any;
  itemdiscount: any;
  
  imageurl: any;
  message: string;
  public imagePath;

  constructor(private fb:FormBuilder, private dataService: DataService, private spinner: NgxSpinnerService, private envService: EnvService) {
    
    this.productsList = [];
    this.itemsList = [];

    this.itemqty = [];
    this.itemdiscount = [];
    this.selectedStocksList = [];

    this.categoryList = [];
    this.subcategoryList = [];
    this.brandList = [];
    this.supplierList = [];
    this.productList = [];
    this.selectedProductList = [];
    this.selectedKeywordList = [];
    this.invoice_user_detail = [];
    this.branch_detail = [];
    this.getProductCategoriesList();
    this.getSuppliersList();
    this.getBrandsList();
    this.getBranchDetail();
    console.log(price_in_words('123456'));
    // const resources = fetch('http://localhost:8888/resources.json');
    // sessionStorage.setItem('resorces', JSON.stringify(resources));
    // this.addProduct();
    this.getProductByKeyword();
  }

  getBrandsList(){
    this.dataService.GetList('brands').subscribe((data) => {
      const anyData = data as any;
      this.brandList = anyData.brands;
    });
    // alert(id);
  }

  searchProduct(){

  }

  getBranchDetail(){
    //loggedInOperatorId
    this.spinner.show();
    this.dataService.GetList('operators/profile').subscribe((data) => {
      const anyData = data as any;
      this.branch_detail = anyData.operator.branch;
      this.branch_address = this.branch_detail.address1 + ", " + this.branch_detail.address2 + ", "  + this.branch_detail.dist + ", " + this.branch_detail.state + ", PIN : " + this.branch_detail.pin_code;
      this.branch_mobile = this.branch_detail.mobile_no;
      this.branch_email = this.branch_detail.email;
      this.branch_gst = this.branch_detail.gst_no;

      
     
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  
  }


  getSuppliersList(){
    this.dataService.GetList('suppliers').subscribe((data) => {
      const anyData = data as any;
      this.supplierList = anyData.suppliers;
    });
    // alert(id);
  }

  verifyBVAmount(){

    const datatosend = {
      "first_name" : this.user_first_name,
      "mobile_no" : this.mlm_user_mobile_no
    };

this.spinner.show();
this.dataService.Create('send-otp-for-bv-transaction', datatosend).subscribe((data) => {
  const anyData = data as any;
  this.spinner.hide();
  if(anyData.status === 200){
    Swal.fire({
      title: 'Enter OTP',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      timer: 300000,
      timerProgressBar: true,
      showCancelButton: false,
      confirmButtonText: 'Verify',
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: (otp) => {       
          const datatosendotp = {
            "mobile_no" : this.mlm_user_mobile_no,
            "otp" : otp
          };
          this.spinner.show();
          this.dataService.Create('verify-mobile-by-otp', datatosendotp).subscribe((data) => {
            const otpData = data as any;
            this.spinner.hide();
            if(otpData.status === 200){              
              this.verified_bv_amount = this.user_used_bv_amount;
              this.user_cash_amount = this.total_net_price - this.verified_bv_amount;

            }else{
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: otpData.error[Object.keys(otpData.error)[0]]
              });
            }
          }, error => {
            this.spinner.hide();
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Network Error, Please try again'
            });
          });
      }                
    })
  }else{        
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: anyData.error[Object.keys(anyData.error)[0]]
    });  
  }
}, error => {
  this.spinner.hide();
  Swal.fire({
    icon: 'error',
    title: 'Error',
    text: 'Network Error, Please try again'
  });
});

    // this.dataService.GetList('suppliers').subscribe((data) => {
    //   const anyData = data as any;
    //   this.supplierList = anyData.suppliers;
    // });

    // this.verified_bv_amount
    // this.user_used_bv_amount
  }

 

  getSelectedProductCategory(id: number){
    this.category_id = id;
    this.spinner.show();
    this.dataService.GetDetails('subcategories-by-category/', id).subscribe((data) => {
      const anyData = data as any;
      this.subcategoryList = anyData.subcategories;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
    // alert(id);
  }

  getSelectedProductSubcategory(id: number){
    this.subcategory_id = id;
    this.category_id = id;
    this.spinner.show();
    this.dataService.GetDetails('products-by-subcategory/', id).subscribe((data) => {
      const anyData = data as any;
      this.productList = anyData.products;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
    // alert(id);
  }

  getSelectedProduct(product_name){
    this.productsList = [];
    // alert(product_name);
    for(let i=0; i<this.productList.length; i++){
      if(this.productList[i].name === product_name){
        this.selectedProductId = this.productList[i].id;
        this.spinner.show()
        this.dataService.GetDetails('operators/get-available-product-by-product-id/', this.productList[i].id).subscribe((data) => {
          const anyData = data as any;
          this.selectedProductList = anyData.stocks;
          if(this.selectedProductList.length !== 0){
            for(let i = 0; i < this.selectedProductList.length; i++){
              this.itemdiscount[i] = 0;
              this.itemqty[i] = 0;
              const producttoshow = {
                "product_name" : this.selectedProductList[i].product_name,
                "product_id" : this.selectedProductList[i].product_id,
                "po_id" : this.selectedProductList[i].po_id,
                "stock_id" : this.selectedProductList[i].stock_id,
                "product_code" : this.selectedProductList[i].product_code,
                "hsn_code" : this.selectedProductList[i].hsn_code,
                "gst_percent" : this.selectedProductList[i].tax_percent,
                "mrp" : this.selectedProductList[i].unit_price,
                "discount" : this.selectedProductList[i].fixed_discount,
                "selling_price" : this.selectedProductList[i].sale_price,
                "bv" : this.selectedProductList[i].bv,
                "available_quantity" : this.selectedProductList[i].stock_in
              };
              this.productsList.push(producttoshow);
            }
          }else{
            Swal.fire({
              icon: 'warning',
              title: 'Alert',
              text: 'Out of Stock!'
            });
          }
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }

  getProductByKeyword(){
        this.dataService.GetList('search-product-by-keyword').subscribe((data) => {
          const anyData = data as any;
          this.selectedKeywordList = anyData.products;
        }, error => {
          // this.spinner.hide();
        });
  }

  searchUserByMobile(){ //search user by user code
    this.dataService.GetDetails('get-mlm-user-by-user-code/', this.mlm_user_mobile_no).subscribe((data) => {
      const anyData = data as any;
      if(anyData.status === 200){
        this.invoice_user_detail = anyData.user;
        
        this.user_id = this.invoice_user_detail[0].id;
        this.user_lft = this.invoice_user_detail[0].lft;
        this.user_rgt = this.invoice_user_detail[0].rgt;
        this.user_level_at = this.invoice_user_detail[0].level_at;
        this.user_parent_id = this.invoice_user_detail[0].parent_id;
        this.user_active = this.invoice_user_detail[0].active;
        this.user_referal_code = this.invoice_user_detail[0].referal_code,
        this.invoice_name = this.invoice_user_detail[0].first_name + ' ' + this.invoice_user_detail[0].last_name;
        this.user_first_name = this.invoice_user_detail[0].first_name;
        this.invoice_mobile = this.invoice_user_detail[0].mobile_no;
        this.invoice_email = this.invoice_user_detail[0].email;
        
        this.invoice_usable_bv_amount = this.invoice_user_detail.bv_amount;
        this.is_user_searched = true;
        this.user_cash_amount = this.total_net_price;
       

        if(this.invoice_user_detail[0].addresses.length > 0){
          this.invoice_address = this.invoice_user_detail[0].addresses[0].address1 + '<br>' + this.invoice_user_detail[0].addresses[0].address2 + '<br>' + this.invoice_user_detail[0].addresses[0].dist + '<br' + this.invoice_user_detail[0].addresses[0].state + '<br> PIN - ' + this.invoice_user_detail[0].addresses[0].pin_code;
        }else{
          this.invoice_address = '';
        }
      }else{
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
    }, error => {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Network Error, Try Again.'
      });
    });

  }

  getSelectedProductByKeyword(product_name){
    this.productsList = [];
    for(let i=0; i<this.selectedKeywordList.length; i++){
      if(this.selectedKeywordList[i].name === product_name){
        // this.selectedProductId = this.selectedKeywordList[i].id;
        this.spinner.show()
        this.dataService.GetDetails('operators/get-available-product-by-product-id/', this.selectedKeywordList[i].id).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
          this.selectedProductList = anyData.stocks;
          if(this.selectedProductList.length !== 0){
            for(let i = 0; i < this.selectedProductList.length; i++){
              this.itemdiscount[i] = 0;
              this.itemqty[i] = 0;
              const producttoshow = {
                "product_name" : this.selectedProductList[i].product_name,
                "product_id" : this.selectedProductList[i].product_id,
                "po_id" : this.selectedProductList[i].po_id,
                "stock_id" : this.selectedProductList[i].stock_id,
                "product_code" : this.selectedProductList[i].product_code,
                "hsn_code" : this.selectedProductList[i].hsn_code,
                "gst_percent" : this.selectedProductList[i].tax_percent,
                "mrp" : this.selectedProductList[i].unit_price,
                "discount" : this.selectedProductList[i].fixed_discount,
                "selling_price" : this.selectedProductList[i].sale_price,
                "bv" : this.selectedProductList[i].bv,
                "available_quantity" : this.selectedProductList[i].stock_in
              };
              this.productsList.push(producttoshow);
            }
          }else{
            Swal.fire({
              icon: 'warning',
              title: 'Alert',
              text: 'Out of Stock!'
            });
          }
          
          
         
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }

  getProductCategoriesList(){
    this.spinner.show();
    this.dataService.GetList('productcategories').subscribe((data) => {
      const anyData = data as any;
      this.categoryList = anyData.categories;
      // this.dtTrigger.next();
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  addItems(i:number){
   
    const newarray = {
      "product_name" : this.productsList[i].product_name,
      "mrp_price" : ((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))*(100/(Number(this.productsList[i].gst_percent)+100))),
      "selling_price" : Number(this.productsList[i].selling_price),
      "hsn_code" : this.productsList[i].hsn_code,
      "qty" : this.itemqty[i],
      "gst" : this.productsList[i].gst_percent,
      // "gst_amount" : ((Number(this.productsList[i].selling_price))*(100/(Number(this.productsList[i].gst_percent)+100))),
      "gst_amount" : ((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))-(((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))*(100/(Number(this.productsList[i].gst_percent)+100))))),
      "cgst" : (((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))-(((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))*(100/(Number(this.productsList[i].gst_percent)+100)))))/2),
      "sgst" : (((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))-(((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))*(100/(Number(this.productsList[i].gst_percent)+100)))))/2),
      "total_amount" : ((Number(this.itemqty[i])*Number(this.productsList[i].selling_price))+(((Number(this.itemqty[i])*Number(this.productsList[i].selling_price))*(Number(this.productsList[i].gst_percent)/2)/100)*2)),
      "discount" : this.productsList[i].discount,
      "itemdiscount" : this.itemdiscount[i],
      "bv" : (Number(this.itemqty[i])*Number(this.productsList[i].bv)),
      "net_amount" : ((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))*Number(this.itemqty[i]))
      // "net_amount" : (((this.itemqty[i]*this.productsList[i].selling_price)+(((this.itemqty[i]*this.productsList[i].selling_price)*(this.productsList[i].gst_percent/2)/100)*2))-this.productsList[i].discount)
    };
    const stocktosend = {
      "stock_id" : this.productsList[i].stock_id,
      "po_id" : this.productsList[i].po_id,
      "product_id" : this.productsList[i].product_id,
      "quantity" : this.itemqty[i],
      "bv" : this.productsList[i].bv,
      "discount" : this.itemdiscount[i],
      "unit_price" : this.productsList[i].selling_price,
      "gst_percent" : this.productsList[i].gst_percent
      
    }
    this.selectedStocksList.push(stocktosend);
    this.itemsList.push(newarray);
    this.total_cgst = this.total_cgst + ((Number(this.itemqty[i])*(((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))-(((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))*(100/(Number(this.productsList[i].gst_percent)+100)))))/2)));
    this.total_sgst = this.total_sgst + ((Number(this.itemqty[i])*(((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))-(((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))*(100/(Number(this.productsList[i].gst_percent)+100)))))/2)));
    this.total_mrp = this.total_mrp + (((Number(this.productsList[i].selling_price)-Number(this.itemdiscount[i]))*(100/(Number(this.productsList[i].gst_percent)+100)))*Number(this.itemqty[i]));
    

    this.total_selling_price = this.total_selling_price + (Number(this.itemqty[i])*Number(this.productsList[i].selling_price));
    this.total_net_price = this.total_net_price + ((Number(this.itemqty[i])*Number(this.productsList[i].selling_price))-(Number(this.itemqty[i])*Number(this.itemdiscount[i])));

    // this.total_selling_price = (this.total_selling_price + (this.itemqty[i]*this.productsList[i].selling_price)-this.productsList[i].discount);
    // this.total_net_price = this.total_net_price + (((this.itemqty[i]*this.productsList[i].selling_price)+(((this.itemqty[i]*this.productsList[i].selling_price)*(this.productsList[i].gst_percent/2)/100)*2))-this.productsList[i].discount);
    this.total_bv = this.total_bv + (Number(this.itemqty[i])*Number(this.productsList[i].bv));
    this.total_discount = this.total_discount + (Number(this.itemqty[i])*Number(this.itemdiscount[i]));
    this.user_cash_amount = this.total_net_price;
    this.price_in_word = price_in_words(this.total_net_price);
    this.productsList = [];
  }

  removeItem(i:number) {
    // this.total_net_price = this.total_net_price - this.itemsList[i].total_amount;
    this.total_mrp = this.total_mrp - (((Number(this.itemsList[i].selling_price)-Number(this.itemsList[i].itemdiscount))*(100/(Number(this.itemsList[i].gst)+100)))*Number(this.itemsList[i].qty));
    this.total_net_price = this.total_net_price - ((Number(this.itemsList[i].qty)*Number(this.itemsList[i].selling_price))-(Number(this.itemsList[i].qty)*Number(this.itemsList[i].itemdiscount)));
    this.total_selling_price = this.total_selling_price -  (Number(this.itemsList[i].selling_price)*Number(this.itemsList[i].qty));
    this.total_cgst = this.total_cgst - Number(this.itemsList[i].cgst);
    this.total_sgst = this.total_sgst - Number(this.itemsList[i].sgst);
    this.total_bv =  this.total_bv - Number(this.itemsList[i].bv);
    this.total_discount = this.total_discount - (Number(this.itemsList[i].qty)*Number(this.itemsList[i].itemdiscount));
    this.user_cash_amount = this.total_net_price;
    this.price_in_word = price_in_words(this.total_net_price);
    this.itemsList.splice(i, 1);
    this.selectedStocksList.splice(i, 1);


  }

  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
  var popupWin = window.open('', '_blank', 'width=1200,height=1200');
  popupWin.document.open();
  popupWin.document.write('<html><head><script type="text/javascript">function PrintWindow(){ window.print(); CheckWindowState();}  function CheckWindowState(){ if(document.readyState=="complete") {  window.close();  }  else  {  setTimeout("CheckWindowState()", 2000)  }  }  </script><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="PrintWindow()">' + printContents + '</body></html>');
  popupWin.document.close();
    // var printContents = document.getElementById(divName).innerHTML;
    // var originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents;

    // window.print();

    // document.body.innerHTML = originalContents;
}

  showPreview(){
    this.is_preview = true;
  }

  // setUserCashAmount(){
  //   this.user_cash_amount = this.total_net_price - this.verified_bv_amount;
  // }

  onSubmit() {


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Submit it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const datatosend = {
          "mlm_bill_header" : {
            "bv_amount" : (Math.floor(this.verified_bv_amount*100)/100),
            "amount" : (Math.floor(this.user_cash_amount*100)/100),
            "user_id" : this.user_id, 
            "lft" : this.user_lft,
            "rgt" : this.user_rgt,
            "referal_code" : this.user_referal_code,
            "level_at" : this.user_level_at,
            "parent_id" : this.user_parent_id,
            "active" : this.user_active,
            "remarks" : this.invoice_remarks
          },
          "order_details" : this.selectedStocksList
        }
          this.spinner.show();
            this.dataService.Create('create-billing', datatosend).subscribe((data) => {
              this.spinner.hide();
              const anyData = data as any;
                  if(anyData.status === 200){
                    this.user_invoice_no = anyData.invoice_no;
                    this.is_submit = false;
                    this.is_print = true;
                    Swal.fire(
                      'Submitted!',
                      'Your Order Submitted Successfully. Print Invoice!',
                      'success'
                    )
                  }else{        
                    Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: anyData.error[Object.keys(anyData.error)[0]]
                    });
                  }
            }, error => {
              this.spinner.hide();
              showDialog3();
            });
      }
    })
    } 
}
