import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ExportExcelService } from 'src/app/services/export-excel.service';

@Component({
  selector: 'app-stockavailable',
  templateUrl: './stockavailable.component.html',
  styleUrls: ['./stockavailable.component.scss']
})
export class StockavailableComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  kycList: any;
  nokycList: any;
  itemsList: any;
  exportList: any;
  operatorid: string;
  dataForExcel = [];
  newExportList : any = [];
  usertype: any;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public ete: ExportExcelService,
  ) {
    this.itemsList = [];
    this.exportList = [];
    this.kycList = [];
    this.nokycList = [];
    this.usertype = JSON.parse(sessionStorage.getItem('sidemenu')).userrole;
    // console.log(this.usertype);
   }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.getAvailableStockList();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  exportToExcel(){

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
        this.spinner.show();
        this.dataService.GetList('operators/get-available-stocks').subscribe((data) => {
          const anyData = data as any;
          this.spinner.hide();
          if(anyData.status === 200){
            this.dataForExcel = [];
            this.exportList = anyData.stocks;
            for(let i=0; i< this.exportList.length; i++){
              const datalist = {
                "branch_name" : this.exportList[i].branch.branch_name,
                "brand_name" : this.exportList[i].phorder.product.brand.brand_name,
                "product_name" : this.exportList[i].phorder.product.product_name,
                "product_type" : this.exportList[i].phorder.product.subcategory.sub_category_name,
                "unit_price" : this.exportList[i].phorder.unit_price,
                "sale_price" : this.exportList[i].phorder.sale_price,
                "fixed_discount" : this.exportList[i].phorder.fixed_discount,
                "bv" : this.exportList[i].phorder.bv,
                "available_quantity" : this.exportList[i].stock_in,
              }
              this.newExportList.push(datalist);
            }
            this.newExportList.forEach((row: any) => {
              this.dataForExcel.push(Object.values(row))
            })
        
            let reportData = {
              title: 'Available Stock Details -' + dd + '-' + mm + '-' + yyyy,
              data: this.dataForExcel,
              headers: ["BRANCH NAME","BRAND NAME","PRODUCT NAME","PRODUCT TYPE","UNIT PRICE","SALE PRICE","FIXED DISCOUNT","BV","AVAILABLE QUANTITY"]
              // headers: Object.keys(this.exportList[0])
            }
        
            this.ete.exportExcel(reportData);
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: anyData.error[Object.keys(anyData.error)[0]]
            });
          }
         
        }, error => {
          // showDialog3();
          this.spinner.hide();
        });
      }

  getAvailableStockList(){
    this.spinner.show();
    this.dataService.GetList('operators/get-available-stocks').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.itemsList = anyData.stocks;
      // console.log(JSON.stringify(this.addrList));
      this.dtTrigger.next();
      this.dtTrigger.unsubscribe();
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
    }, error => {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Network Error, Please Try Again.'
      });
    });
  }

  // editPurchaseOrder(id){
  //   localStorage.setItem('activeid', id);
  //   this.router.navigate(['purchaseorder/edit']);
  // }

  // changeStatus(id, status){
  //   let new_status;
  //   if(status === 1){
  //     new_status = 0;
  //   }else{
  //     new_status = 1;
  //   }
  //   const datatosend = {
  //     "product_id" : id,
  //     "status" : new_status
  //   }
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'You are going to change status!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, change it!',
  //   }).then((result) => {
  //     // alert(result.value);
  //     if (result.value) {
  //       this.spinner.show();
  //       this.dataService.Create('operators/purchaseheader/update-status', datatosend).subscribe((data) => {
  //         this.spinner.hide();
  //         // showDialog6();
  //         this.getPurchaseHeaderList();
  //       }, error => {
  //         this.spinner.hide();
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Error',
  //           text: 'Network Error, Please Try Again.'
  //         });
  //       });

  //     }
  //   });
  // }

}
