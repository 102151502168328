
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title"> Expense List</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active">  Expense &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active">  Expense List</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="tabbable-line">
								<!-- <ul class="nav nav-tabs">
                                    <li class="active">
                                        <a href="#tab1" data-toggle="tab"> List View </a>
                                    </li>
                                    <li>
                                        <a href="#tab2" data-toggle="tab"> Grid View </a>
                                    </li>
                                </ul> -->
								<ul class="nav customtab nav-tabs" role="tablist">
									<!-- <li class="nav-item"><a href="#tab1" class="nav-link active" data-toggle="tab">List
											View</a></li> -->
									<!-- <li class="nav-item"><a href="#tab2" class="nav-link" data-toggle="tab">Grid
											View</a></li> -->
								</ul>
								<div class="tab-content">
									<div class="tab-pane active fontawesome-demo" id="tab1">
										<div class="row">
											<div class="col-md-12">
												<div class="card card-box">
													<div class="card-head">
														<header>All  Expense</header>
														<div class="tools">
															<a class="fa fa-repeat btn-color box-refresh"
																href="javascript:;"></a>
															<a class="t-collapse btn-color fa fa-chevron-down"
																href="javascript:;"></a>
															<a class="t-close btn-color fa fa-times"
																href="javascript:;"></a>
														</div>
													</div>
													<div class="card-body ">
														<div class="row">
															<div class="col-md-6 col-sm-6 col-6">
																<div class="btn-group">
																	<a href="expense/expenseentry/add" id="addRow"
																		class="btn btn-info">
																		Add New <i class="fa fa-plus"></i>
																	</a>
																</div>
															</div>
															<div class="col-md-6 col-sm-6 col-6">
																<div class="btn-group pull-right">
																</div>
															</div>
														</div>
														<div class="table-scrollable">
															<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" 	class="table table-striped table-bordered table-hover table-checkable order-column valign-middle btn-sweetalert"
																>
																<thead>
																	<tr>
																		<th>#</th>
																		<th *ngIf="usertype != 'OPERATOR'">Site Name</th>
																		<th> Amount </th>
																		<th> Description </th>
																		<th> Date </th>
																		<th> Action </th>
																	</tr>
																</thead>
																<tbody>
																	<tr class="odd gradeX" *ngFor="let item of itemsList; let i = index">
																		<td class="left">
																			{{i+1}}
																		</td>
																		<td class="left"  *ngIf="usertype != 'OPERATOR'">{{item.branch.branch_name}}</td>
																		<td class="left">{{item.amount}}</td>
                                    									<td class="left"> {{ item.description}}</td>
																		<td class="left">{{item.actual_date | date: 'dd-MM-yyyy'}}</td>
																		<td class="left">
																			<button type="button" class="btn btn-primary btn-xs" (click)="editItem(item.id)">
																				<i class="fa fa-pencil"></i>
																			</button>
																			<button type="button" *ngIf="item.status == 1" class="btn btn-success btn-xs" (click)="deleteOperator(item.id, item.status)">
																				<i class="fa fa-eye "></i>
																			</button>
																			<button type="button" *ngIf="item.status == 0" class="btn btn-secondary btn-xs" (click)="deleteOperator(item.id, item.status)">
																				<i class="fa fa-eye-slash "></i>
																			</button>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>

</body>

