import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
  currenturl: any;
  @Input() sidemenubar: any;

  public innerHeight: any;

  user_image : '../../assets/images/user.png';
 

  constructor( private router: Router) {
    this.currenturl = this.router.url;
   }

  ngOnInit(): void {
    this.innerHeight = window.innerHeight-50;
  }
  @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerHeight = window.innerHeight-50;
  // alert(this.innerHeight);
}
  
 

}
