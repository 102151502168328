import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-miscexpenseadd',
  templateUrl: './miscexpenseadd.component.html',
  styleUrls: ['./miscexpenseadd.component.scss']
})
export class MiscexpenseaddComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  expenseTypeList: any = [];

  description = new FormControl('');
  amount = new FormControl('');
  actual_date = new FormControl('');
  expense_type_id = new FormControl('');

  

   constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
  ) {
    
    
   
    // this.getBranchTypeList();
    this.getExpenseTypeList();
   }

  ngOnInit(){
   
  }

  getExpenseTypeList(){
      this.spinner.show();
      this.dataService.GetList('miscexptypes').subscribe((data) => {
        const anyData = data as any;
        this.spinner.hide();
        if(anyData.status === 200){
          this.expenseTypeList = anyData.miscexpensetypes;
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: anyData.error[Object.keys(anyData.error)[0]]
          });
        }     
      }, error => {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Network Error, Try Again'
        });
      });
  }



  onSubmit(form: NgForm) {
    // alert(this.first_name.value);

    if(!this.amount.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Amount is Required'
      });
    }else if(!this.description.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Description is Required'
      });
    }else {
      
                    const datatosend = {
                      "description": this.description.value,
                      "amount": this.amount.value,
                      "actual_date": this.actual_date.value,
                      "expense_type_id": this.expense_type_id.value
                      
                    }
                    this.spinner.show();
                    this.dataService.Create('save-misc-expense',datatosend).subscribe((data) => {
                      this.spinner.hide();
                      const anyData = data as any;
                      if(anyData.status === 200){
                        form.reset();
                        Swal.fire({
                          icon: 'success',
                          title: 'Success',
                          text: anyData.message
                        });
                      }else{
                        Swal.fire({
                          icon: 'error',
                          title: 'Error',
                          text: anyData.error[Object.keys(anyData.error)[0]]
                        });
                      }
                      
                    }, error => {
                      this.spinner.hide();
                      Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Network Error, Please try again'
                      });
                    });

  }


  }



}
