import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

declare function showDialog6(): any;
declare function showDialog3(): any;
declare function showDialog11(): any;
@Component({
  selector: 'app-brandedit',
  templateUrl: './brandedit.component.html',
  styleUrls: ['./brandedit.component.scss']
})
export class BrandeditComponent implements OnInit {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  brandForm = new FormGroup({brand_name: new FormControl()});


  itemList: any;



  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
    ) {
      this.itemList = [] ;
    this.getDetails(localStorage.getItem('activeid'));
   }

  ngOnInit(){

  }

  getDetails(id){
    this.spinner.show();
    this.dataService.GetDetails('brands/', id).subscribe((data) => {
      this.spinner.hide();
      const anyData = data as any;
      console.log(JSON.stringify(anyData.brand));

      this.itemList = anyData.brand;
      this.brandForm = this.formBuilder.group({
        brand_name:[this.itemList.brand_name, Validators.required]
      });
    }, error =>{
      this.spinner.hide();
      showDialog3();
    });
  }

  onSubmit() {
    if (this.brandForm.valid) {
      // alert('Success');
      // alert(JSON.stringify(this.productCategoryForm.value));
      console.log(this.brandForm.value);
      this.spinner.show();
        this.dataService.Edit('brands/', JSON.stringify(this.brandForm.value),localStorage.getItem('activeid')).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
              if(anyData.status === 200){
                
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Brand Updated Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
        }, error => {
          this.spinner.hide();
          showDialog3();
        });
    } else{
      showDialog11();
    }
  }

}

