import { Component, OnInit } from '@angular/core';

import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ExportExcelService } from 'src/app/services/export-excel.service';

declare function showDialog7(): any;
declare function showDialog6(): any;
declare function showDialog3(): any;
declare function deleteRecord(url, id): any;
declare function sortByProperty(property);


@Component({
  selector: 'app-paymentsupplierpayment',
  templateUrl: './paymentsupplierpayment.component.html',
  styleUrls: ['./paymentsupplierpayment.component.scss']
})
export class PaymentsupplierpaymentComponent implements OnInit {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  sub: any;

  dtOptions: DataTables.Settings = {};
  // persons: Person[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();



  public loading = false;

  itemsList: any;
  newitemsList: any =[];
  exportList: any = [];
  dataForExcel = [];
  newExportList : any = [];
  creditList: any = [];
  debitList: any = [];
  curdate: any;
  fromDate: any;
  toDate: any;
  net_balance: number = 0;

  net_due_balance: number = 0;

  supplier_detail: any;

  
  transaction_id: any;
  transaction_amount: any;
  transaction_date: any;
  operatorid: string;
  myDate = new Date();

  constructor(
    public dataService: DataService,
    private envService: EnvService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    public ete: ExportExcelService

  ) {

    this.transaction_amount = [];
    this.transaction_date = [];
    this.transaction_id = [];
   }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.itemsList = [];
    this.getItemsList();
    // this.sub = this.route.params.subscribe(params => {
    //   sessionStorage.setItem('activeMenuId', params['id']);
    // });   
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    // this.sub.unsubscribe();
  }

  ngAfterViewInit(): void{
    // this.dtTrigger.next();
  }

  exportToExcel(id){

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
        this.spinner.show();
        // const datatosend = {
        //   "from" : this.fromDate,
        //   "to" : this.toDate
        // }
        
          this.dataService.GetDetails('payment/get-supplier-distributor-balance-sheet/', id).subscribe((data) => {
            const anyData = data as any;
            this.spinner.hide();
            if(anyData.status === 200){
              this.dataForExcel = [];
              this.exportList = anyData.ledger.ledger;
              this.supplier_detail = anyData.ledger.supplier.s_name + ',<br/> Mobile: ' + anyData.ledger.supplier.mobile_no + ',<br/> Address: ' + anyData.ledger.supplier.address1 + ',<br/> ' + anyData.ledger.supplier.address2 + ',<br/> ' + anyData.ledger.supplier.dist + ',<br/> ' + anyData.ledger.supplier.state + '-' + anyData.ledger.supplier.pin_code + ',<br/> GST NO: ' + anyData.ledger.supplier.gst_no;
              console.log(this.exportList);
              this.net_balance = this.net_balance - Number(anyData.ledger.supplier.opening_balance);
              const openingdata = {
                "date" : '00-00-000',
                "debit" : anyData.ledger.supplier.opening_balance,
                "credit" : '0',
                "balance" : Math.floor(this.net_balance*100)/100
              }
              this.newExportList.push(openingdata);
              for(let i=0; i< this.exportList.length; i++){
                this.net_balance = this.net_balance + Number(this.exportList[i].credit) - Number(this.exportList[i].debit);
                // for(let j=0; j< this.exportList[i].orderdetail.length; j++){
                  const datalist = {
                    "date" : this.datePipe.transform(this.exportList[i].date, 'dd-MM-yyyy'),
                    "debit" : this.exportList[i].debit,
                    "credit" : this.exportList[i].credit,
                    "balance" : Math.floor(this.net_balance*100)/100
                  }
                  this.newExportList.push(datalist);
                // }
              }
              this.newExportList.forEach((row: any) => {
                this.dataForExcel.push(Object.values(row))
              })
              console.log(this.newExportList);
      
              // alert(JSON.stringify(Object.keys(this.exportList[0])));
          
              let reportData = {
                details: this.supplier_detail,
                title: 'Supplier Balance Report -' + dd + '-' + mm + '-' + yyyy,
                data: this.dataForExcel,
                headers: ["DATE","DEBIT","CREDIT","BALANCE"]
                // headers: Object.keys(this.exportList[0])
              }
          
              this.ete.exportExcel(reportData);
              // this.dtTrigger.next();
              // this.dtTrigger.unsubscribe();
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: anyData.error[Object.keys(anyData.error)[0]]
              });
            }
           
          }, error => {
            // showDialog3();
            this.spinner.hide();
          });
        
      }

      exportNow(id){}

  getItemsList(){
    this.spinner.show();
    this.dataService.GetList('payment/suppliers-dues').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.itemsList = anyData.supplier_dues_balances;
        for(let i=0; i< this.itemsList.length; i++){
          this.net_due_balance = this.net_due_balance+Number(this.itemsList[i].dues_balance);
          this.transaction_date[i] = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
          this.transaction_id[i] = '';
          this.transaction_amount[i] = '';
        }
        console.log(this.net_due_balance);
        this.dtTrigger.next();
        this.dtTrigger.unsubscribe();
        // console.log(JSON.stringify(this.itemsList));
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
     
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

  payNow(id, sl){
    const datatosend = {
      "supplier_id" : id,
      "transaction_id" : this.transaction_id[sl],
      "amount" : this.transaction_amount[sl],
      "transaction_date" : this.transaction_date[sl]
    }
    this.spinner.show();
    this.dataService.Create('payment/suppliers', datatosend).subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
       this.getItemsList();
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
     
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

 
}
