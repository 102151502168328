import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  headers: any;

  currentItem : any;

  // usermenu : any;

  sidemenu: any;


  constructor(
    private http: HttpClient,
    private env: EnvService,
    private fb:FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { 
    
    // alert(sessionStorage.getItem('sideMenu'));
    // alert(JSON.parse(sessionStorage.getItem('currentItem')).userrole)
    // const sidemenubar = {
    //   "userrole" : JSON.parse(sessionStorage.getItem('currentItem')).userrole,
    //   "sidemenu" : JSON.parse(sessionStorage.getItem('sideMenu'))
    // }
    // this.sidemenu = sidemenubar;
    this.sidemenu = JSON.parse(sessionStorage.getItem('sidemenu'));
    // alert(JSON.stringify(this.sidemenu));
    this.currentItem = JSON.parse(sessionStorage.getItem('currentItem'));
    // alert(JSON.stringify(this.currentItem));
    // this.getMenuDetails();
  }

  getMenuDetails(){
    // const encrypted = 
    this.GetHeaders();
    // const request = this.http.get(this.env.apiUrl + '', { headers: this.headers }).subscribe((data) => {
      // const anyData = data as any;
      const anyData = [
        {
          "url" : "dashboard",
          "title" : "Dashboard",
          "icon" : "dashboard"
        },
        {
          "url" : "operator",
          "title" : "Operators",
          "icon" : "person"
        },
        {
          "url" : "user",
          "title" : "Users",
          "icon" : "group"
        },
        {
          "url" : "supplier",
          "title" : "Suppliers",
          "icon" : "assignment_ind"
        },
        {
          "url" : "branch",
          "title" : "Branch",
          "icon" : "extension"
        },
        {
          "url" : "productcategory",
          "title" : "Category",
          "icon" : "view_column"
        },
        {
          "url" : "productsubcategory",
          "title" : "Subcategory",
          "icon" : "view_module"
        },
        {
          "url" : "brand",
          "title" : "Brands",
          "icon" : "store_mall_directory"
        },
        {
          "url" : "product",
          "title" : "Products",
          "icon" : "markunread_mailbox"
        },
        ];
        this.sidemenu = anyData;
      
    // }, error => {
      
    // });
    // return request;
  }

  CheckEmpty(ctrlid, ctrltext){
    // tslint:disable-next-line: triple-equals
    if (ctrltext == '' || ctrltext == null){
      document.getElementById(ctrlid).focus();
      return false;
    }else{
      return true;
    }
  }

 
  newProduct(): FormGroup {
    return this.fb.group({
      product_id: ['', Validators.required],
      unit_price: ['', Validators.required],
      sale_price: ['', Validators.required],
      price_id: [''],
      fixed_discount: ['', Validators.required],
      bv: ['', Validators.required],
      gst_percent: ['', Validators.required],
      quantity: ['', Validators.required],
      from_date: ['', Validators.required]
    })
  }

  GetHeaders(){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', 'Bearer ' + sessionStorage.getItem('token'));
    this.headers = this.headers.append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE', );
    this.headers = this.headers.append('Accept', 'application/json');
    this.headers = this.headers.append('Content-Type', 'application/json');
  }

  SendLoginData(datatosend): Observable<any> {
    this.GetHeaders();
    const request = this.http.post(this.env.apiUrl + 'operators/login', datatosend, { headers: this.headers });
    return request;
  }

  //------------------------------------------------------------------------------------------------
  GetList(url) {   
    this.GetHeaders();
    // this.http.g
    const request = this.http.get(this.env.apiUrl + url, { headers: this.headers });
    return request;
  }
  GetDetails(url, operatorid){
    this.GetHeaders();
    const request = this.http.get(this.env.apiUrl + url + operatorid, {headers: this.headers});
    return request;
  }
  Create(url, datatosend){
    this.GetHeaders();
    const request = this.http.post(this.env.apiUrl + url, datatosend, { headers: this.headers });
    return request;
  }
  Edit(url, datatosend, id){
    this.GetHeaders();
    const request = this.http.put(this.env.apiUrl + url + id, datatosend, { headers: this.headers });
    return request;
  }
  Delete(url, id){
    this.GetHeaders();
    const request = this.http.delete(this.env.apiUrl + url + id, { headers: this.headers });
    return request;
  }
  CreateWithID(url, datatosend, id){
    this.GetHeaders();
    const request = this.http.post(this.env.apiUrl + url + id, datatosend, { headers: this.headers });
    return request;
  }
  GetDetailsDatewise(url, from, to){
    this.GetHeaders();
    const request = this.http.get(this.env.apiUrl + url + from + '/' + to, {headers: this.headers});
    return request;
  }

  ProfileUpdate(url, datatosend){
    this.GetHeaders();
    const request = this.http.put(this.env.apiUrl + url, datatosend, { headers: this.headers });
    return request;
  }
  

  UpdateEmailMobile(updateurl, datatosend, userid){
    this.spinner.show();
    this.ProfileUpdate(updateurl, datatosend).subscribe((data) => {
      this.spinner.hide();
      const anyData = data as any;
      if(anyData.status === 200){
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Your request processed Successfully'
        });
        // this.router.navigate([]);
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }              
    }, error => {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Network Error, Please try again'
      });
    });
    }
  
    VerifyOTP(otpurl, updateurl, userid, accounttoupdate, inputtype, requesttype){
      Swal.fire({
        title: 'Enter OTP',
        // text: 'Check your Mobile for OTP',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        timer: 300000,
        timerProgressBar: true,
        showCancelButton: false,
        confirmButtonText: 'Verify',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: (otp) => {
          // this.dataService.GetList
          let datatosend;
          if(inputtype === 'email'){
            datatosend = {
              "email" : accounttoupdate,
              "otp" : otp
            };
          }else{
            datatosend = {
              "mobile_no" : accounttoupdate,
              "otp" : otp
            };
          }
          
          this.spinner.show();
          this.Create( otpurl, datatosend).subscribe((data) => {
            const otpData = data as any;
            this.spinner.hide();
            if(otpData.status === 200){
              let updatedatatosend;
              if(inputtype === 'email'){
                updatedatatosend = {
                  "email" : accounttoupdate
                }
              }else{
                updatedatatosend = {
                  "mobile_no" : accounttoupdate
                };
              }
  
              if(requesttype === 'change'){
                this.UpdateEmailMobile(updateurl, updatedatatosend, userid);
              }else if(requesttype === 'forgot'){
                this.router.navigateByUrl('resetpassword',{ state: { email: accounttoupdate } });
              }
              
              
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: otpData.error[Object.keys(otpData.error)[0]]
              });
            }
          }, error => {
            this.spinner.hide();
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Network Error, Please try again'
            });
          });
        }                
      })
      
    }
  
    ChangeEmailMobile(sendotpbyurl, otpurl, updateurl, userid, firstname, titletext, textmsg, inputtype, requesttype){
      Swal.fire({
        title: titletext,
        text: textmsg,
        input: inputtype,
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Send OTP',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: (toverify) => {          
          let datatosend;
          if(inputtype === 'email'){
            datatosend = {
              "first_name" : firstname,
              "email" : toverify,
              "type" : "user"
            };
          }else{
            datatosend = {
              "first_name" : firstname,
              "mobile_no" : toverify
            };
          }
       
        this.spinner.show();
        this.Create( sendotpbyurl, datatosend).subscribe((data) => {
          const anyData = data as any;
          this.spinner.hide();
          if(anyData.status === 200){
            this.VerifyOTP(otpurl, updateurl, userid, toverify, inputtype, requesttype);
          }else{        
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: anyData.error[Object.keys(anyData.error)[0]]
            });  
          }
        }, error => {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Network Error, Please try again'
          });
        });
  
        }
      });
    }


}
