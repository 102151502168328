import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

declare function showDialog6(): any;
declare function showDialog3(): any;

@Component({
  selector: 'app-productcategoryadd',
  templateUrl: './productcategoryadd.component.html',
  styleUrls: ['./productcategoryadd.component.scss']
})
export class ProductcategoryaddComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
  ) {
   }

  ngOnInit(){
  }



  onSubmit(form: NgForm) {
    const datatosend = {
    "category_name":form.value.category_name,
    }
    this.spinner.show();
        this.dataService.Create('productcategories',datatosend).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
              if(anyData.status === 200){
                form.reset();
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Product Category Created Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
        }, error => {
          this.spinner.hide();
          showDialog3();
        });
    // alert(JSON.stringify(datatosend));

  }



}
