
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Print Invoice</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Billing &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active">Print Invoice</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<div class="card card-box">
								<div class="card-body" id="bar-parent">
                  
                  <div id="printableArea" class=WordSection1>
                    
                    <table class="invoice_preview_table" style="border: solid thin #000000; border-collapse: collapse; font-size: 14px; padding: 5px;">
                        <tr><td colspan="4" style="border: none;"></td><td colspan="6"  style="border: none;"><strong>Tax Invoice</strong></td><td colspan="6"  style="border: none; font-size: 16px;"><img class="col-md-6" src="/assets/images/logo4.png" alt=""></td></tr>
                     <tr>
                        <td width=281 colspan=3 rowspan=3  style="border: solid thin #000000; padding: 5px;">
                            <p><span><strong>ANSHU ENTREPRISES</strong><br>INFRONT OF AGGARWAL PETROL PUMP<br>PATNA RANCHI ROAD, NAWADA<br>Contact: 9835856927<br> GSTIN NO.: 10HCCPK9197M1Z2<br>STATE: Bihar  </span></p>
                        </td>
                        <td width=198 colspan=8  style="border: solid thin #000000; padding: 5px;">
                            <p><span>Invoice No.:   {{invoiceDetail.invoice_no}}</span></p>
                        </td>
                        <td width=227 colspan=5  style="border: solid thin #000000; padding: 5px;">
                            <p><span>Date:   {{invoiceDetail.order_date | date: 'dd-MM-yyyy'}}</span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=198 colspan=8  style="border: solid thin #000000; padding: 5px;">
                        <p><span>Delivery Note:   </span></p>
                      </td>
                      <td width=227 colspan=5  style="border: solid thin #000000; padding: 5px;">
                      <p><span>Mode/Terms of Payment   </span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=198 colspan=8  style="border: solid thin #000000; padding: 5px;">
                        <p><span>Supplier’s Ref:   </span></p>
                      </td>
                      <td width=227 colspan=5  style="border: solid thin #000000; padding: 5px;">
                      <p><span>Other Reference(s)   </span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=281 colspan=3 rowspan=4  style="border: solid thin #000000; padding: 5px;">
                      <p><span> <strong>Name:</strong> {{invoiceDetail.destbranch.branch_name}}<br>
                        <strong> Mobile:</strong> {{invoiceDetail.destbranch.mobile_no}}<br>
                         <strong>Email:</strong> {{invoiceDetail.destbranch.email}}<br>
                         <strong>Address:</strong> <span>{{invoiceDetail.destbranch.address1}},<br>{{invoiceDetail.destbranch.address2}},<br>{{invoiceDetail.destbranch.dist}},<br>{{invoiceDetail.destbranch.state}},<br>PIN - {{invoiceDetail.destbranch.pin_code}}</span><br> </span></p>
                      </td>
                      <td width=198 colspan=8  style="border: solid thin #000000; padding: 5px;">
                      <p><span>Buyer’s Order No.:   </span></p>
                      </td>
                      <td width=227 colspan=5  style="border: solid thin #000000; padding: 5px;">
                      <p><span>Date:   {{invoiceDetail.order_date | date: 'dd-MM-yyyy'}}</span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=198 colspan=8  style="border: solid thin #000000; padding: 5px;">
                      <p><span>Dispatch Document No.:   </span></p>
                      </td>
                      <td width=227 colspan=5  style="border: solid thin #000000; padding: 5px;">
                      <p><span>Delivery Note Date:   {{invoiceDetail.order_date | date: 'dd-MM-yyyy'}}</span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=198 colspan=8  style="border: solid thin #000000; padding: 5px;">
                      <p><span>Dispatched Through:   </span></p>
                      </td>
                      <td width=227 colspan=5  style="border: solid thin #000000; padding: 5px;">
                      <p><span>Destination:   </span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=425 colspan=13  style="border: solid thin #000000; padding: 5px;">
                      <p><span>Terms of Delivery:   </span></p>
                      </td>
                     </tr>
                     <tr>
                         <td colspan="16">
                            <div style="width: 100%; min-height: 380px; border: solid thin #000000;">
                                <table with="100%" style="min-height: 380px; font-size: 12px;" class="items-table">
                                  <tr>
                                    <th width=30 valign=top height="40"  style="border-right: solid thin #000000; padding: 0px 5px; border-bottom: solid thin #000000;">
                                    <p><span>Sl</span></p>
                                    </th>
                                    <th width=340 valign=top  style="border-right: solid thin #000000; padding: 0px 5px; border-bottom: solid thin #000000;">
                                    <p><span>Description   </span></p>
                                    </th>
                                    <th width=60 valign=top   style="border-right: solid thin #000000; text-align: center; padding: 0px 5px; border-bottom: solid thin #000000;">
                                    <p><span>HSN/SAC   </span></p>
                                    </th>
                                    <th width=66 valign=top   style="border-right: solid thin #000000; text-align: center; padding: 0px 5px; border-bottom: solid thin #000000;">
                                    <p><span>Rate   </span></p>
                                    </th>
                                    <th width=60 valign=top    style="border-right: solid thin #000000; text-align: center; padding: 0px 5px; border-bottom: solid thin #000000;">
                                    <p><span>GST%   </span></p>
                                    </th>
                                    <th width=60 valign=top    style="border-right: solid thin #000000; text-align: center; padding: 0px 5px; border-bottom: solid thin #000000;">
                                    <p><span>GST   </span></p>
                                    </th>
                                    <th width=40 valign=top   style="border-right: solid thin #000000; text-align: center; padding: 0px 5px; border-bottom: solid thin #000000;">
                                    <p><span>Qty   </span></p>
                                    </th>
                                    <th width=60 valign=top   style="border-right: solid thin #000000; text-align: center; padding: 0px 5px; border-bottom: solid thin #000000;">
                                    <p><span>Amount   </span></p>
                                    </th>
                                </tr>
                                    <tr  *ngFor="let item of order_list; let i=index">
                                      <td valign=top   style="border-right: solid thin #000000; padding: 0px 5px;">
                                        <p><span> {{i+1}}  </span></p>
                                        </td>
                                        <td valign=top   style="border-right: solid thin #000000; padding: 0px 5px;">
                                        <p><span > {{item.description}}  </span></p>
                                        </td>
                                        <td valign=top   style="border-right: solid thin #000000; text-align: center; padding: 0px 5px;">
                                        <p><span > {{item.hsn_code}}  </span></p>
                                        </td>
                                        <td valign=top   style="border-right: solid thin #000000; text-align: center; padding: 0px 5px;">
                                        <p><span > {{item.rate | number : '1.2-2'}}  </span></p>
                                        </td>
                                        <td valign=top   style="border-right: solid thin #000000; text-align: center; padding: 0px 5px;">
                                        <p><span > {{item.gst_percent}}  </span></p>
                                        </td>
                                        <td valign=top   style="border-right: solid thin #000000; text-align: center; padding: 0px 5px;">
                                        <p><span > {{item.gst_amount | number : '1.2-2'}}  </span></p>
                                        </td>
                                        <td valign=top   style="border-right: solid thin #000000; text-align: center; padding: 0px 5px;">
                                        <p><span > {{item.quantity}}  </span></p>
                                        </td>
                                        <td valign=top   style="border-right: solid thin #000000; text-align: center; padding: 0px 5px;">
                                        <p><span>{{item.total_amount | number : '1.2-2'}}   </span></p>
                                        </td>
                                    </tr>
                                 </table>
                             </div>
                         </td>
                     </tr>
                     <tr>
                      <td width=707 colspan=16  style="border: solid thin #000000; padding: 5px;">
                          <p><span></span><span style="float: right;">Amount Sum Total : {{total_net_amount | number : '1.2-2'}}   </span></p>
                        </td>
                   </tr>
                   <tr>
                    <td width=707 colspan=16 valign=top style="border: solid thin #000000; padding: 5px;">
                    <p style="text-align: right;"><span > ON AMOUNT: {{net_total_mrp | number : '1.2-2'}} <br>
                                  CGST: {{net_total_gst/2 | number : '1.2-2'}} <br>
                                  SGST: {{net_total_gst/2 | number : '1.2-2'}} <br>
                                  TOTAL AFTER ROUND OFF: {{total_net_amount| number : '1.2-2'}}
                  </span></p>
                    </td>
                   </tr>
                     <tr>
                      <td width=357 colspan=6  height="120" valign="top" style="border: solid thin #000000; padding: 5px;">
                      <p><span >Customer’s Signature   </span></p>
                      </td>
                      <td width=350 colspan=10  valign="top"  style="border: solid thin #000000; padding: 5px;">
                      <p><span > ANSHU ENTREPRISES<br>Seal and Signature  </span></p>
                      </td>
                     </tr>
                     <!-- <tr height=0>
                      <td width=52 style='border:none'></td>
                      <td width=182 style='border:none'></td>
                      <td width=47 style='border:none'></td>
                      <td width=21 style='border:none'></td>
                      <td width=8 style='border:none'></td>
                      <td width=47 style='border:none'></td>
                      <td width=19 style='border:none'></td>
                      <td width=9 style='border:none'></td>
                      <td width=57 style='border:none'></td>
                      <td width=19 style='border:none'></td>
                      <td width=19 style='border:none'></td>
                      <td width=19 style='border:none'></td>
                      <td width=28 style='border:none'></td>
                      <td width=47 style='border:none'></td>
                      <td width=28 style='border:none'></td>
                      <td width=104 style='border:none'></td>
                     </tr> -->
                    </table>
                    <p style="text-align: center;"><span>This is a Computer Generated Invoice</span></p>
                    
                    </div>

                  <!-- {{itemsList | json}} -->
                  <input type="button" (click)="printDiv('printableArea')" value="Print" class="btn btn-primary" />
                 
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>
</body>

