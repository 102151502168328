import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-changepwd',
  templateUrl: './changepwd.component.html',
  styleUrls: ['./changepwd.component.scss']
})
export class ChangepwdComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;

  oldpassword = new FormControl('');
  newpassword = new FormControl('');
  cnfpassword = new FormControl('');
  

//  headershow: boolean;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService
  ) {
    // this.headershow = false;
   }

  ngOnInit(): void {
  }


  onSubmit(form: NgForm) {
    if(this.newpassword.value === this.cnfpassword.value){
      const datatosend = {
        "old_password": this.oldpassword.value,
        "new_password": this.newpassword.value,
        "confirm_password": this.cnfpassword.value
      }
      this.spinner.show();
      this.dataService.Create('operators/change-password', datatosend).subscribe((data) => {
        this.spinner.hide();
        const anyData = data as any;
        if(anyData.status === 200){
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Password Changed Successfully'
          });
          // alert(anyData.message);
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Fail',
            text: anyData.error[Object.keys(anyData.error)[0]]
          });
          // alert("success");
        }
      }, error => {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Network Problem',
          text: 'Please Try Again'
        });
        // alert("fail");
      });
    }else{
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: "New Password and Confirm Password Must be same!"
      });
      // alert("New Password and Confirm Password Must be same!")
    }
   
  }

}
