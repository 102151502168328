
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">KYC Verification</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> KYC &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active">KYC Verification</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<div class="card card-box">
								<div class="card-body" id="bar-parent">
                                    <h5>ID Proof Details: </h5>
                                    <table style="width: 100%;">
                                        <tr>
                                            <td><strong>Document Type: </strong>{{itemsList.idproof_types_id}}</td>
                                            <td><strong>Document Number: </strong>{{itemsList.idproof_no}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <img [src]="imageURL+itemsList.idproof_image_path" class="kycimg" alt="">
                                            </td>
                                        </tr>
                                    </table>
                                    
										
														<!-- <button type="button" (click)="verifyUserKYC()" style="float:right;"	class="btn btn-info m-r-20">Verify Now</button> -->
													
									
								</div>
                            </div>
                            <div class="card card-box">
								<div class="card-body" id="bar-parent">
                                    <h5>Address Proof Details: </h5>
                                    <table style="width: 100%;">
                                        <tr>
                                            <td><strong>Document Type: </strong>{{itemsList.addressproof_types_id}}</td>
                                            <td><strong>Document Number: </strong>{{itemsList.addressproof_no}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <img [src]="imageURL+itemsList.addressproof_image_path" class="kycimg" alt="">
                                            </td>
                                        </tr>
                                    </table>
                                    
										
														<!-- <button type="button" (click)="verifyUserKYC()" style="float:right;"	class="btn btn-info m-r-20">Verify Now</button> -->
													
									
								</div>
                            </div>
                            <div class="card card-box">
								<div class="card-body" id="bar-parent">
                                    <h5>Bank Details: </h5>
                                    <table style="width: 100%;">
                                        <tr>
                                            <td><strong>Bank Name: </strong>{{itemsList.bank_name}}</td>
                                            <td><strong>IFSC Code:</strong> {{itemsList.ifsc_code}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong> Account Number: </strong>{{itemsList.bank_account_no}}</td>
                                            <td><strong> Document Type: </strong>{{itemsList.bank_proof_types_id}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <img [src]="imageURL+itemsList.bank_account_image_path" class="kycimg" alt="">
                                            </td>
                                        </tr>
                                    </table>
                                    
										
														
													
									
								</div>
                            </div>
                            <button type="button" (click)="verifyUserKYC()" style="float:right;"	class="btn btn-info m-r-20">Verify Now</button>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>
</body>

