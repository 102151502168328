import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/data.service';
import { FormControl, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

declare function showDialog3(): any;

@Component({
  selector: 'app-profileupdate',
  templateUrl: './profileupdate.component.html',
  styleUrls: ['./profileupdate.component.scss']
})
export class ProfileupdateComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  operatorDetail: any;
  imageURL: string;
  profileimgurl: any;
  profilemessage: any;

  first_name = new FormControl('');
  last_name = new FormControl('');
  gender = new FormControl('');
  dob = new FormControl('');
  profile_image = new FormControl('');
  address1 = new FormControl('');
  address2 = new FormControl('');
  dist = new FormControl('');
  state = new FormControl('');
  pin_code = new FormControl('');

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
  ) {
    this.getUserDetail();
   }

  ngOnInit(): void {
  }

  readprofileimage(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.profilemessage = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
        this.profileimgurl = reader.result;
        console.log(this.profileimgurl);
    }
  }

  getUserDetail(){
    //loggedInOperatorId
    this.spinner.show();
    this.dataService.GetList('operators/profile').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.operatorDetail = anyData.operator;
      this.imageURL = 'https://twenty20mvision.com/twenty20/twenty20server/public/'+this.operatorDetail.image_path;
      this.first_name.setValue(this.operatorDetail.first_name);
      this.last_name.setValue(this.operatorDetail.last_name);
      this.gender.setValue(this.operatorDetail.gender);
      //this.datePipe.transform(this.userDetails.dob, 'yyyy-MM-dd')
      this.dob.setValue(this.datePipe.transform(this.operatorDetail.dob, 'yyyy-MM-dd'));
      this.address1.setValue(this.operatorDetail.address1);
        this.address2.setValue(this.operatorDetail.address2);
        this.dist.setValue(this.operatorDetail.dist);
        this.state.setValue(this.operatorDetail.state);
        this.pin_code.setValue(this.operatorDetail.pin_code);
      console.log(this.operatorDetail);
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
      
      
    }, error => {
      this.spinner.hide();
      showDialog3();
    });
  
  }

  onSubmit(form: NgForm) {
    const datatosend = {
      "first_name" : this.first_name.value,
      "last_name" : this.last_name.value,
      "gender" : this.gender.value,
      "dob" : this.dob.value,
      "address" : {
        "address1" : this.address1.value,
        "address2" : this.address2.value,
        "dist" : this.dist.value,
        "state" : this.state.value,
        "pin_code" : this.pin_code.value,
      },
      "image_path" : this.profileimgurl
    }
    console.log(datatosend);
    this.spinner.show();
    this.dataService.ProfileUpdate('operators/profile',datatosend).subscribe((data) => {
      this.spinner.hide();
      // alert("success");
      const anyData = data as any;
      if(anyData.status === 200){
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Profile Updated Successfully'
        });
      }else{        
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
     
    }, error => {
      this.spinner.hide();
      // alert("fail");
      Swal.fire({
        icon: 'error',
        title: 'Network Error',
        text: 'Please Try Again'
      });
    });


  }


}
