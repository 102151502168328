
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Add Operator Address</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Operators &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active">Add Operator</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<div class="card card-box">
								<div class="card-body" id="bar-parent">
									<form id="form_sample_1" class="form-horizontal" #addrForm="ngForm" (ngSubmit)="onSubmit(addrForm)" method="post">
										<div class="form-body">												
											<div class="form-group row" >
												<label class="control-label col-md-3">Address 1<span class="required"> * </span>
												</label>
												<div class="col-md-5">
													<input type="text" [formControl]="address1" data-required="1"
														placeholder="Address Line 1"
														class="form-control input-height" />
												</div>
                      						</div>
                      						<div class="form-group row">
												<label class="control-label col-md-3">Address 2
												</label>
												<div class="col-md-5">
													<input type="text" [formControl]="address2" data-required="1"
														placeholder="Address Line 2"
														class="form-control input-height" />
												</div>
                      						</div>

                      						<div class="form-group row" >
												<label class="control-label col-md-3">District<span class="required"> * </span>
												</label>
												<div class="col-md-5">
													<input type="text" [formControl]="dist" data-required="1"
														placeholder="District"
														class="form-control input-height" />
												</div>
                      						</div>
                      						<div class="form-group row">
												<label class="control-label col-md-3">State<span class="required"> * </span>
												</label>
												<div class="col-md-5">
													<input type="text" [formControl]="state" data-required="1"
														placeholder="State"
														class="form-control input-height" />
												</div>
                      						</div>
                      						<div class="form-group row">
												<label class="control-label col-md-3">PIN
												</label>
												<div class="col-md-5">
													<input type="text" [formControl]="pin_code" data-required="1"
														placeholder="PIN"
														class="form-control input-height" />
												</div>
                      						</div>
											<div class="form-actions">
												<div class="row">
													<div class="offset-md-3 col-md-9">
														<button type="submit"	class="btn btn-info m-r-20">Submit</button>
														<button type="button" class="btn btn-default">Cancel</button>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>
</body>

