import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { FormControl, FormGroup, NgForm, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  user_mobile: any;

  new_password = new FormControl('');
  confirm_password = new FormControl('');

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private dataService: DataService
  ) {
      // alert(JSON.stringify(router.getCurrentNavigation().extras.state));
      this.user_mobile = router.getCurrentNavigation().extras.state.mobile;
      // alert(this.user_mobile);
   }

  ngOnInit(): void {
  }

  onReset(form: NgForm){
    if(this.confirm_password.value === this.new_password.value){
      const datatosend = {
        "mobile_no" : this.user_mobile,
        "new_password" : this.new_password.value,
        "confirm_password" : this.confirm_password.value,
        "type" : "operator"
      }
      // alert(JSON.stringify(datatosend));
      this.spinner.show();
      this.dataService.Create('operators/forgot-password',datatosend).subscribe((data) => {
        this.spinner.hide();
        const anyData = data as any;
        if(anyData.status === 200){
          Swal.fire({
            title: 'Success',
            text: 'Password Changed Successfully, Go to login Page.',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
          }).then((result) => {
            // alert(result.value);
            if (result.value) {
              
              this.router.navigate(['operator/login']);
            }
          });
        // Swal.fire({
        //   icon: 'success',
        //   title: 'Success',
        //   text: anyData.message
        // });
        // this.router.navigate(['operator/login']);
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: anyData.error[Object.keys(anyData.error)[0]]
          });
          // alert(anyData.message);
        }
        
      }, error => {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Network Error',
          text: 'Please Try Again'
        });
        // alert("fail");
      });

    }else{
      Swal.fire({
        icon: 'error',
        title: 'Password Mismatch',
        text: 'New Password and Confirm Password Must be Same'
      });

    }


  }

}
