import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private router:Router, authService:AuthService) {
    sessionStorage.removeItem('isUserloggedIn');
    sessionStorage.removeItem('token');
    authService.isloggedIn = false;
    this.router.navigate(['/operator/login']);

   }

  ngOnInit(): void {
  }

}
