import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-datewisereport',
  templateUrl: './datewisereport.component.html',
  styleUrls: ['./datewisereport.component.scss']
})
export class DatewisereportComponent implements OnInit {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  public loading = false;

  itemsList: any;
  newitemsList: any =[];
  exportList: any;
  dataForExcel = [];
  dataForView = [];
  counter : number;
  curdate: any;
  fromDate: any;
  toDate: any;
  project_id: number;
  projectList: any = [];
  headerList: any;
  url: string;
  reportName: string;
  pageurl: string;


  constructor(
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public ete: ExportExcelService,
    private datePipe: DatePipe

  ) {
    this.pageurl = this.router.url;
    console.log(this.router.url);
    // if(this.pageurl === '/manpowerbydate'){
    //   this.url = 'report/manpower-report-by-date/';
    //   this.reportName = 'Man Power';
    //   this.headerList = ["SR NO.","DATE","SUPPLIER NAME","PROJECT","MANPOWER TYPE","SUPPLY TYPE","PER DAY RATE","NO. OF LABOURS","WORKING AMOUNT","REMARKS"];
    // }else if(this.pageurl === '/materialbydate'){
    //   this.url = 'report/material-report-by-date/';
    //   this.reportName = 'Material';
    //   this.headerList = ["SR NO.","DATE","SUPPLIER NAME","SITE NAME","MATERIAL","QUANTITY","RATE","UNIT"];
    // }else if(this.router.url === '/expensereportbydate'){
      this.url = 'report/miscexpense-report-by-date/';
      this.reportName = 'Miscellaneous';
      this.headerList = ["SR NO.","DATE","BRANCH NAME","EXPENSE TYPE","AMOUNT","DESCRIPTION"];
    // }
    this.itemsList = [];
    this.exportList = []
    this.curdate = new Date();
    this.fromDate = this.curdate.getFullYear()+'-'+this.curdate.getMonth()+'-'+(this.curdate.getDate());
    this.toDate = this.curdate.getFullYear()+'-'+(this.curdate.getMonth()+1)+'-'+(this.curdate.getDate()+1);
    // this.getProjectList();

   }


exportToExcel(){
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  this.dataForExcel = [];
  this.newitemsList.forEach((row: any) => {
    this.dataForExcel.push(Object.values(row))
  })      
  let reportData = {
    title: this.reportName+' Report -' + dd + '-' + mm + '-' + yyyy,
    data: this.dataForExcel,
    headers: this.headerList
  }   
  this.ete.exportExcel(reportData);
  }

  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
  var popupWin = window.open('', '_blank', 'width=1200,height=1200');
  popupWin.document.open();
  popupWin.document.write('<html><head><script type="text/javascript">function PrintWindow(){ window.print(); CheckWindowState();}  function CheckWindowState(){ if(document.readyState=="complete") {  window.close();  }  else  {  setTimeout("CheckWindowState()", 2000)  }  }  </script><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="PrintWindow()">' + printContents + '</body></html>');
  popupWin.document.close();
}

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.itemsList = [];
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void{
  }


  getItemsList(){
    this.counter = 1;
    this.newitemsList = [];
    this.spinner.show();
      this.dataService.GetDetailsDatewise('report/miscexpense-report-by-date/', this.fromDate, this.toDate).subscribe((data) => {
        const anyData = data as any;
        
        this.spinner.hide();
        if(anyData.status === 200){
          this.itemsList = [];
            this.itemsList = anyData.mexpense;
            for(let i=0; i<this.itemsList.length; i++){
              const datalist = {
                "sr_no" : this.counter++,
                "created_at": this.datePipe.transform(this.itemsList[i].actual_date, 'dd-MM-yyyy'),
                "site": this.itemsList[i].branch.branch_name,
                "expense_type": this.itemsList[i].expensetype.misc_exp_type_name,
                "amount": this.itemsList[i].amount,
                "description": this.itemsList[i].description              
              }
              this.newitemsList.push(datalist);
            }            
          // }
          this.dataForView = [];
          this.newitemsList.forEach((row: any) => {
            this.dataForView.push(Object.values(row))
          })  
          // console.log(this.dataForView);
          this.dtTrigger.next();
          this.dtTrigger.unsubscribe(); 
          // this.dtTrigger = new Subject();         
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: anyData.error[Object.keys(anyData.error)[0]]
          });
        }
       
      }, error => {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Network error, Try again!'
        });
      });
  }
}
