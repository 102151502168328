<html xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40">

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<link rel=File-List href="twenty20taxinvoice_files/filelist.xml">

<link rel=themeData href="twenty20taxinvoice_files/themedata.thmx">
<link rel=colorSchemeMapping href="twenty20taxinvoice_files/colorschememapping.xml">

</head>

<body lang=EN-US style="background: #ffffff; margin: 0px auto;" >

<div class=WordSection1>

<p class=MsoNormal    style='text-align:center'><b><span
style='font-size:10.0pt;line-height:115%;'>Tax Invoice   </span></b></p>

<table class=MsoTableGrid  cellspacing=0 cellpadding=0 style='margin: 0px auto; border-collapse:collapse;border:none;'>
 <tr style='height:25.05pt'>
    <td width=281 colspan=3 rowspan=3 valign=top style='width:210.95pt; border:solid black 1.0pt;padding:0in 5.4pt 0in 5.4pt;height:25.05pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;'> &nbsp;  </span></p>
  </td>
  <td width=198 colspan=8 valign=top style='width:148.85pt;border:solid black 1.0pt;
  border-left:none;padding:0in 5.4pt 0in 5.4pt;height:25.05pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Invoice No.:   </span></p>
  </td>
  <td width=227 colspan=5 valign=top style='width:170.1pt;border:solid black 1.0pt;
    border-left:none;  
        padding:0in 5.4pt 0in 5.4pt;height:25.05pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Date:   </span></p>
  </td>
 </tr>
 <tr style='  height:29.7pt'>
  <td width=198 colspan=8 valign=top style='width:148.85pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:29.7pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Delivery Note:   </span></p>
  </td>
  <td width=227 colspan=5 valign=top style='width:170.1pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:29.7pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Mode/Terms of Payment   </span></p>
  </td>
 </tr>
 <tr style='  height:28.75pt'>
  <td width=198 colspan=8 valign=top style='width:148.85pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:28.75pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Supplier’s Ref:   </span></p>
  </td>
  <td width=227 colspan=5 valign=top style='width:170.1pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:28.75pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Other Reference(s)   </span></p>
  </td>
 </tr>
 <tr style='  height:26.8pt'>
  <td width=281 colspan=3 rowspan=4 valign=top style='width:210.95pt;
  border:solid black 1.0pt;  border-top:none;
      
      padding:0in 5.4pt 0in 5.4pt;
  height:26.8pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=198 colspan=8 valign=top style='width:148.85pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:26.8pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Buyer’s Order No.:   </span></p>
  </td>
  <td width=227 colspan=5 valign=top style='width:170.1pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:26.8pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Date:   </span></p>
  </td>
 </tr>
 <tr style='  height:26.6pt'>
  <td width=198 colspan=8 valign=top style='width:148.85pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:26.6pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Dispatch Document No.:   </span></p>
  </td>
  <td width=227 colspan=5 valign=top style='width:170.1pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:26.6pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Delivery Note Date:   </span></p>
  </td>
 </tr>
 <tr style='height:27.0pt'>
  <td width=198 colspan=8 valign=top style='width:148.85pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:27.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Dispatched Through:   </span></p>
  </td>
  <td width=227 colspan=5 valign=top style='width:170.1pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:27.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Destination:   </span></p>
  </td>
 </tr>
 <tr style='height:42.0pt'>
  <td width=425 colspan=13 valign=top style='width:318.95pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:42.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Terms of Delivery:   </span></p>
  </td>
 </tr>
 <tr >
  <td width=52 valign=top style='width:39.2pt;border:solid black 1.0pt;
    border-top:none;  
        padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span class=SpellE><span
  style='font-size:10.0pt;    '>Sl</span></span><span style='font-size:10.0pt;    '>   </span></p>
  </td>
  <td width=250 colspan=3 valign=top style='width:2.6in;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Description   </span></p>
  </td>
  <td width=83 colspan=4 valign=top style='width:62.5pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>HSN/SAC   </span></p>
  </td>
  <td width=76 colspan=2 valign=top style='width:56.7pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Quantity   </span></p>
  </td>
  <td width=66 colspan=3 valign=top style='width:49.65pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Rate   </span></p>
  </td>
  <td width=76 colspan=2 valign=top style='width:56.7pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Per   </span></p>
  </td>
  <td width=104 valign=top style='width:77.95pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;  
  border-right:solid black 1.0pt;    
        padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Amount   </span></p>
  </td>
 </tr>
 <tr style='height:170.0pt'>
  <td width=52 valign=top style='width:39.2pt;border:solid black 1.0pt;
    border-top:none;  
        padding:0in 5.4pt 0in 5.4pt;height:170.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=250 colspan=3 valign=top style='width:2.6in;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:170.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=83 colspan=4 valign=top style='width:62.5pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:170.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=76 colspan=2 valign=top style='width:56.7pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:170.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=66 colspan=3 valign=top style='width:49.65pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:170.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=76 colspan=2 valign=top style='width:56.7pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:170.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=104 valign=top style='width:77.95pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;  
  border-right:solid black 1.0pt;        
        padding:0in 5.4pt 0in 5.4pt;height:170.0pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
 </tr>
 <tr >
  <td width=52 valign=top style='width:39.2pt;border:solid black 1.0pt;
    border-top:none;  
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=250 colspan=3 valign=top style='width:2.6in;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal  style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:right;line-height:normal'><span style='font-size:10.0pt;
      '>Total   </span></p>
  </td>
  <td width=83 colspan=4 valign=top style='width:62.5pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=76 colspan=2 valign=top style='width:56.7pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=66 colspan=3 valign=top style='width:49.65pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=76 colspan=2 valign=top style='width:56.7pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=104 valign=top style='width:77.95pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;  
  border-right:solid black 1.0pt;   
        padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
 </tr>
 <tr style='height:36.2pt'>
  <td width=707 colspan=16 valign=top style='width:529.9pt;border:solid black 1.0pt;
    border-top:none;  
        padding:0in 5.4pt 0in 5.4pt;height:36.2pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Amount in Words:<span
  >                                                                                                                                
  </span><span >                            </span><span
  > </span>E. &amp; O.E.   </span></p>
  </td>
 </tr>
 <tr >
  <td width=234 colspan=2 valign=top style='width:175.5pt;border:solid black 1.0pt;
    border-top:none;  
        padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>HSN/SAC   </span></p>
  </td>
  <td width=76 colspan=3 rowspan=2 valign=top style='width:56.7pt;border-top:
  none;border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Taxable Value   </span></p>
  </td>
  <td width=132 colspan=4 valign=top style='width:99.25pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Central Tax   </span></p>
  </td>
  <td width=132 colspan=5 valign=top style='width:99.2pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>State Tax   </span></p>
  </td>
  <td width=132 colspan=2 rowspan=2 valign=top style='width:99.25pt;border-top:
  none;border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Total Tax
  Amount   </span></p>
  </td>
 </tr>
 <tr >
  <td width=234 colspan=2 valign=top style='width:175.5pt;border:solid black 1.0pt;
    border-top:none;  
        padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=66 colspan=2 valign=top style='width:49.65pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Rate   </span></p>
  </td>
  <td width=66 colspan=2 valign=top style='width:49.6pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Amount   </span></p>
  </td>
  <td width=57 colspan=3 valign=top style='width:42.5pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Rate   </span></p>
  </td>
  <td width=76 colspan=2 valign=top style='width:56.7pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal    style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:center;line-height:normal'><span style='font-size:10.0pt;
      '>Amount   </span></p>
  </td>
 </tr>
 <tr>
  <td width=234 colspan=2 valign=top style='width:175.5pt;border:solid black 1.0pt;
    border-top:none;  
        padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=76 colspan=3 valign=top style='width:56.7pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=66 colspan=2 valign=top style='width:49.65pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=66 colspan=2 valign=top style='width:49.6pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=57 colspan=3 valign=top style='width:42.5pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=76 colspan=2 valign=top style='width:56.7pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=132 colspan=2 valign=top style='width:99.25pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
 </tr>
 <tr >
  <td width=234 colspan=2 valign=top style='width:175.5pt;border:solid black 1.0pt;
    border-top:none;  
        padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
  text-align:right;line-height:normal'><span style='font-size:10.0pt;
      '>Total   </span></p>
  </td>
  <td width=76 colspan=3 valign=top style='width:56.7pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=66 colspan=2 valign=top style='width:49.65pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=66 colspan=2 valign=top style='width:49.6pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=57 colspan=3 valign=top style='width:42.5pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=76 colspan=2 valign=top style='width:56.7pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
  <td width=132 colspan=2 valign=top style='width:99.25pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
 </tr>
 <tr style='height:141.75pt'>
  <td width=707 colspan=16 valign=top style='width:529.9pt;border:solid black 1.0pt;
    border-top:none;  
        padding:0in 5.4pt 0in 5.4pt;height:141.75pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
 </tr>
 <tr style='height:63.55pt'>
  <td width=357 colspan=6 valign=top style='width:267.65pt;border:solid black 1.0pt;
    border-top:none;  
        padding:0in 5.4pt 0in 5.4pt;height:63.55pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '>Customer’s Seal and Signature   </span></p>
  </td>
  <td width=350 colspan=10 valign=top style='width:262.25pt;border-top:none;
  border-left:none;border-bottom:solid black 1.0pt;  border-right:solid black 1.0pt;  
      
      
      padding:0in 5.4pt 0in 5.4pt;
  height:63.55pt'>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'><span style='font-size:10.0pt;  '> &nbsp;  </span></p>
  </td>
 </tr>
 <tr height=0>
  <td width=52 style='border:none'></td>
  <td width=182 style='border:none'></td>
  <td width=47 style='border:none'></td>
  <td width=21 style='border:none'></td>
  <td width=8 style='border:none'></td>
  <td width=47 style='border:none'></td>
  <td width=19 style='border:none'></td>
  <td width=9 style='border:none'></td>
  <td width=57 style='border:none'></td>
  <td width=19 style='border:none'></td>
  <td width=19 style='border:none'></td>
  <td width=19 style='border:none'></td>
  <td width=28 style='border:none'></td>
  <td width=47 style='border:none'></td>
  <td width=28 style='border:none'></td>
  <td width=104 style='border:none'></td>
 </tr>
</table>
<p class=MsoNormal  style='text-align:center'><span
style='font-size:10.0pt;line-height:115%;  
  '>This is a Computer Generated Invoice</span></p>

</div>

</body>

</html>
