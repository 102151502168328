import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { 
    // this.triggerFalseClick();
    
  }

  ngOnInit(): void {
    let element:HTMLElement = document.getElementById('auto_trigger') as HTMLElement;

    element.click();
  }

//   @ViewChild('myLink') myDiv: ElementRef<HTMLElement>;

//   triggerFalseClick() {
//    let el: HTMLElement = this.myDiv.nativeElement;
//    el.click();
// }

}
