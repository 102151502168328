import { Component, OnInit } from '@angular/core';

import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

declare function showDialog7(): any;
declare function showDialog6(): any;
declare function showDialog3(): any;
declare function deleteRecord(url, id): any;


@Component({
  selector: 'app-paymentuserpayment',
  templateUrl: './paymentuserpayment.component.html',
  styleUrls: ['./paymentuserpayment.component.scss']
})
export class PaymentuserpaymentComponent implements OnInit {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  sub: any;

  dtOptions: DataTables.Settings = {};
  // persons: Person[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();



  public loading = false;

  itemsList: any;
  transaction_id: any;
  transaction_amount: any;
  transaction_date: any;
  operatorid: string;
  myDate = new Date();

  constructor(
    public dataService: DataService,
    private envService: EnvService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe

  ) {
    this.transaction_amount = [];
    this.transaction_date = [];
    this.transaction_id = [];


   }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.itemsList = [];
    this.getItemsList();
    // this.sub = this.route.params.subscribe(params => {
    //   sessionStorage.setItem('activeMenuId', params['id']);
    // });   
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    // this.sub.unsubscribe();
  }

  ngAfterViewInit(): void{
    // this.dtTrigger.next();
  }


  getItemsList(){
    this.spinner.show();
    this.dataService.GetList('payment/users-dues').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){

        
        this.itemsList = anyData.user_dues_balances;
        for(let i=0; i< this.itemsList.length; i++){
          this.transaction_date[i] = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
          this.transaction_id[i] = '';
          this.transaction_amount[i] = '';
        }
        this.dtTrigger.next();
        this.dtTrigger.unsubscribe();
        console.log(JSON.stringify(this.itemsList));
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
     
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

  payNow(id, sl){
    const datatosend = {
      "user_id" : id,
      "transaction_id" : this.transaction_id[sl],
      "amount" : this.transaction_amount[sl],
      "transaction_date" : this.transaction_date[sl]
    }
    this.spinner.show();
    this.dataService.Create('payment/users', datatosend).subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
       this.getItemsList();
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
     
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

 
}
