import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ChangepwdComponent } from './pages/changepwd/changepwd.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthService } from '../app/auth/auth.service';
import { from } from 'rxjs';
import { AuthGuard } from './auth/auth.guard';
import { LogoutComponent } from './pages/logout/logout.component';
import { EnvService } from './services/env.service';
import { HomeComponent } from './pages/home/home.component';
import { OperatorComponent } from './pages/operator/operator.component';
import { AddoperatorComponent } from './pages/addoperator/addoperator.component';
import { UpdateoperatorComponent } from './pages/updateoperator/updateoperator.component';
import { UserComponent } from './pages/user/user.component';
import { AdduserComponent } from './pages/adduser/adduser.component';
import { UpdateuserComponent } from './pages/updateuser/updateuser.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SupplierComponent } from './pages/supplier/supplier.component';
import { SupplieraddComponent } from './pages/supplieradd/supplieradd.component';
import { SuppliereditComponent } from './pages/supplieredit/supplieredit.component';
import { DataTablesModule } from 'angular-datatables';
import {  RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { BranchComponent } from './pages/branch/branch.component';
import { BranchaddComponent } from './pages/branchadd/branchadd.component';
import { BrancheditComponent } from './pages/branchedit/branchedit.component';
import { ProductcategoryComponent } from './pages/productcategory/productcategory.component';
import { ProductcategoryaddComponent } from './pages/productcategoryadd/productcategoryadd.component';
import { ProductcategoryeditComponent } from './pages/productcategoryedit/productcategoryedit.component';
import { ProductsubcategoryComponent } from './pages/productsubcategory/productsubcategory.component';
import { ProductsubcategoryaddComponent } from './pages/productsubcategoryadd/productsubcategoryadd.component';
import { ProductsubcategoryeditComponent } from './pages/productsubcategoryedit/productsubcategoryedit.component';
import { ProductaddComponent } from './pages/productadd/productadd.component';
import { BrandComponent } from './pages/brand/brand.component';
import { BrandaddComponent } from './pages/brandadd/brandadd.component';
import { BrandeditComponent } from './pages/brandedit/brandedit.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { UserloginComponent } from './pages/userlogin/userlogin.component';
import { ProductComponent } from './pages/product/product.component';
import { ProducteditComponent } from './pages/productedit/productedit.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { BranchaddressComponent } from './pages/branchaddress/branchaddress.component';
import { OperatoraddressComponent } from './pages/operatoraddress/operatoraddress.component';
import { OperatoraddressaddComponent } from './pages/operatoraddressadd/operatoraddressadd.component';
import { OperatoraddresseditComponent } from './pages/operatoraddressedit/operatoraddressedit.component';
import { BranchaddressaddComponent } from './pages/branchaddressadd/branchaddressadd.component';
import { BranchaddresseditComponent } from './pages/branchaddressedit/branchaddressedit.component';
import { SupplieraddressComponent } from './pages/supplieraddress/supplieraddress.component';
import { SupplieraddressaddComponent } from './pages/supplieraddressadd/supplieraddressadd.component';
import { SupplieraddresseditComponent } from './pages/supplieraddressedit/supplieraddressedit.component';
import { KycComponent } from './pages/kyc/kyc.component';
import { KycverificationComponent } from './pages/kycverification/kycverification.component';
import { EncdecService } from './services/encdec.service';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileupdateComponent } from './pages/profileupdate/profileupdate.component';
import { PurchaseheaderComponent } from './pages/purchaseheader/purchaseheader.component';
import { PurchaseorderComponent } from './pages/purchaseorder/purchaseorder.component';
import { PurchaseheaderaddComponent } from './pages/purchaseheaderadd/purchaseheaderadd.component';
import { PurchaseheadereditComponent } from './pages/purchaseheaderedit/purchaseheaderedit.component';
import { PurchaseorderaddComponent } from './pages/purchaseorderadd/purchaseorderadd.component';
import { PurchaseordereditComponent } from './pages/purchaseorderedit/purchaseorderedit.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { DatePipe } from '@angular/common';
import { StockavailableComponent } from './pages/stockavailable/stockavailable.component';
import { StocktransferComponent } from './pages/stocktransfer/stocktransfer.component';
import { BillingmlmComponent } from './pages/billingmlm/billingmlm.component';
import { TaxinvoiceComponent } from './pages/taxinvoice/taxinvoice.component';
import { StocktransferaddComponent } from './pages/stocktransferadd/stocktransferadd.component';
import { StocktranfereditComponent } from './pages/stocktranferedit/stocktranferedit.component';
import { StocktransferdetailComponent } from './pages/stocktransferdetail/stocktransferdetail.component';
import { StockrequestComponent } from './pages/stockrequest/stockrequest.component';
import { PaymentuserpaymentComponent } from './pages/paymentuserpayment/paymentuserpayment.component';
import { PaymentsupplierpaymentComponent } from './pages/paymentsupplierpayment/paymentsupplierpayment.component';
import { PaymentsupplierduesComponent } from './pages/paymentsupplierdues/paymentsupplierdues.component';
import { ReportlevelwiseComponent } from './pages/reportlevelwise/reportlevelwise.component';
import { ReportmlmuserComponent } from './pages/reportmlmuser/reportmlmuser.component';
import { ReportsalesComponent } from './pages/reportsales/reportsales.component';
import { StockrequestdetailComponent } from './pages/stockrequestdetail/stockrequestdetail.component';
import { BillingotheruserComponent } from './pages/billingotheruser/billingotheruser.component';
import { BillingmlmhistoryComponent } from './pages/billingmlmhistory/billingmlmhistory.component';
import { BillingotherhistoryComponent } from './pages/billingotherhistory/billingotherhistory.component';
import { StocktransferhistoryComponent } from './pages/stocktransferhistory/stocktransferhistory.component';
import { BillingmlmhistoryprintComponent } from './pages/billingmlmhistoryprint/billingmlmhistoryprint.component';
import { StocktransferhistoryprintComponent } from './pages/stocktransferhistoryprint/stocktransferhistoryprint.component';
import { BillingotherhistoryprintComponent } from './pages/billingotherhistoryprint/billingotherhistoryprint.component';
import { SearchstocktransferComponent } from './pages/searchstocktransfer/searchstocktransfer.component';
import { PurchaseordercustomeditComponent } from './pages/purchaseordercustomedit/purchaseordercustomedit.component';
import { OrderModule } from 'ngx-order-pipe';
import { StockreturnComponent } from './pages/stockreturn/stockreturn.component';
import { PurchasereturnComponent } from './pages/purchasereturn/purchasereturn.component';
import { ReportpurchaseComponent } from './pages/reportpurchase/reportpurchase.component';
import { ReportsupplierComponent } from './pages/reportsupplier/reportsupplier.component';
import { ReportstocktransferComponent } from './pages/reportstocktransfer/reportstocktransfer.component';
import { StockreturnaddComponent } from './pages/stockreturnadd/stockreturnadd.component';
import { StockreturndetailComponent } from './pages/stockreturndetail/stockreturndetail.component';
import { StockreturneditComponent } from './pages/stockreturnedit/stockreturnedit.component';
import { StockreturnrequestComponent } from './pages/stockreturnrequest/stockreturnrequest.component';
import { StockreturnrequestdetailComponent } from './pages/stockreturnrequestdetail/stockreturnrequestdetail.component';
import { PurchasereturnaddComponent } from './pages/purchasereturnadd/purchasereturnadd.component';
import { PurchasereturndetailComponent } from './pages/purchasereturndetail/purchasereturndetail.component';
import { ExpensetypeComponent } from './pages/expensetype/expensetype.component';
import { DatewisereportComponent } from './report/datewisereport/datewisereport.component';
import { MiscexpenseallComponent } from './pages/miscexpenseall/miscexpenseall.component';
import { MiscexpenseaddComponent } from './pages/miscexpenseadd/miscexpenseadd.component';
import { MiscexpenseeditComponent } from './pages/miscexpenseedit/miscexpenseedit.component';
import { BranchwisereportComponent } from './report/branchwisereport/branchwisereport.component';


@NgModule({
  declarations: [
    AppComponent,
    SidemenuComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    ChangepwdComponent,
    LoginComponent,
    LogoutComponent,
    HomeComponent,
    OperatorComponent,
    AddoperatorComponent,
    UpdateoperatorComponent,
    UserComponent,
    AdduserComponent,
    UpdateuserComponent,
    SupplierComponent,
    SupplieraddComponent,
    SuppliereditComponent,
    BranchComponent,
    BranchaddComponent,
    BrancheditComponent,
    ProductcategoryComponent,
    ProductcategoryaddComponent,
    ProductcategoryeditComponent,
    ProductsubcategoryComponent,
    ProductsubcategoryaddComponent,
    ProductsubcategoryeditComponent,
    ProductaddComponent,
    BrandComponent,
    BrandaddComponent,
    BrandeditComponent,
    RegistrationComponent,
    UserloginComponent,
    ProductComponent,
    ProducteditComponent,
    ResetpasswordComponent,
    BranchaddressComponent,
    OperatoraddressComponent,
    OperatoraddressaddComponent,
    OperatoraddresseditComponent,
    BranchaddressaddComponent,
    BranchaddresseditComponent,
    SupplieraddressComponent,
    SupplieraddressaddComponent,
    SupplieraddresseditComponent,
    KycComponent,
    KycverificationComponent,
    ProfileComponent,
    ProfileupdateComponent,
    PurchaseheaderComponent,
    PurchaseorderComponent,
    PurchaseheaderaddComponent,
    PurchaseheadereditComponent,
    PurchaseorderaddComponent,
    PurchaseordereditComponent,
    PurchaseComponent,
    StockavailableComponent,
    StocktransferComponent,
    BillingmlmComponent,
    TaxinvoiceComponent,
    StocktransferaddComponent,
    StocktranfereditComponent,
    StocktransferdetailComponent,
    StockrequestComponent,
    PaymentuserpaymentComponent,
    PaymentsupplierpaymentComponent,
    PaymentsupplierduesComponent,
    ReportlevelwiseComponent,
    ReportmlmuserComponent,
    ReportsalesComponent,
    StockrequestdetailComponent,
    BillingotheruserComponent,
    BillingmlmhistoryComponent,
    BillingotherhistoryComponent,
    StocktransferhistoryComponent,
    BillingmlmhistoryprintComponent,
    StocktransferhistoryprintComponent,
    BillingotherhistoryprintComponent,
    SearchstocktransferComponent,
    PurchaseordercustomeditComponent,
    StockreturnComponent,
    PurchasereturnComponent,
    ReportpurchaseComponent,
    ReportsupplierComponent,
    ReportstocktransferComponent,
    StockreturnaddComponent,
    StockreturndetailComponent,
    StockreturneditComponent,
    StockreturnrequestComponent,
    StockreturnrequestdetailComponent,
    PurchasereturnaddComponent,
    PurchasereturndetailComponent,
    ExpensetypeComponent,
    DatewisereportComponent,
    MiscexpenseallComponent,
    MiscexpenseaddComponent,
    MiscexpenseeditComponent,
    BranchwisereportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    DataTablesModule,
    RxReactiveFormsModule,
    BrowserAnimationsModule,
    OrderModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthService,
    AuthGuard,
    EnvService,
    EncdecService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
