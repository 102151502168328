import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-billingotherhistory',
  templateUrl: './billingotherhistory.component.html',
  styleUrls: ['./billingotherhistory.component.scss']
})
export class BillingotherhistoryComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  sub: any;

  dtOptions: DataTables.Settings = {};
  // persons: Person[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  public loading = false;

  itemsList: any;
  exportList: any;
  operatorid: string;
  dataForExcel = [];
  newExportList : any = [];
  curdate: any;
  fromDate: any;
  toDate: any;

  empPerformance = [
    { ID: 10011, NAME: "A", DEPARTMENT: "Sales", MONTH: "Jan", YEAR: 2020, SALES: 132412, CHANGE: 12, LEADS: 35, DATE: "18-02-2021" },
    { ID: 10012, NAME: "A", DEPARTMENT: "Sales", MONTH: "Feb", YEAR: 2020, SALES: 232324, CHANGE: 2, LEADS: 443, DATE: "18-02-2021" },
    { ID: 10013, NAME: "A", DEPARTMENT: "Sales", MONTH: "Mar", YEAR: 2020, SALES: 542234, CHANGE: 45, LEADS: 345, DATE: "18-02-2021" },
    { ID: 10014, NAME: "A", DEPARTMENT: "Sales", MONTH: "Apr", YEAR: 2020, SALES: 223335, CHANGE: 32, LEADS: 234, DATE: "18-02-2021" },
    { ID: 10015, NAME: "A", DEPARTMENT: "Sales", MONTH: "May", YEAR: 2020, SALES: 455535, CHANGE: 21, LEADS: 12, DATE: "18-02-2021" },
  ];

  constructor(
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    public ete: ExportExcelService,
    private datePipe: DatePipe

  ) {
    this.itemsList = [];

    this.exportList = []
    this.curdate = new Date();
    this.fromDate = this.curdate.getFullYear()+'-'+this.curdate.getMonth()+'-'+(this.curdate.getDate());
    this.toDate = this.curdate.getFullYear()+'-'+(this.curdate.getMonth()+1)+'-'+(this.curdate.getDate()+1);
    // this.getItemsList();

   }

 exportToExcel(){

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

    this.spinner.show();
    this.dataService.GetList('operators/get-bank-account-info-of-verified-users').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.dataForExcel = [];
        this.exportList = anyData.users;
        for(let i=0; i< this.exportList.length; i++){
          const datalist = {
            "first_name" : this.exportList[i].first_name,
            "last_name" : this.exportList[i].first_name,
            "mobile_no" : this.exportList[i].first_name,
            "balance" : this.exportList[i].first_name,
            "bank_name" : this.exportList[i].kycdetail.bank_name,
            "account_no" : this.exportList[i].kycdetail.account_no,
            "ifsc_code" : this.exportList[i].kycdetail.ifsc_code,
          }
          this.newExportList.push(datalist);
        }
        this.newExportList.forEach((row: any) => {
          this.dataForExcel.push(Object.values(row))
        })

        // alert(JSON.stringify(Object.keys(this.exportList[0])));
    
        let reportData = {
          title: 'Users Bank Details -' + dd + '-' + mm + '-' + yyyy,
          data: this.dataForExcel,
          headers: ["FIRST NAME","LAST NAME","MOBILE NO.","BALANCE","BANK NAME","ACCOUNT NO.","IFSC CODE"]
          // headers: Object.keys(this.exportList[0])
        }
    
        this.ete.exportExcel(reportData);
        // this.dtTrigger.next();
        // this.dtTrigger.unsubscribe();
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
     
    }, error => {
      // showDialog3();
      this.spinner.hide();
    });
  }


  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.itemsList = [];
    
    // this.dtTrigger.next();
    // this.sub = this.route.params.subscribe(params => {
    //   sessionStorage.setItem('activeMenuId', params['id']);
    // });   
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    // this.sub.unsubscribe();
  }

  ngAfterViewInit(): void{
    // this.dtTrigger.next();
  }

  viewInvoiceDetail(id){
    sessionStorage.setItem('order_id', id);
    // this.router.navigate(['billing/otherhistory/print']);
    let element:HTMLElement = document.getElementById('auto_trigger') as HTMLElement;

    element.click();
  }

  deleteInvoiceDetail(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
          this.spinner.show();
            this.dataService.GetDetails('delete-other-invoice-by-id/', id).subscribe((data) => {
              this.spinner.hide();
              const anyData = data as any;
                  if(anyData.status === 200){
                    Swal.fire(
                      'Submitted!',
                      'Invoice Deleted Successfully.',
                      'success'
                    )
                    this.getItemsList();
                  }else{        
                    Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: anyData.error[Object.keys(anyData.error)[0]]
                    });
                  }
            }, error => {
              this.spinner.hide();
              // showDialog3();
            });
      }
    })
  }

  getItemsList(){
    this.itemsList = [];
    this.spinner.show();
    const datatosend = {
      "from" : this.fromDate,
      "to" : this.toDate
    }
    this.dataService.Create('billing/get-other-user-invoices-by-date', datatosend).subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){        
        this.itemsList = anyData.invoices;
        this.dtTrigger.next();
        this.dtTrigger.unsubscribe();
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
     
    }, error => {
      // showDialog3();
      this.spinner.hide();
    });
  }




}
