import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvService } from '../services/env.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, envService: EnvService){

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean {
    // if (EnvService.prototype.hasToken) {
    //   return true;
    // } else {
    //   return false;
    // }
    if(sessionStorage.getItem('isUserloggedIn') === 'loggedin' || this.authService.isloggedIn === true){
      return true;
    }else{
      return false;
    }
  }
}
