
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Expense Type List</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Expense Type &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Expense Type List</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="tabbable-line">
								
								<div class="tab-content">
									<div class="tab-pane active fontawesome-demo" id="tab1">
										<div class="row">
											<div class="col-md-12">
												<div class="card card-box">
													<div class="card-head">
														<header>All Expense Type</header>
														<div class="tools">
															<a class="fa fa-repeat btn-color box-refresh"
																href="javascript:;"></a>
															<a class="t-collapse btn-color fa fa-chevron-down"
																href="javascript:;"></a>
															<a class="t-close btn-color fa fa-times"
																href="javascript:;"></a>
														</div>
													</div>
													<div class="card-body ">
														<div class="row">
															<div class="col-md-12 col-sm-12 col-12">
																<table>
                                                                    <tr>
                                                                        <td class="col-md-4 col-sm-4 col-4">
                                                                            Misc. Expense Type Name:
                                                                            <input type="text" [(ngModel)]="add_ms_name" class="form-control">
                                                                        </td>
                                                                        <td class="col-md-4 col-sm-4 col-4">
                                                                            <button type="button" class="btn btn-primary btn-xl" (click)="onSubmit()">
                                                                                Submit
																			</button>
                                                                        </td>
                                                                    </tr>
                                                                </table>
															</div>
														</div>
														<div class="table-scrollable">
															<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-hover table-checkable order-column valign-middle btn-sweetalert">
																<thead>
																	<tr>
																		<th>#</th>
																		<th> Misc. Expense Type Name </th>
																		<th> Action </th>
																	</tr>
																</thead>
																<tbody>
																	<tr class="odd gradeX" *ngFor="let item of itemsList; let i = index">
																		<td class="patient-img">
																			{{i+1}}
																		</td>
																		<td class="left"><input type="text" [(ngModel)]="ms_name[i]" class="form-control"></td>
																		<td>
																			<button type="button" class="btn btn-warning btn-xs" (click)="update(item.id, i)">
                                                                                Update
																			</button>
																			<!-- <button type="button" class="btn btn-danger btn-xs" (click)="exportToExcel(item.id)">
																				Export
																			</button> -->
                                                                        </td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>

</body>

