import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

declare function showDialog6(): any;
declare function showDialog3(): any;
declare function showDialog11(): any;


@Component({
  selector: 'app-productedit',
  templateUrl: './productedit.component.html',
  styleUrls: ['./productedit.component.scss']
})
export class ProducteditComponent implements OnInit {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  public imagePath;
  categoryList: any;
  category_id: any;
  subcategory_id: any;
  subcategoryList: any;
  brandList: any;
  supplierList: any;
  imageurl: any;
  message: string;
  subcategoryid: number;


  productForm = new FormGroup({category_id: new FormControl(), subcategory_id: new FormControl(), product_name: new FormControl(), model_no: new FormControl(), hsn_code: new FormControl(), product_code: new FormControl(), image_path: new FormControl(), description: new FormControl(), brand_id: new FormControl()});

  productid : string;
  itemList: any;



  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
    ) {
      this.itemList = [] ;
    this.getProductDetails(localStorage.getItem('activeid'));
    this.categoryList = [];
    this.subcategoryList = [];
    this.brandList = [];
    this.supplierList = [];
    this.getProductCategoriesList();
    this.getSuppliersList();
    this.getBrandsList();
   }

  ngOnInit(){

  }

  getBrandsList(){
    this.dataService.GetList('brands').subscribe((data) => {
      const anyData = data as any;
      this.brandList = anyData.brands;
    });
    // alert(id);
  }

  getSuppliersList(){
    this.dataService.GetList('suppliers').subscribe((data) => {
      const anyData = data as any;
      this.supplierList = anyData.suppliers;
    });
    // alert(id);
  }


  getSelectedProductCategory(id: number){
    this.category_id = id;
    this.spinner.show();
    this.dataService.GetDetails('subcategories-by-category/', id).subscribe((data) => {
      const anyData = data as any;
      this.subcategoryList = anyData.subcategories;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
    // alert(id);
  }

  getSelectedProductSubcategory(id: number){
    this.subcategory_id = id;
    // alert(id);
  }

  getProductCategoriesList(){
    this.spinner.show();
    this.dataService.GetList('productcategories').subscribe((data) => {
      const anyData = data as any;
      this.categoryList = anyData.categories;
      // this.dtTrigger.next();
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  // readURL(fileInput: any) {
  //   this.imageError = null;
  //       if (fileInput.target.files && fileInput.target.files[0]) {
  //           // Size Filter Bytes
  //           const max_size = 20971520;
  //           const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
  //           const max_height = 15200;
  //           const max_width = 25600;

  //           if (fileInput.target.files[0].size > max_size) {
  //               this.imageError =
  //                   'Maximum size allowed is ' + max_size / 1000 + 'Mb';

  //               return false;
  //           }
  //           // alert(fileInput.target.files[0].type);

  //           if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
  //               this.imageError = 'Only Images are allowed ( JPG | PNG )';
  //               return false;
  //           }
  //           const reader = new FileReader();
  //           reader.onload = (e: any) => {
  //               const image = new Image();
  //               image.src = e.target.result;
  //               image.onload = rs => {
  //                   const img_height = rs.currentTarget['height'];
  //                   const img_width = rs.currentTarget['width'];

  //                   console.log(img_height, img_width);


  //                   if (img_height > max_height && img_width > max_width) {
  //                       this.imageError =
  //                           'Maximum dimentions allowed ' +
  //                           max_height +
  //                           '*' +
  //                           max_width +
  //                           'px';
  //                       return false;
  //                   } else {
  //                       const imgBase64Path = e.target.result;
  //                       this.cardImageBase64 = imgBase64Path;
  //                       this.isImageSaved = true;
  //                       this.imageurl = imgBase64Path;
  //                   }
  //               };
  //           };

  //           reader.readAsDataURL(fileInput.target.files[0]);
  //           reader.onload = () => {

  //             this.imageurl = reader.result as string;

  //             this.productForm.patchValue({
  //               image_path: reader.result
  //             });

  //           };
  //       }
  // }


  readURL(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imageurl = reader.result;
      this.productForm.patchValue({
        image_path: reader.result
      });

    }
  }

  getProductDetails(productid){
    this.spinner.show();
    this.dataService.GetDetails('products/', productid).subscribe((data) => {
      this.spinner.hide();
      const anyData = data as any;
      console.log(JSON.stringify(anyData.product));

      this.itemList = anyData.product[0];
      this.getSelectedProductCategory(this.itemList.category_id);
      this.imageurl = 'https://w3weavers.com/twenty20/twenty20server/public/'+this.itemList.image_path+'?ver='+ this.itemList.image_version;
      this.productForm = this.formBuilder.group({
        product_name:[this.itemList.product_name, Validators.required],
        model_no:[this.itemList.model_no],
        hsn_code:[this.itemList.hsn_code, Validators.required],
        purchase_price:[this.itemList.purchase_price, Validators.required],
        sale_price:[this.itemList.sale_price, Validators.required],
        fixed_discount:[this.itemList.fixed_discount, Validators.required],
        bv:[this.itemList.bv, Validators.required],
        product_code:[this.itemList.product_code, Validators.required],
        image_path:['', Validators.required],
        description:[this.itemList.description, Validators.required],
        brand_id:[this.itemList.brand_id, Validators.required],
        category_id:[this.itemList.category_id, Validators.required],
        subcategory_id:[this.itemList.subcategory_id, Validators.required],
        gst_percent:[this.itemList.gst_percent, Validators.required],
      });
    }, error =>{
      this.spinner.hide();
      showDialog3();
    });
  }

  onSubmit() {
    // if (this.productForm.valid) {
      // alert('Success');
      // alert(JSON.stringify(this.operatorForm.value));
      console.log(this.productForm.value);
      this.spinner.show();
        this.dataService.Edit('products/', JSON.stringify(this.productForm.value),localStorage.getItem('activeid')).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
              if(anyData.status === 200){
                this.getProductDetails(localStorage.getItem('activeid'));
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Product Updated Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
          
        }, error => {
          this.spinner.hide();
          showDialog3();
        });
    // } else{
    //   showDialog11();
    // }
  }

}
