import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, NgForm, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-purchaseorderadd',
  templateUrl: './purchaseorderadd.component.html',
  styleUrls: ['./purchaseorderadd.component.scss']
})

export class PurchaseorderaddComponent implements OnInit {

  productForm: FormGroup;
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  itemsList: any;
  gstList: any;
  // myDate: any;

  // invoice_no: any;
  // purchase_date: any;
  // total_amount: any;
  // supplier_id: any;
  invoice_no = new FormControl('');
  purchase_date = new FormControl('');
  total_amount = new FormControl('');
  total_sum : number = 0;
  total_sum1 : number = 0;
  supplier_id = new FormControl('');

  
  supplierList: any;
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.purchase_date.setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    // this.purchase_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.productForm = this.fb.group({
      // invoice_no: '',
      // purchase_date: '',
      // total_amount: '',
      // supplier_id: '',
      products: this.fb.array([])
    });
    this.supplierList = [];
    this.gstList = [];
    this.itemsList = [];
    this.getSuppliersList();
    this.getProductsList();
    this.getGSTList();
   }

  ngOnInit(): void {
  }

  getSuppliersList(){
    this.dataService.GetList('phorder/get-suppliers-for-stock-entry').subscribe((data) => {
      const anyData = data as any;
      this.supplierList = anyData.suppliers;
    });
    // alert(id);
  }

  getGSTList(){
    this.dataService.GetList('operators/get-gst-types').subscribe((data) => {
      const anyData = data as any;
      this.gstList = anyData.taxes;
    });
  }

  products() : FormArray {
    // return this.productForm.get("quantities") as FormArray
    return this.productForm.get("products") as FormArray
  }

  addItem() {
    this.products().push(this.dataService.newProduct());
  }

  sumTotal(){
    this.total_sum = 0.00;
    this.total_sum1 = 0.00;
    for(let i=0; i<this.products().value.length; i++){
      let gstamount : number = 0;
      if(this.products().value[i].gst_percent === "1"){
        gstamount = (Number(this.products().value[i].unit_price)*(5/100));
      }else if(this.products().value[i].gst_percent === "2"){
        gstamount = (Number(this.products().value[i].unit_price)*(12/100));
      }else if(this.products().value[i].gst_percent === "3"){
        gstamount = (Number(this.products().value[i].unit_price)*(18/100));
      }else if(this.products().value[i].gst_percent === "4"){
        gstamount = (Number(this.products().value[i].unit_price)*(28/100));
      }else if(this.products().value[i].gst_percent === "5"){
        gstamount = (Number(this.products().value[i].unit_price));
      }
      // console.log(this.total_sum);
      // console.log(gstamount);
      //console.log(((Number(this.products().value[i].unit_price)+gstamount)*Number(this.products().value[i].quantity)).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + "==" + Number(((Number(this.products().value[i].unit_price)+gstamount)*Number(this.products().value[i].quantity))).toFixed(2));
      this.total_sum1 = Number(this.total_sum1 + (Number(this.products().value[i].unit_price)+gstamount)*Number(this.products().value[i].quantity));
      // this.total_sum = Number((this.total_sum + ((Number(this.products().value[i].unit_price)+gstamount)*Number(this.products().value[i].quantity))).toString());
      // this.total_sum = this.total_sum + ((Number(this.products().value[i].unit_price)+gstamount)*Number(this.products().value[i].quantity));
      // this.total_sum = Number(this.total_sum.toFixed(2)) + Number((Number((Number(this.products().value[i].unit_price)+gstamount).toFixed(2))*Number(this.products().value[i].quantity)).toFixed(2));
    }

    this.total_sum = Math.floor(this.total_sum1*100)/100;
    
   
    // console.log(this.products().value[i]);
    
    console.log((Math.floor(this.total_sum1*100)/100) + "====" + this.total_sum1);

    // console.log(0.1+0.2);

  }

  removeItem(i:number) {

    this.products().removeAt(i);
  }

  getProductsList(){
    this.spinner.show();
    this.dataService.GetList('products').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.itemsList = anyData.products;
      
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
    }, error => {

      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Network Error, Please Try Again.'
      });
    });
  }

  onSubmit() {
    const datatosend = {
      "purchase_header":{
        "invoice_no": this.invoice_no.value,
        "purchase_date": this.purchase_date.value,
        "total_amount": this.total_amount.value,
        "supplier_id": this.supplier_id.value,
      },
      "purchase_list": this.productForm.value.products
    }
    console.log(this.productForm.value.products);

    // console.log(this.productForm.value);
      this.spinner.show();
        this.dataService.Create('purchaseorders', datatosend).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
              if(anyData.status === 200){
                this.productForm.reset();
                this.invoice_no.setValue('');
                this.total_amount.setValue('');
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Product Added Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
        }, error => {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Network Error, Please Try Again.'
          });
        });
  }

}
