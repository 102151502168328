import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-stockreturndetail',
  templateUrl: './stockreturndetail.component.html',
  styleUrls: ['./stockreturndetail.component.scss']
})
export class StockreturndetailComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  itemsList: any;
  newItemsList: any;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    
    this.itemsList = [];
    this.newItemsList = [];
    this.getDetails(localStorage.getItem('activeid'));
  }

  ngOnInit(): void {
  }

  
  getDetails(id){
    this.spinner.show();
    this.dataService.GetDetails('stock/get-stock-return-detail-by-id/', id).subscribe((data) => {
      this.spinner.hide();
      const anyData = data as any;
      // console.log(JSON.stringify(anyData.user));

      this.itemsList = anyData.stockReturnDetail.stockreturndetail;
      for(let i=0; i<this.itemsList.length; i++){
        const listItems = {
          "product_name" : this.itemsList[i].stock.phorder.product.product_name,
          "hsn_code" : this.itemsList[i].stock.phorder.product.hsn_code,
          "qty" : this.itemsList[i].quantity,
          "unit_price" : this.itemsList[i].stock.phorder.unit_price,
          "tax_percent" : this.itemsList[i].stock.phorder.tax.tax_percent,
          "amount" : (Math.floor(((Number(this.itemsList[i].stock.phorder.unit_price)+(Number(this.itemsList[i].stock.phorder.unit_price)*(Number(this.itemsList[i].stock.phorder.tax.tax_percent)/100)))*Number(this.itemsList[i].quantity))*100)/100)
        }
        this.newItemsList.push(listItems);
      }
      
     
    }, error =>{
      this.spinner.hide();
      // showDialog3();
    });
  }

}
