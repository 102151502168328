
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Edit Items</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Billing &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active">Edit Items</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<div class="card card-box">
								<div class="card-body" id="bar-parent">
                                        <table style="width: 100%;" class="searchtable">
                                            <tr><th colspan="3" style="height: 45px; text-decoration: underline; background: #ffd6c5; padding: 0px 10px; color: #c20000;"><strong>Rejection Description: </strong>{{rejection_description}}</th></tr>
                                            <tr>
                                                <td>Category: <select class="form-control"  id="category_id" name="category_id" (change)="getSelectedProductCategory($event.target.value)">
                                                    <option value="">Select...</option>
                                                    <option *ngFor="let c of categoryList" [value]="c.id">{{c.category_name}}</option>
                                                  </select></td>
                                                <td>Subcategory: <select class="form-control"  id="subcategory_id" name="subcategory_id" (change)="getSelectedProductSubcategory($event.target.value)">
                                                    <option value="">Select...</option>
                                                    <option *ngFor="let c of subcategoryList" [value]="c.id">{{c.sub_category_name}}</option>
                                                  </select></td>
                                                <td>Product Name / Code: <input type="text"  name="product_name" list="product_name" data-required="1"  (change)="getSelectedProduct($event.target.value)"  class="form-control" />
                                                <datalist id="product_name" >
                                                    <option *ngFor="let p of productList" [value]="p.name"></option>
                                                </datalist>
                                                </td>
                                                <!-- <td><button type="button" style="margin-top: 23px;" (click)="searchProduct()" class="btn btn-info m-r-20">Search</button></td> -->
                                            </tr>
                                            <tr>
                                                <td colspan="4"><input type="text" list="productKeywordSearch" placeholder="Search by Keywords" style="margin-top: 30px; border: solid thin #dedede; border-radius: 15px;" class="form-control" name="product_keyword" (change)="getSelectedProductByKeyword($event.target.value)">
                                                <datalist id="productKeywordSearch">
                                                    <option *ngFor="let p of selectedKeywordList" [value]="p.name"></option>
                                                </datalist>
                                                </td>
                                            </tr>
                                        </table>
                                        <table style="width: 100%; margin-top: 30px;" class="table">
                                            <tr style="background: #dfdfdf;">
                                                <th>Product Code</th>
                                                <th>HSN</th>
                                                <th>Selling Price</th>
                                                <th>Available Quantity</th>
                                                <th>Enter Quantity</th>
                                                <th>Action</th>
                                            </tr>
                                            <tr *ngFor="let item of productsList; let i=index">
                                                <td>{{item.product_code}}</td>
                                                <td>{{item.hsn_code}}</td>
                                                <td>{{item.mrp | number : '1.2-2'}}</td>
                                                <td>{{item.available_quantity}}</td>
                                                <td><input type="text" [(ngModel)]="itemqty[i]"   class="form-control" /></td>
                                                <td><input type="button" value="Add" (click)="addItems(i)" class="btn btn-success" /></td>
                                            </tr>
                                        </table>
                                        
                                        <table style="width: 100%; margin-top: 50px;" class="table">
                                            <tr style="background: #005d92; color: #dfdfdf;">
                                                <th>#</th>
                                                <th>Product Name</th>
                                                <th>Unit Price</th>
                                                <th>Qty</th>
                                                <th>Net Amount</th>
                                                <th>Action</th>
                                            </tr>
                                            <tr  *ngFor="let item of itemsList; let i=index">                                                
                                                <td><label for="" >{{i+1}}</label></td>
                                                <td><label for="" >{{item.product_name}}</label></td>
                                                <td><label for="" >{{item.unit_price | number : '1.2-2'}}</label></td>
                                                <td><label for="" >{{item.qty}}</label></td>
                                                <td><label for="" >{{item.total_amount | number : '1.2-2'}}</label></td>
                                                <td><button (click)="removeItem(i)" class="btn btn-danger"><i class="fa fa-trash-o"></i></button></td>
                                            </tr>
                                        </table>
                                        <table style="width: 100%; margin-top: 50px; background: #ffff94; font-weight: bold;" class="table">
                                            <tr>
                                                <td>Total Amount: <span style="color: #c20000;"> {{total_net_price | number : '1.2-2'}}</span></td>
                                                <td>Select Branch :</td><td> 
                                                    <select name="" id="" class="form-control" [(ngModel)]="invoice_branch_id" (change)="getBranchDetails()">
                                                        <!-- <option value="" [selected]="isSelected">Select</option> -->
                                                        <option *ngFor="let branch of new_branchList; let i=index" value="{{branch.id}}">{{branch.branch_name}}</option>
                                                    </select>
                                                </td>
                                                <!-- <td> <input class="btn btn-danger" type="button" (click)="searchUserByMobile()" value="Search"></td> -->
                                            </tr>
                                        </table>
                                        <!-- <table *ngIf="is_user_searched" style="width: 100%; margin-top: 50px;" class="table">
                                            <tr>
                                                <td rowspan="5">
                                                    <strong>Name:</strong> {{invoice_name}}<br>
                                                   <strong> Mobile:</strong> {{invoice_mobile}}<br>
                                                    <strong>Email:</strong> {{invoice_email}}<br>
                                                    <strong>Address:</strong> <span [innerHTML]="invoice_address"></span><br>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Usable BV Amount : <strong>{{invoice_usable_bv_amount}}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    BV amount to be used : <input type="text" [(ngModel)]="user_used_bv_amount" (keyup)="setUserCashAmount()" class="form-control"> <input type="button" class="btn btn-primary" value="Verify">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Enter Cash Amount: <input type="text" [(ngModel)]="user_cash_amount"  class="form-control">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <input type="button" class="btn btn-success" (click)="showPreview()" value="preview">
                                                </td>
                                            </tr>
                                        </table> -->
                                   
                  <br/>
                
                  <div id="printableArea" class=WordSection1 >
                    
                    <table class="invoice_preview_table"  style="border: solid thin #000000; border-collapse: collapse; font-size: 14px; padding: 5px;">
                        <tr><td colspan="4" style="border: none;"></td><td colspan="6"  style="border: none;"><strong>Tax Invoice</strong></td><td colspan="6"  style="border: none; font-size: 16px;"><img class="col-md-6" src="/assets/images/logo4.png" alt=""></td></tr>
                     <tr>
                        <td width=281 colspan=3 rowspan=3 valign=top style="border: solid thin #000000; padding: 5px;">
                            <p><span><strong>ANSHU ENTREPRISES(GAYA)</strong><br>INFRONT OF AGGARWAL PETROL PUMP<br>PATNA RANCHI ROAD, NAWADA<br>Contact: 9835856927<br> GSTIN NO.: 10HCCPK9197M1Z2<br>STATE: Bihar  </span></p>
                        </td>
                        <td width=198 colspan=8 valign=top style="border: solid thin #000000; padding: 5px;">
                            <p><span>Invoice No.:   {{user_invoice_no}}</span></p>
                        </td>
                        <td width=227 colspan=5 valign=top style="border: solid thin #000000; padding: 5px;">
                            <p><span>Date:   {{invoice_date | date: 'dd-MM-yyyy'}}</span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=198 colspan=8 valign=top style="border: solid thin #000000; padding: 5px;">
                        <p><span>Delivery Note:   </span></p>
                      </td>
                      <td width=227 colspan=5 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p><span>Mode/Terms of Payment   </span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=198 colspan=8 valign=top style="border: solid thin #000000; padding: 5px;">
                        <p><span>Supplier’s Ref:   </span></p>
                      </td>
                      <td width=227 colspan=5 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p><span>Other Reference(s)   </span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=281 colspan=3 rowspan=4 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p><span> <strong>Name:</strong> {{invoice_name}}<br>
                        <strong> Mobile:</strong> {{invoice_mobile}}<br>
                         <strong>Email:</strong> {{invoice_email}}<br>
                         <strong>Address:</strong> <span [innerHTML]="invoice_address"></span><br> </span></p>
                      </td>
                      <td width=198 colspan=8 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p><span>Buyer’s Order No.:   </span></p>
                      </td>
                      <td width=227 colspan=5 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p><span>Date:   {{invoice_date | date: 'dd-MM-yyyy'}}</span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=198 colspan=8 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p><span>Dispatch Document No.:   </span></p>
                      </td>
                      <td width=227 colspan=5 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p><span>Delivery Note Date:   {{invoice_date | date: 'dd-MM-yyyy'}}</span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=198 colspan=8 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p><span>Dispatched Through:   </span></p>
                      </td>
                      <td width=227 colspan=5 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p><span>Destination:   </span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=425 colspan=13 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p><span>Terms of Delivery:   </span></p>
                      </td>
                     </tr>
                     <tr>
                         <td colspan="16">
                            <div style="width: 100%; min-height: 380px; border: solid thin #000000;">
                                <table with="100%" style="min-height: 380px;" class="items-table">
                                    <tr>
                                        <th width=30 valign=top height="40" style="border-right: solid thin #000000; border-bottom: solid thin #000000;">
                                        <p style="padding: 0px 5px;"><span  style="font-weight: bold;">Sl</span></p>
                                        </th>
                                        <th width=340 valign=top style="border-right: solid thin #000000; border-bottom: solid thin #000000;">
                                        <p style="padding: 0px 5px;"><span style="font-weight: bold;">Description   </span></p>
                                        </th>
                                        <th width=60 valign=top  style="border-right: solid thin #000000; text-align: center; border-bottom: solid thin #000000;">
                                        <p style="padding: 0px 5px;"><span style="font-weight: bold;">HSN/SAC   </span></p>
                                        </th>
                                        <th width=66 valign=top style="border-right: solid thin #000000; text-align: center; border-bottom: solid thin #000000;">
                                        <p style="padding: 0px 5px;"><span style="font-weight: bold;">Rate   </span></p>
                                        </th>
                                        <th width=60 valign=top  style="border-right: solid thin #000000; text-align: center; border-bottom: solid thin #000000;">
                                        <p style="padding: 0px 5px;"><span style="font-weight: bold;">GST%   </span></p>
                                        </th>
                                        <th width=60 valign=top  style="border-right: solid thin #000000; text-align: center; border-bottom: solid thin #000000;">
                                        <p style="padding: 0px 5px;"><span style="font-weight: bold;">GST   </span></p>
                                        </th>
                                        <th width=40 valign=top style="border-right: solid thin #000000; text-align: center; border-bottom: solid thin #000000;">
                                        <p style="padding: 0px 5px;"><span style="font-weight: bold;">Quantity   </span></p>
                                        </th>
                                        <th width=60 valign=top style="border-right: solid thin #000000; text-align: center; border-bottom: solid thin #000000;">
                                        <p style="padding: 0px 5px;"><span style="font-weight: bold;">Amount   </span></p>
                                        </th>
                                    </tr>
                                    <tr  *ngFor="let item of itemsList; let i=index">
                                        <td valign=top style="border-right: solid thin #000000;">
                                        <p style="padding: 0px 5px;"><span> {{i+1}}  </span></p>
                                        </td>
                                        <td valign=top style="border-right: solid thin #000000;">
                                        <p style="padding: 0px 5px;"><span > {{item.product_name}}  </span></p>
                                        </td>
                                        <td valign=top style="border-right: solid thin #000000; text-align: center;">
                                        <p style="padding: 0px 5px;"><span > {{item.hsn_code}}  </span></p>
                                        </td>
                                        <td valign=top style="border-right: solid thin #000000; text-align: center;">
                                        <p style="padding: 0px 5px;"><span > {{item.unit_price | number : '1.2-2'}}  </span></p>
                                        </td>
                                        <td valign=top style="border-right: solid thin #000000; text-align: center;">
                                        <p style="padding: 0px 5px;"><span > {{item.gst}}  </span></p>
                                        </td>
                                        <td valign=top style="border-right: solid thin #000000; text-align: center;">
                                        <p style="padding: 0px 5px;"><span > {{item.gst_amount | number : '1.2-2'}}  </span></p>
                                        </td>
                                        <td valign=top style="border-right: solid thin #000000; text-align: center;">
                                        <p style="padding: 0px 5px;"><span > {{item.qty}}  </span></p>
                                        </td>
                                        <td width=104 valign=top style="border-right: solid thin #000000; text-align: center;">
                                        <p style="padding: 0px 5px;"><span>{{item.net_amount | number : '1.2-2'}}   </span></p>
                                        </td>
                                    </tr>
                                 </table>
                             </div>
                         </td>
                     </tr>
                     <tr>
                        <td width=707 colspan=16  style="border: solid thin #000000; padding: 5px;">
                            <p><span></span><span style="float: right;">Amount Sum Total : {{total_net_price | number : '1.2-2'}}   </span></p>
                          </td>
                     </tr>
                     <tr>
                      <td width=707 colspan=16 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p style="text-align: right;"><span > ON AMOUNT: {{total_selling_price | number : '1.2-2'}} <br>
                                    CGST: {{total_cgst | number : '1.2-2'}} <br>
                                    SGST: {{total_sgst | number : '1.2-2'}} <br>
                                    TOTAL AFTER ROUND OFF: {{total_net_price| number : '1.2-2'}}
                    </span></p>
                      </td>
                     </tr>
                     <tr>
                      <td width=357 colspan=6 valign=top style="border: solid thin #000000; padding: 5px;">
                      <p><span >Customer’s Signature   </span></p>
                      </td>
                      <td width=350 colspan=10 valign=top height="100" style="border: solid thin #000000; padding: 5px;">
                      <p><span > ANSHU ENTREPRISES<br>Seal and Signature  </span></p>
                      </td>
                     </tr>
                     <!-- <tr height=0>
                      <td width=52 style='border:none'></td>
                      <td width=182 style='border:none'></td>
                      <td width=47 style='border:none'></td>
                      <td width=21 style='border:none'></td>
                      <td width=8 style='border:none'></td>
                      <td width=47 style='border:none'></td>
                      <td width=19 style='border:none'></td>
                      <td width=9 style='border:none'></td>
                      <td width=57 style='border:none'></td>
                      <td width=19 style='border:none'></td>
                      <td width=19 style='border:none'></td>
                      <td width=19 style='border:none'></td>
                      <td width=28 style='border:none'></td>
                      <td width=47 style='border:none'></td>
                      <td width=28 style='border:none'></td>
                      <td width=104 style='border:none'></td>
                     </tr> -->
                    </table>
                    <p><span>This is a Computer Generated Invoice</span></p>
                    
                    </div>

                  <!-- {{itemsList | json}} -->
                  <input type="button" *ngIf="is_submit" class="btn btn-danger" value="Submit" (click)="onSubmit()">&nbsp;
                  <input type="button" *ngIf="is_print" (click)="printDiv('printableArea')" value="Print" class="btn btn-primary" />
                

								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>
</body>

