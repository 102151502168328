
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Edit Product</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Products &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active">Edit Product</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<div class="card card-box">
								<div class="card-body" id="bar-parent">
									<form id="form_sample_1" class="form-horizontal" [formGroup]="productForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
										<input type="hidden" name="_method" value="PUT">
										<div class="form-body">
											<div class="form-group row">
												<label class="control-label col-md-3">Product Category
													<span class="required"> * </span>
												</label>
												<div class="col-md-5">
													<select class="form-control" formControlName="category_id" id="category_id" name="category_id" (change)="getSelectedProductCategory($event.target.value)">
														<option value="">Select...</option>
														<option *ngFor="let c of categoryList" [value]="c.id">{{c.category_name}}</option>
													  </select> </div>
											</div>
											<div class="form-group row">
												<label class="control-label col-md-3">Product Subcategory
													<span class="required"> * </span>
												</label>
												<div class="col-md-5">
													<select class="form-control" formControlName="subcategory_id" id="subcategory_id" name="subcategory_id" (change)="getSelectedProductSubcategory($event.target.value)">
														<option value="">Select...</option>
														<option *ngFor="let c of subcategoryList" [value]="c.id">{{c.sub_category_name}}</option>
													  </select> </div>
											</div>
											<div class="form-group row">
												<label class="control-label col-md-3">Brand
													<span class="required"> * </span>
												</label>
												<div class="col-md-5">
													<select class="form-control" formControlName="brand_id" id="brand_id" name="brand_id">
													<option value="">Select...</option>
													<option *ngFor="let c of brandList" [value]="c.id">{{c.brand_name}}</option>
												</select> </div>
											</div>
											
											<div class="form-group row">
												<label class="control-label col-md-3">Product Name
													<span class="required"> * </span>
												</label>
												<div class="col-md-5">
													<input type="text" id="product_name" name="product_name" formControlName="product_name" data-required="1"
														placeholder="Product Name"
														class="form-control input-height" />
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-md-3">Model No.
												</label>
												<div class="col-md-5">
													<input type="text" id="model_no" name="model_no" formControlName="model_no" data-required="1"
														placeholder="Model Number"
														class="form-control input-height" />
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-md-3">HSN/SAC Code
													<span class="required"> * </span>
												</label>
												<div class="col-md-5">
													<input type="text" class="form-control input-height" id="hsn_code" name="hsn_code" formControlName="hsn_code" />
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-md-3">Product Code
													<span class="required"> * </span>
												</label>
												<div class="col-md-5">
													<input type="text" id="product_code" name="product_code" formControlName="product_code" placeholder="Product Code" class="form-control input-height" /> </div>
											</div>
											<div class="form-group row">
												<label class="control-label col-md-3">Image
													<span class="required"> * </span>
												</label>
												<div class="col-md-5">
                          <input type="file" accept='image/*' #file (change)="readURL(file.files);" id="image_path" name="image_path" formControlName="image_path" placeholder="Image" class="form-control input-height" />
                          {{message}}
                          <img [src]="imageurl" alt="">
                        </div>
											</div>
											
											<div class="form-group row">
												<label class="control-label col-md-3">Description
													<span class="required"> * </span>
												</label>
												<div class="col-md-5">
													<input type="text" id="description" name="description" formControlName="description" placeholder="Description" class="form-control input-height" /> </div>
											</div>
											
											<div class="form-actions">
												<div class="row">
													<div class="offset-md-3 col-md-9">
														<button type="submit" class="btn btn-info m-r-20">Submit</button>
														<button type="button" class="btn btn-default">Cancel</button>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>
</body>

