
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Edit Purchase Order Products</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Purchase Order Products &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active">Edit Purchase Order Products</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<div class="card card-box">
								<div class="card-body" id="bar-parent">
									<form id="form_sample_1" class="form-horizontal" [formGroup]="productForm" (ngSubmit)="onSubmit()">

										<!-- <p>
										  <label for="name">Product Name:</label>
										  <input type="text" id="name" name="name" formControlName="name" class="form-control">
										</p> -->
					
										<table class="table table-bordered" formArrayName="products">
                                            <tr>
                                                <td colspan="7"><input type="text" list="productKeywordSearch" placeholder="Search by Keywords" style="margin-top: 30px; border: solid thin #dedede; border-radius: 15px;" class="form-control" name="product_keyword" (change)="getSelectedProductByKeyword($event.target.value)">
                                                <datalist id="productKeywordSearch">
                                                    <option *ngFor="let p of selectedKeywordList" [value]="p.name"></option>
                                                </datalist>
                                                </td>
                                            </tr>
											<tr>
												<th colspan="3">Invoice No.</th>
												<th colspan="2">Invoice Date</th>
												<!-- <th colspan="2">Quantity</th> -->
												<th colspan="2">Action</th>
											</tr>
											<tr *ngFor="let item of headerList; let i=index">
												<td colspan="3">{{item.phheader.invoice_no}}</td>
												<td colspan="2">{{item.phheader.purchase_date | date:'dd-MM-yyyy'}}</td>
												<!-- <td colspan="2">{{item.quantity}}</td> -->
												<td colspan="2"><button type="button" *ngIf="item.stock" (click)="editItem(i)" class="btn btn-primary">Edit Item</button></td>
												<!-- <td><button type="button" *ngIf="!item.stock" disabled (click)="editItem(i)" class="btn btn-primary">Edit Item</button></td> -->
											</tr>
										  <tr style="background: #efefef;">
											<th >Invoice No.: <input type="text" [formControl]="invoice_no" class="form-control"></th>
											<th colspan="2">Invoice Date: <input type="date" [formControl]="purchase_date" class="form-control"></th>
											<th colspan="2">Total Amount: <input type="text" readonly [formControl]="total_amount" class="form-control"></th>
											<th colspan="2">Supplier: <select class="form-control" [formControl]="supplier_id">
												<option value="">Select...</option>
												<option *ngFor="let c of supplierList" [value]="c.id">{{c.s_name}}</option>
											  </select></th>
											<!-- <th><button type="button" (click)="addItem()" class="btn btn-primary">Add Item</button></th> -->
										  </tr>
										  <tr *ngFor="let item of products().controls; let i=index" [formGroupName]="i">
											<td style="width:  20%;">
												<input type="hidden" formControlName="id">
												Product Name :
												<select class="form-control" formControlName="product_id" disabled>
													<option value="">Select...</option>
													<option *ngFor="let item of itemsList" [value]="item.id">{{item.product_name}}</option>
												  </select>
											</td>
											<td style="width:  8%;">
												GST%:
												<select disabled name="" formControlName="gst_percent" id="" class="form-control">
													<option value="">Select GST</option>
													<option *ngFor="let gst of gstList" [value]="gst.id">{{gst.tax_percent}}</option>
												</select>
											</td>
											<td style="width:  15%;">
												<input type="hidden" formCotrolName="price_id">
												Purchase Price:
												<input type="text" readonly formControlName="unit_price" class="form-control">
											</td>
											<td style="width:  8%;">
												Quantity:
												<input type="number" formControlName="quantity" readonly class="form-control">
											</td>
											<td style="width:  11%;">
												Sale Price:
												<input type="text" formControlName="sale_price" class="form-control">
											</td>
											<td style="width:  15%;">
												Fixed Discount:
												<input type="text" formControlName="fixed_discount" class="form-control">
											</td>
											<td style="width:  8%;">
												BV:
												<input type="text" formControlName="bv" class="form-control">
											</td>
										  </tr>
										</table>
					
										<button type="submit" class="btn btn-success">Submit</button>
					
									  </form>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>
</body>

