import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

declare function showDialog3(): any;
@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;

  dtOptions: DataTables.Settings = {};
  // persons: Person[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  public loading = false;

  itemsList: any;
  addrList: any;
  operatorid: string;
  sub: any;

  constructor(
    public dataService: DataService,
    private envService: EnvService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute

  ) {


   }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    // this.dtTrigger.next();
    this.itemsList = [];
    this.addrList = [];
    this.getProductBrandsList();
    // this.sub = this.route.params.subscribe(params => {
    //   sessionStorage.setItem('activeMenuId', params['id']);
    // });   
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    // this.sub.unsubscribe();
  }
  ngAfterViewInit(): void{
    // this.dtTrigger.next();
  }


  getProductBrandsList(){
    this.spinner.show();
    this.dataService.GetList('brands').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.itemsList = anyData.brands;
        console.log(JSON.stringify(this.addrList));
        this.dtTrigger.next();
        this.dtTrigger.unsubscribe();
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }      
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }


  editBrand(productBrandid){
    localStorage.setItem('activeid', productBrandid);
    let element:HTMLElement = document.getElementById('auto_trigger') as HTMLElement;

    element.click();
    // this.router.navigate(['brand/update']);
    // let navigationExtras: NavigationExtras = {
    //   state: {
    //     operatorid: operatorid
    //   }
    // };
    // this.router.navigate(['operator/update'], navigationExtras);
  }


  deleteBrand(id, status){
    let new_status;
    if(status === 1){
      new_status = 0;
    }else{
      new_status = 1;
    }
    const datatosend = {
      "brand_id" : id,
      "status" : new_status
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are going to change status!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!',
    }).then((result) => {
      // alert(result.value);
      if (result.value) {
        this.spinner.show();
        this.dataService.Create('operators/brand/update-status', datatosend).subscribe((data) => {
          this.spinner.hide();
          // showDialog6();
          this.getProductBrandsList();
        }, error => {
          this.spinner.hide();
          showDialog3();
        });

      }
    });
  }

}
