
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Stock Transfer Report</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Report &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Stock Transfer Report</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="tabbable-line">
								<!-- <ul class="nav nav-tabs">
                                    <li class="active">
                                        <a href="#tab1" data-toggle="tab"> List View </a>
                                    </li>
                                    <li>
                                        <a href="#tab2" data-toggle="tab"> Grid View </a>
                                    </li>
                                </ul> -->
								<ul class="nav customtab nav-tabs" role="tablist">
									<!-- <li class="nav-item"><a href="#tab1" class="nav-link active" data-toggle="tab">List
											View</a></li> -->
									<!-- <li class="nav-item"><a href="#tab2" class="nav-link" data-toggle="tab">Grid
											View</a></li> -->
								</ul>
								<div class="tab-content">
									<div class="tab-pane active fontawesome-demo" id="tab1">
										<div class="row">
											<div class="col-md-12">
												<div class="card card-box">
													<div class="card-head">
														<header>All Records</header>
														<div class="tools">
															<a class="fa fa-repeat btn-color box-refresh"
																href="javascript:;"></a>
															<a class="t-collapse btn-color fa fa-chevron-down"
																href="javascript:;"></a>
															<a class="t-close btn-color fa fa-times"
																href="javascript:;"></a>
														</div>
													</div>
													<div class="card-body ">
														<div class="row">
															<div class="col-md-9 col-sm-9 col-lg-9 col-sx-9 col-9">
																<div class="btn-group">
																	<table class="col-md-12 col-sm-12 col-12">
																		<tr>
																			<td class="col-md-3 col-sm-3 col-3">From Date:<input class="form-control" type="date" [(ngModel)]="fromDate"></td>
																			<td class="col-md-3 col-sm-3 col-3">To Date:<input class="form-control" type="date" [(ngModel)]="toDate"></td>
																			<td class="col-md-3 col-sm-3 col-3"><button class="btn btn-primary" (click)="getItemsList()">Search</button></td></tr>
																	</table>
																</div>
															</div>
															<div class="col-md-3 col-sm-3 col-lg-3 col-sx-3 col-3">
																<div class="btn-group pull-right">
																	<a class="btn deepPink-bgcolor  btn-outline dropdown-toggle"
																		data-toggle="dropdown">Tools
																		<i class="fa fa-angle-down"></i>
																	</a>
																	<ul class="dropdown-menu pull-right">
																		<!-- <li>
																			<a href="javascript:;">
																				<i class="fa fa-print"></i> Print </a>
																		</li>
																		<li>
																			<a href="javascript:;">
																				<i class="fa fa-file-pdf-o"></i> Save as
																				PDF </a>
																		</li> -->
																		<li>
																			<a href="javascript:;" (click)="exportToExcel()">
																				<i class="fa fa-file-excel-o"></i>
																				Export to Excel </a>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div class="table-scrollable">
															<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" 	class="table table-striped table-bordered table-hover table-checkable order-column valign-middle btn-sweetalert"  *ngIf="user_type == 'mlm'">
																<thead>
																	<tr>
																		<th>#</th>
																		<th> Branch Name </th>
																		<th> Invoice No. </th>
																		<th> Product Name </th>
																		<th> Transfer Date </th>
																		<th> Quantity </th>
																		<th> Unit Price </th>
																	</tr>
																</thead>
																<tbody>
																	<tr class="odd gradeX" *ngFor="let item of newitemsList; let i = index">
																		<td class="left">
																			{{i+1}}
																		</td>
                                                                        <td class="left"> {{ item.branch_name}}</td>
																		<td class="left">{{item.invoice_no}}</td>
                                    									<td class="left"> {{ item.product_name}}</td>
																		<td class="left">{{item.purchase_date | date: 'dd-MM-yyyy'}}</td>
																		<td class="left">{{item.quantity}}</td>
                                    									<td class="left"> {{ item.unit_price}}</td>																		
																	</tr>
																</tbody>
															</table>
															
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>

</body>

