<div class="limiter">
    <div class="container-login100 page-background">
        <div class="wrap-login100">
            <form class="login100-form validate-form" #passwordResetForm="ngForm" (ngSubmit)="onReset(passwordResetForm)" method="post">
                <span class="logo">
                    <img alt="" src="../../../assets/images/logo4.png">
                </span>
                <span class="login100-form-title p-b-34 p-t-27">
                    Reset Password
                </span>
                <div class="wrap-input100 validate-input" data-validate="Enter username">
                    <input class="input100" type="password" [formControl]="new_password"  placeholder="New Password">
                    <span class="focus-input100" data-placeholder="&#xf207;"></span>
                </div>
                <div class="wrap-input100 validate-input" data-validate="Enter password">
                    <input class="input100" type="password" [formControl]="confirm_password" placeholder="Confirm Password">
                    <span class="focus-input100" data-placeholder="&#xf191;"></span>
                </div>
                <div class="container-login100-form-btn">
                    <button class="login100-form-btn" type="submit">
                        Reset Password
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

