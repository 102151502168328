import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

declare function showDialog6(): any;
declare function showDialog3(): any;
declare function showDialog11(): any;
@Component({
  selector: 'app-productsubcategoryedit',
  templateUrl: './productsubcategoryedit.component.html',
  styleUrls: ['./productsubcategoryedit.component.scss']
})
export class ProductsubcategoryeditComponent implements OnInit {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  productSubcategoryForm = new FormGroup({category_id: new FormControl(), sub_category_name: new FormControl()});


  itemList: any;
  categoryList: any;
  categoryName: string;



  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
    ) {
      this.itemList = [] ;
      this.categoryList = [];
    this.getDetails(localStorage.getItem('activeid'));
   }

  ngOnInit(){

  }
  getProductCategoriesList(){
    this.spinner.show();
    this.dataService.GetList('productcategories').subscribe((data) => {
      const anyData = data as any;
      this.categoryList = anyData.categories;
      // this.dtTrigger.next();
      this.spinner.hide();
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

  getDetails(id){
    this.spinner.show();
    this.getProductCategoriesList();
    this.dataService.GetDetails('productsubcategories/', id).subscribe((data) => {
      this.spinner.hide();
      const anyData = data as any;
      console.log(JSON.stringify(anyData.subcategory));

      this.itemList = anyData.subcategory;
      this.productSubcategoryForm = this.formBuilder.group({
        category_id: [this.itemList.category_id],
        sub_category_name:[this.itemList.sub_category_name, Validators.required]
      });
    }, error =>{
      this.spinner.hide();
      showDialog3();
    });
  }

  onSubmit() {
    if (this.productSubcategoryForm.valid) {
      // alert('Success');
      // alert(JSON.stringify(this.productCategoryForm.value));
      console.log(this.productSubcategoryForm.value);
      this.spinner.show();
        this.dataService.Edit('productsubcategories/', JSON.stringify(this.productSubcategoryForm.value),localStorage.getItem('activeid')).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
              if(anyData.status === 200){
                
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Subcategory Updated Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
        }, error => {
          this.spinner.hide();
          showDialog3();
        });
    } else{
      showDialog11();
    }
  }

}

