import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-kycverification',
  templateUrl: './kycverification.component.html',
  styleUrls: ['./kycverification.component.scss']
})
export class KycverificationComponent implements OnInit {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  itemsList: any;

  imageURL = 'https://twenty20mvision.com/twenty20/twenty20server/public/';

  constructor(
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.getAddressDetails();
    this.itemsList = [];
   }

  ngOnInit(): void {
  }

  getAddressDetails(){
    this.spinner.show();
    this.dataService.GetDetails('operators/users/kyc/', sessionStorage.getItem('activeid')).subscribe((data) => {
      const anyData = data as any;
      this.itemsList = anyData.users[0];
      console.log(this.itemsList);
      this.spinner.hide();     
      
    }, error => {
      // showDialog3();
      this.spinner.hide();
    });
  }

  verifyUserKYC(){
    const datatosend = {
      "user_id" : sessionStorage.getItem('activeid')
    }
    // alert(JSON.stringify(datatosend));
    this.spinner.show();
    this.dataService.Create('operators/users/verify-kyc', datatosend).subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();  
      if(anyData.status === 200){
        this.router.navigate(['user']);
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }  
      
    }, error => {
      // showDialog3();
      this.spinner.hide();
    });
  }

}
