import { DataService } from './../../services/data.service';

import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { AnonymousSubject } from 'rxjs/internal/Subject';


declare function showDialog6(): any;
declare function showDialog3(): any;
declare function showDialog11(): any;
declare function price_in_words(invoice_amount): any;

@Component({
  selector: 'app-purchasereturnadd',
  templateUrl: './purchasereturnadd.component.html',
  styleUrls: ['./purchasereturnadd.component.scss']
})
export class PurchasereturnaddComponent {

  productsList: any;
  selectedProductList: any;
  selectedKeywordList: any;
  selectedProductId: any;
  selectedStocksList : any;
  price_in_word: any;
  today_date = new Date();
  operator_branch_id: any;
  new_branchList: any;
  // isSelected: boolean = true;

  user_used_bv_amount : any = 0;
  user_cash_amount: any;
  user_id: any;
  user_lft: any;
  user_rgt: any;
  user_level_at: any;
  user_parent_id: any;
  user_active: any;
  user_referal_code: any;
  user_invoice_no: any;
  



  is_preview: boolean = false;
  is_user_searched: boolean = false;
  is_submit: boolean = true;
  is_print: boolean = false;

  // itemsForm : FormGroup;
  itemsList: any;
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  branchList : any;
  branchDetailList: any;
  // name = 'Angular';
  categoryList: any;
  category_id: any;
  subcategory_id: any;
  subcategoryList: any;
  productList: any;
  brandList: any;
  supplierList: any;
  selectedItemList: any;
  mlm_user_mobile_no: any;

  invoice_name: string;
  invoice_mobile: string;
  invoice_email: string;
  invoice_address: string;
  invoice_user_detail: any;
  invoice_usable_bv_amount: any = 0;
  invoice_used_bv_amount: any;
  invoice_supplier_id: any;
  supplier_id: any;

  total_selling_price: number = 0;
  total_net_price: number = 0;
  total_bv: number = 0;
  billing_mobile_no: string;
  total_cgst: number = 0;
  total_sgst: number = 0;
  supplier_product_list : any;

  itemqty: any;
  
  imageurl: any;
  message: string;
  public imagePath;

  constructor(private fb:FormBuilder, private dataService: DataService, private spinner: NgxSpinnerService) {
    
    this.productsList = [];
    this.itemsList = [];
    this.branchDetailList = [];

    this.itemqty = [];
    this.selectedStocksList = [];
    this.supplier_product_list = [];
    this.operator_branch_id = sessionStorage.getItem('branch_id');

    this.categoryList = [];
    this.subcategoryList = [];
    this.brandList = [];
    this.supplierList = [];
    this.productList = [];
    this.new_branchList = []
    this.selectedProductList = [];
    this.selectedKeywordList = [];
    this.invoice_user_detail = [];
    this.getProductCategoriesList();
    this.getSuppliersList();
    this.getBrandsList();
    console.log(price_in_words('123456'));
    // this.addProduct();
  }

  getBrandsList(){
    this.dataService.GetList('brands').subscribe((data) => {
      const anyData = data as any;
      this.brandList = anyData.brands;
    });
    // alert(id);
  }

  searchProduct(){

  }



 

  getSelectedProductCategory(id: number){
    this.category_id = id;
    this.spinner.show();
    this.dataService.GetDetails('subcategories-by-category/', id).subscribe((data) => {
      const anyData = data as any;
      this.subcategoryList = anyData.subcategories;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
    // alert(id);
  }

  getSelectedProductSubcategory(id: number){
    this.subcategory_id = id;
    this.category_id = id;
    this.spinner.show();
    this.dataService.GetDetails('products-by-subcategory/', id).subscribe((data) => {
      const anyData = data as any;
      this.productList = anyData.products;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
    // alert(id);
  }

  getSelectedProduct(product_name){
    this.productsList = [];
    // alert(product_name);
    for(let i=0; i<this.productList.length; i++){
      if(this.productList[i].name === product_name){
        this.selectedProductId = this.productList[i].id;
        this.spinner.show()
        this.dataService.GetDetails('operators/get-available-product-by-product-id/', this.productList[i].id).subscribe((data) => {
          const anyData = data as any;
          this.selectedProductList = anyData.stocks;
          if(this.selectedProductList.length !== 0){
            for(let i = 0; i < this.selectedProductList.length; i++){
              const producttoshow = {
                "product_name" : this.selectedProductList[i].product_name,
                "product_id" : this.selectedProductList[i].product_id,
                "po_id" : this.selectedProductList[i].po_id,
                "stock_id" : this.selectedProductList[i].stock_id,
                "product_code" : this.selectedProductList[i].product_code,
                "hsn_code" : this.selectedProductList[i].hsn_code,
                "gst_percent" : this.selectedProductList[i].tax_percent,
                "mrp" : this.selectedProductList[i].unit_price,
                "discount" : this.selectedProductList[i].fixed_discount,
                "selling_price" : this.selectedProductList[i].sale_price,
                "bv" : this.selectedProductList[i].bv,
                "available_quantity" : this.selectedProductList[i].stock_in
              };
              this.productsList.push(producttoshow);
            }
          }else{
            Swal.fire({
              icon: 'warning',
              title: 'Alert',
              text: 'Out of Stock!'
            });
          }
          // this.productsList.push(producttoshow);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }

  getProductByKeyword(keyword){
        this.dataService.GetDetails('search-product-by-keyword/', keyword).subscribe((data) => {
          const anyData = data as any;
          this.selectedKeywordList = anyData.products;
        }, error => {
          // this.spinner.hide();
        });
  }

  searchUserByMobile(){
    this.dataService.GetDetails('get-mlm-user-by-mobile-no/', this.mlm_user_mobile_no).subscribe((data) => {
      const anyData = data as any;
      if(anyData.status === 200){
        this.invoice_user_detail = anyData.user;
        
        this.user_id = this.invoice_user_detail[0].id;
        this.user_lft = this.invoice_user_detail[0].lft;
        this.user_rgt = this.invoice_user_detail[0].rgt;
        this.user_level_at = this.invoice_user_detail[0].level_at;
        this.user_parent_id = this.invoice_user_detail[0].parent_id;
        this.user_active = this.invoice_user_detail[0].active;
        this.user_referal_code = this.invoice_user_detail[0].referal_code,
        this.invoice_name = this.invoice_user_detail[0].first_name + ' ' + this.invoice_user_detail[0].last_name;
        this.invoice_mobile = this.invoice_user_detail[0].mobile_no;
        this.invoice_email = this.invoice_user_detail[0].email;
        
        this.invoice_usable_bv_amount = this.invoice_user_detail.bv_amount;
        this.is_user_searched = true;
        this.user_cash_amount = this.total_selling_price;
        this.price_in_word = price_in_words(this.total_selling_price);

        if(this.invoice_user_detail[0].addresses.length > 0){
          this.invoice_address = this.invoice_user_detail[0].addresses[0].address1 + '<br>' + this.invoice_user_detail[0].addresses[0].address2 + '<br>' + this.invoice_user_detail[0].addresses[0].dist + '<br' + this.invoice_user_detail[0].addresses[0].state + '<br> PIN - ' + this.invoice_user_detail[0].addresses[0].pin_code;
        }else{
          this.invoice_address = '';
        }
      }else{

      }
    }, error => {
      // this.spinner.hide();
    });

  }

  getSelectedProductByKeyword(product_name){
    this.productsList = [];
    for(let i=0; i<this.selectedKeywordList.length; i++){
      if(this.selectedKeywordList[i].product_name === product_name){
        // this.selectedProductId = this.selectedKeywordList[i].id;
        this.spinner.show()
        this.dataService.GetDetails('operators/get-available-product-by-product-id/', this.selectedKeywordList[i].product_id).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
          this.selectedProductList = anyData.stocks;
          if(this.selectedProductList.length !== 0){
            for(let i = 0; i < this.selectedProductList.length; i++){
              const producttoshow = {
                "product_name" : this.selectedProductList[i].product_name,
                "product_id" : this.selectedProductList[i].product_id,
                "po_id" : this.selectedProductList[i].po_id,
                "stock_id" : this.selectedProductList[i].stock_id,
                "product_code" : this.selectedProductList[i].product_code,
                "hsn_code" : this.selectedProductList[i].hsn_code,
                "gst_percent" : this.selectedProductList[i].tax_percent,
                "mrp" : this.selectedProductList[i].unit_price,
                "discount" : this.selectedProductList[i].fixed_discount,
                "selling_price" : this.selectedProductList[i].sale_price,
                "bv" : this.selectedProductList[i].bv,
                "available_quantity" : this.selectedProductList[i].stock_in
              };
              this.productsList.push(producttoshow);
            }
          }else{
            Swal.fire({
              icon: 'warning',
              title: 'Alert',
              text: 'Out of Stock!'
            });
          }
          
          
         
        }, error => {
          this.spinner.hide();
        });
      }
    }
  }

  getProductCategoriesList(){
    this.spinner.show();
    this.dataService.GetList('productcategories').subscribe((data) => {
      const anyData = data as any;
      this.categoryList = anyData.categories;
      // this.dtTrigger.next();
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  addItems(i:number){
   
    const newarray = {
      "product_name" : this.productsList[i].product_name,
      "unit_price" : this.productsList[i].mrp,
      "hsn_code" : this.productsList[i].hsn_code,
      "qty" : this.itemqty[i],
      "gst" : this.productsList[i].gst_percent,
      // "gst_amount" : Math.floor((Number(this.productsList[i].mrp)*(Number(this.productsList[i].gst_percent)/100))*100)/100,
      "gst_amount" : (Number(this.productsList[i].mrp)*(Number(this.productsList[i].gst_percent)/100)),
      // "cgst" : Math.floor((((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*(Number(this.productsList[i].gst_percent)/2)/100))*100)/100,
      "cgst" : (((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*(Number(this.productsList[i].gst_percent)/2)/100)),
      // "sgst" : Math.floor((((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*(Number(this.productsList[i].gst_percent)/2)/100))*100)/100,
      "sgst" : (((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*(Number(this.productsList[i].gst_percent)/2)/100)),
      // "total_amount" : Math.floor((((Number(this.itemqty[i])*Number(this.productsList[i].mrp))+(((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*(Number(this.productsList[i].gst_percent)/2)/100)*2)))*100)/100,
      "total_amount" : (((Number(this.itemqty[i])*Number(this.productsList[i].mrp))+(((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*(Number(this.productsList[i].gst_percent)/2)/100)*2))),
      "discount" : this.productsList[i].discount,
      // "bv" : Math.floor(((Number(this.itemqty[i])*Number(this.productsList[i].bv)))*100)/100,
      "bv" : ((Number(this.itemqty[i])*Number(this.productsList[i].bv))),
      // "net_amount" : Math.floor(((Number(this.itemqty[i])*Number(this.productsList[i].mrp))+((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*(Number(this.productsList[i].gst_percent)/100)))*100)/100
      "net_amount" : ((Number(this.itemqty[i])*Number(this.productsList[i].mrp))+((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*(Number(this.productsList[i].gst_percent)/100)))
    };
    const stocktosend = {
      "product_id" : this.productsList[i].product_id,
      "stock_id" : this.productsList[i].stock_id,
      "quantity" : this.itemqty[i],
      "unit_price" : this.productsList[i].mrp,
      "gst_percent" : this.productsList[i].gst_percent
    }

    console.log(newarray);
    console.log(stocktosend);
    this.selectedStocksList.push(stocktosend);
    this.itemsList.push(newarray);
    // this.total_cgst = this.total_cgst + (Math.floor(((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*((Number(this.productsList[i].gst_percent)/2)/100))*100)/100);
    this.total_cgst = this.total_cgst + ((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*((Number(this.productsList[i].gst_percent)/2)/100));
    // this.total_sgst = this.total_sgst + (Math.floor(((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*((Number(this.productsList[i].gst_percent)/2)/100))*100)/100);
    this.total_sgst = this.total_sgst + ((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*((Number(this.productsList[i].gst_percent)/2)/100));
    // this.total_selling_price = this.total_selling_price + (Math.floor((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*100)/100);
    this.total_selling_price = this.total_selling_price + (Number(this.itemqty[i])*Number(this.productsList[i].mrp));
    // this.total_net_price = this.total_net_price + (Math.floor(((Number(this.itemqty[i])*Number(this.productsList[i].mrp))+(((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*(Number(this.productsList[i].gst_percent)/2)/100)*2))*100)/100);
    this.total_net_price = this.total_net_price + ((Number(this.itemqty[i])*Number(this.productsList[i].mrp))+(((Number(this.itemqty[i])*Number(this.productsList[i].mrp))*(Number(this.productsList[i].gst_percent)/2)/100)*2));
    // this.total_bv = this.total_bv + (Math.floor((Number(this.itemqty[i])*Number(this.productsList[i].bv))*100)/100);
    this.total_bv = this.total_bv + (Number(this.itemqty[i])*Number(this.productsList[i].bv));
    this.price_in_word = price_in_words(Number(this.total_net_price));
    this.productsList = [];
  }

  removeItem(i:number) {
    console.log(this.itemsList[i].cgst);
    this.total_net_price = this.total_net_price - Number(this.itemsList[i].total_amount);
    this.total_selling_price = this.total_selling_price - (Number(this.itemsList[i].unit_price)*Number(this.itemsList[i].qty));
    this.total_cgst = this.total_cgst - Number(this.itemsList[i].cgst);
    this.total_sgst = this.total_sgst - Number(this.itemsList[i].sgst);
    this.total_bv =  this.total_bv - Number(this.itemsList[i].bv);
    this.price_in_word = price_in_words(this.total_net_price);
    this.itemsList.splice(i, 1);
    this.selectedStocksList.splice(i, 1);


  }

  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
  var popupWin = window.open('', '_blank', 'width=1200,height=1200');
  popupWin.document.open();
  popupWin.document.write('<html><head><script type="text/javascript">function PrintWindow(){ window.print(); CheckWindowState();}  function CheckWindowState(){ if(document.readyState=="complete") {  window.close();  }  else  {  setTimeout("CheckWindowState()", 2000)  }  }  </script><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="PrintWindow()">' + printContents + '</body></html>');
  popupWin.document.close();
    // var printContents = document.getElementById(divName).innerHTML;
    // var originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents;

    // window.print();

    // document.body.innerHTML = originalContents;
}

  showPreview(){
    this.is_preview = true;
  }

  setUserCashAmount(){
    this.user_cash_amount = this.total_selling_price - this.user_used_bv_amount;
  }

  getSuppliersList(){
    this.spinner.show();
    this.dataService.GetList('phorder/get-suppliers-for-stock-entry').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.supplierList = anyData.suppliers;

      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

  getSupplierProductList(){
    this.getSupplierDetails(this.supplier_id);
    this.spinner.show();
    this.dataService.GetDetails('stock/product-details-by-supplier_id/', this.supplier_id).subscribe((data) => {
      this.spinner.hide();
      const anyData = data as any;
      console.log(JSON.stringify(anyData.branch));
      if(anyData.status === 200){
        this.selectedKeywordList = anyData.products;
      }else{        
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
    }, error =>{
      this.spinner.hide();
      showDialog3();
    });
  }
  

  getSupplierDetails(id){
    this.spinner.show();
    this.dataService.GetDetails('suppliers/', id).subscribe((data) => {
      this.spinner.hide();
      const anyData = data as any;
      this.is_user_searched = true;
      this.branchDetailList = anyData.supplier;
      this.invoice_name = this.branchDetailList.s_name;
      this.invoice_email = this.branchDetailList.email;
      this.invoice_mobile = this.branchDetailList.mobile_no;
      this.invoice_address = this.branchDetailList.address1 + '<br>' + this.branchDetailList.address2 + '<br>' + this.branchDetailList.dist + '<br' + this.branchDetailList.state + '<br> PIN - ' + this.branchDetailList.pin_code;
      // this.is_preview = true;
    }, error =>{
      this.spinner.hide();
      showDialog3();
    });
  }

  onSubmit() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Submit it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const datatosend = {
          "transfer_header" : {
            "supplier_id" : this.supplier_id,
            "amount" : Math.floor(this.total_net_price*100)/100,
            "remarks" : "Hello"
          },
          "order_list" : this.selectedStocksList
        }
          this.spinner.show();
            this.dataService.Create('stock/purchase-return', datatosend).subscribe((data) => {
              this.spinner.hide();
              const anyData = data as any;
                  if(anyData.status === 200){
                    this.user_invoice_no = anyData.invoice_no;
                    this.is_submit = false;
                    this.is_print = true;
                    Swal.fire(
                      'Submitted!',
                      'Your return Submitted Successfully. Print Invoice!',
                      'success'
                    )
                  }else{        
                    Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: anyData.error[Object.keys(anyData.error)[0]]
                    });
                  }
            }, error => {
              this.spinner.hide();
              showDialog3();
            });
      }
    })
    } 
}
