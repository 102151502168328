
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Other Billing History</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Billing &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Other Billing History</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="tabbable-line">
								<!-- <ul class="nav nav-tabs">
                                    <li class="active">
                                        <a href="#tab1" data-toggle="tab"> List View </a>
                                    </li>
                                    <li>
                                        <a href="#tab2" data-toggle="tab"> Grid View </a>
                                    </li>
                                </ul> -->
								<ul class="nav customtab nav-tabs" role="tablist">
									<!-- <li class="nav-item"><a href="#tab1" class="nav-link active" data-toggle="tab">List
											View</a></li> -->
									<!-- <li class="nav-item"><a href="#tab2" class="nav-link" data-toggle="tab">Grid
											View</a></li> -->
								</ul>
								<div class="tab-content">
									<div class="tab-pane active fontawesome-demo" id="tab1">
										<div class="row">
											<div class="col-md-12">
												<div class="card card-box">
													<div class="card-head">
														<header>All Records<a id="auto_trigger" href="/billing/otherhistory/print"></a></header>
														<div class="tools">
															<a class="fa fa-repeat btn-color box-refresh"
																href="javascript:;"></a>
															<a class="t-collapse btn-color fa fa-chevron-down"
																href="javascript:;"></a>
															<a class="t-close btn-color fa fa-times"
																href="javascript:;"></a>
														</div>
													</div>
													<div class="card-body ">
														<div class="row">
															<div class="col-md-12 col-sm-12 col-12">
																<div class="btn-group">
																	<table class="col-md-12 col-sm-12 col-12">
																		<tr>
																			<td class="col-md-2 col-sm-2 col-2">From Date:</td>
																			<td class="col-md-3 col-sm-3 col-3"><input class="form-control" type="date" [(ngModel)]="fromDate"></td>
																			<td class="col-md-2 col-sm-2 col-2"></td><td>To Date:</td>
																			<td class="col-md-3 col-sm-3 col-3"><input class="form-control" type="date" [(ngModel)]="toDate"></td>
																			<td class="col-md-2 col-sm-2 col-2"><button class="btn btn-primary" (click)="getItemsList()">Search</button></td></tr>
																	</table>
																</div>
															</div>
														</div>
														<div class="table-scrollable">
															<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" 	class="table table-striped table-bordered table-hover table-checkable order-column valign-middle btn-sweetalert"
																>
																<thead>
																	<tr>
																		<th>#</th>
																		<th> Invoice No. </th>
																		<th> Name </th>
																		<th> Cash Amount </th>
																		<th> Invoice Date </th>
																		<th> Action </th>
																	</tr>
																</thead>
																<tbody>
																	<tr class="odd gradeX" *ngFor="let item of itemsList; let i = index">
																		<td class="left">
																			{{i+1}}
																		</td>
                                                                        <td class="left"> {{ item.invoice_no}}</td>
																		<td class="left">{{item.name}}</td>
                                    									<td class="left"> {{item.amount}}</td>
																		<td class="left">{{item.transaction_date | date: 'dd-MM-yyyy'}}</td>
																		<td class="left">
																			<button (click)="viewInvoiceDetail(item.id)" type="button" class="btn btn-primary btn-xs">
																				 View
																			</button>
																			<button (click)="deleteInvoiceDetail(item.id)" type="button" class="btn btn-danger btn-xs">
																				Delete
																		   </button>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- <div class="tab-pane" id="tab2">
										<div class="row">
											<div class="col-md-4" *ngFor="let item of itemsList; let i = index">
												<div class="card card-box">
													<div class="card-body no-padding ">
														<div class="doctor-profile">
															<img src="../../../assets/images/logo4.png" class="doctor-pic"
																alt="">
															<div class="profile-usertitle">
																<div class="doctor-name">{{ item.first_name}} {{item.last_name}} </div>
																<div class="name-center"> {{ item.operator_type }} </div>
															</div>
															<p>{{item.address}} <br /> {{item.dist}} <br /> {{item.state}} <br/> {{item.pin_code}}</p>
															<div>
																<p><i class="fa fa-phone"></i><a
																		href="tel:{{item.mobile_no}}"> {{item.mobile_no}}</a></p>
															</div>
															<div class="profile-userbuttons">
																<a href="#"
																	class="btn btn-circle deepPink-bgcolor btn-sm">Read
																	More</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

									</div> -->
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>

</body>

