import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

declare function showDialog6(): any;
declare function showDialog3(): any;
declare function showDialog11(): any;
@Component({
  selector: 'app-branchedit',
  templateUrl: './branchedit.component.html',
  styleUrls: ['./branchedit.component.scss']
})
export class BrancheditComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;

  branch_name = new FormControl('');
  email = new FormControl('');
  mobile_no = new FormControl('');
  gst_no = new FormControl('');
  branch_type = new FormControl('');
  address1 = new FormControl('');
  address2 = new FormControl('');
  dist = new FormControl('');
  state = new FormControl('');
  pin_code = new FormControl('');

  branchForm = new FormGroup({branch_name: new FormControl(), mobile_no: new FormControl(), gst_no: new FormControl(), email: new FormControl()});


  itemList: any;
  branchTypeList: any;



  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
    ) {
      this.itemList = [] ;
      this.branchTypeList = [];
    this.getDetails(localStorage.getItem('activeid'));
    this.getBranchTypeList();
   }

  ngOnInit(){

  }

  getBranchTypeList(){
    this.spinner.show();
    this.dataService.GetList('branch-types').subscribe((data) => {
      const anyData = data as any;
      this.branchTypeList = anyData.branches;
      this.spinner.hide();
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

  getDetails(id){
    this.spinner.show();
    this.dataService.GetDetails('branchs/', id).subscribe((data) => {
      this.spinner.hide();
      const anyData = data as any;
      console.log(JSON.stringify(anyData.branch));

      this.itemList = anyData.branch;
      this.branch_name.setValue(this.itemList.branch_name);
      this.email.setValue(this.itemList.email);
      this.mobile_no.setValue(this.itemList.mobile_no);
      this.gst_no.setValue(this.itemList.gst_no);
      this.address1.setValue(this.itemList.address1);
      this.address2.setValue(this.itemList.address2);
      this.dist.setValue(this.itemList.dist);
      this.state.setValue(this.itemList.state);
      this.pin_code.setValue(this.itemList.pin_code);
      this.branch_type.setValue(this.itemList.branch_type_id);
     
    }, error =>{
      this.spinner.hide();
      showDialog3();
    });
  }

  // onSubmit() {
  //   if (this.branchForm.valid) {
  //     // alert('Success');
  //     // alert(JSON.stringify(this.branchForm.value));
  //     console.log(this.branchForm.value);
  //     this.spinner.show();
  //       this.dataService.Edit('branchs/', JSON.stringify(this.branchForm.value),localStorage.getItem('activeid')).subscribe((data) => {
  //         this.spinner.hide();
  //         showDialog6();
  //       }, error => {
  //         this.spinner.hide();
  //         showDialog3();
  //       });
  //   } else{
  //     showDialog11();
  //   }
  // }
  onSubmit(form: NgForm) {
    if(!this.branch_name.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Branch Name is Required'
      });
    }else if(!this.mobile_no.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Mobile Number is Required'
      });
    }else if(!this.address1.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Address 1 is Required'
      });
    }else if(!this.dist.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'District is Required'
      });
    }else if(!this.state.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'State is Required'
      });
    }else{
      const datatosend = {
        "branch_name":this.branch_name.value,
        "gst_no":this.gst_no.value,
        "email":this.email.value,
        "mobile_no":this.mobile_no.value,
        "branch_type": this.branch_type.value,
          "address":
            {
            "address1":this.address1.value,
            "address2":this.address2.value,
            "state":this.state.value,
            "pin_code":this.pin_code.value,
            "dist":this.dist.value
            }
          
        }
        this.spinner.show();
        this.dataService.Edit('branchs/', datatosend, localStorage.getItem('activeid')).subscribe((data) => {
              this.spinner.hide();
              const anyData = data as any;
              if(anyData.status === 200){
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Branch Updated Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
              // showDialog6();
            }, error => {
              this.spinner.hide();
              Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'Please Try Again'
              });
            });
    }
  }

}

