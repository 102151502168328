import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import * as CryptoJS from 'crypto-js';
import { EncdecService } from 'src/app/services/encdec.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
//  currentItem = this.dataService.currentItem;
currentItem = this.dataService.currentItem;
 sidemenu = this.dataService.sidemenu;
 operatorDetail : any;
 sub: any;
 page: any;

 total_users: any;
 total_operators: any;
 total_products: any;

//  localSidemenu: [
//   {
//     "url" : "dashboard",
//     "title" : "Dashboard",
//     "icon" : "dashboard"
//   },
//   {
//     "url" : "operator",
//     "title" : "Operators",
//     "icon" : "person"
//   },
//   {
//     "url" : "user",
//     "title" : "Users",
//     "icon" : "group"
//   },
//   {
//     "url" : "supplier",
//     "title" : "Suppliers",
//     "icon" : "assignment_ind"
//   },
//   {
//     "url" : "branch",
//     "title" : "Branch",
//     "icon" : "extension"
//   },
//   {
//     "url" : "productcategory",
//     "title" : "Category",
//     "icon" : "view_column"
//   },
//   {
//     "url" : "productsubcategory",
//     "title" : "Subcategory",
//     "icon" : "view_module"
//   },
//   {
//     "url" : "brand",
//     "title" : "Brands",
//     "icon" : "store_mall_directory"
//   },
//   {
//     "url" : "product",
//     "title" : "Products",
//     "icon" : "markunread_mailbox"
//   },
//   ];

 

  constructor(
    private router: Router,
    private dataService: DataService,
    private encdecService: EncdecService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
    ) { 
      this.operatorDetail = [];
     
      
      // const encsidemenu = CryptoJS.AES.encrypt(JSON.stringify(this.localSidemenu), 'ravi').toString();

      // console.log(encsidemenu);

      // sessionStorage.setItem('sidemenu', encsidemenu);

      // const bytemenu = CryptoJS.AES.decrypt(encsidemenu, 'ravi');
      // console.log(bytemenu);
      // const dencsidemenu =  CryptoJS.AES.decrypt(encsidemenu, 'kant').toString(CryptoJS.enc.Utf8);
      // console.log(JSON.stringify(dencsidemenu));
   

  }

  @ViewChild('myLink') myDiv: ElementRef<HTMLElement>;

  triggerFalseClick() {
   let el: HTMLElement = this.myDiv.nativeElement;
   el.click();
}

  // ngOnInit(){
    // var encrypted = this.encdecService.set('123456$#@$^@1ERF', 'password@123456');
    // var decrypted = this.encdecService.get('123456$#@$^@1ERF', encrypted);
   
    // console.log('Encrypted :' + encrypted);
    // console.log('Decrypted :' + decrypted);
  // }

  ngOnInit(){
    this.getUserDetail();
    // this.triggerFalseClick();
    // let element:HTMLElement = document.getElementById('auto_trigger') as HTMLElement;

    // element.click();
    // this.sub = this.route.queryParams.subscribe(params => {
    //     sessionStorage.setItem('activeMenuId', params['id']);
    //   });     
    
  }

  // gotoDashboard(){
  //   alert('dashboard');
  //   this.router.navigate(['dashboard']);
  // }

  ngOnDestroy() {
    // this.sub.unsubscribe();
  }

  getUserDetail(){
    this.spinner.show();
    this.dataService.GetList('operators/get-dashboard-info').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.total_users = anyData.dashboard_info[1];
        this.total_operators = anyData.dashboard_info[2];
        this.total_products = anyData.dashboard_info[0];

        
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }      
    }, error => {
      
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Network Error! Please Try Again.'
      });
    });
  
  }

}
