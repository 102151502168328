import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';


declare function showDialog6(): any;
declare function showDialog3(): any;
declare function showDialog11(): any;
declare function price_in_words(invoice_amount): any;


@Component({
  selector: 'app-stockreturn',
  templateUrl: './stockreturn.component.html',
  styleUrls: ['./stockreturn.component.scss']
})
export class StockreturnComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  itemsList: any;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.itemsList = [];
   }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.getAvailableStockList();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getAvailableStockList(){
    this.spinner.show();
    this.dataService.GetList('stock/get-all-pending-or-rejected-return').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.itemsList = anyData.srh;
      // console.log(JSON.stringify(this.addrList));
      this.dtTrigger.next();
      this.dtTrigger.unsubscribe();
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
    }, error => {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Network Error, Please Try Again.'
      });
    });
  }

  editStockReturn(id){
    localStorage.setItem('activeid', id);
    // this.router.navigate(['stock/transfer/edit']);
    let element:HTMLElement = document.getElementById('auto_trigger') as HTMLElement;

    element.click();
  }
  getStockReturnDetail(id){
    localStorage.setItem('activeid', id);
    // this.router.navigate(['stock/transfer/detail']);
    let element:HTMLElement = document.getElementById('auto_trigger1') as HTMLElement;

    element.click();
  }

  // changeStatus(id, status){
  //   let new_status;
  //   if(status === 1){
  //     new_status = 0;
  //   }else{
  //     new_status = 1;
  //   }
  //   const datatosend = {
  //     "product_id" : id,
  //     "status" : new_status
  //   }
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'You are going to change status!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, change it!',
  //   }).then((result) => {
  //     // alert(result.value);
  //     if (result.value) {
  //       this.spinner.show();
  //       this.dataService.Create('operators/purchaseheader/update-status', datatosend).subscribe((data) => {
  //         this.spinner.hide();
  //         // showDialog6();
  //         this.getPurchaseHeaderList();
  //       }, error => {
  //         this.spinner.hide();
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Error',
  //           text: 'Network Error, Please Try Again.'
  //         });
  //       });

  //     }
  //   });
  // }

}
