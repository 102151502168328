import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgForm, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';

declare function showDialog6(): any;
declare function showDialog3(): any;
@Component({
  selector: 'app-supplieradd',
  templateUrl: './supplieradd.component.html',
  styleUrls: ['./supplieradd.component.scss']
})
export class SupplieraddComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;

  s_name = new FormControl('');
  c_name = new FormControl('');
  email = new FormControl('');
  mobile_no = new FormControl('');
  mobile_no2 = new FormControl('');
  gst_no = new FormControl('');
  opening_balance = new FormControl('');
  address1 = new FormControl('');
  address2 = new FormControl('');
  dist = new FormControl('');
  state = new FormControl('');
  pin_code = new FormControl('');

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
  ) {
   }

  ngOnInit(){
  }



  onSubmit(form: NgForm) {
    if(!this.s_name.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Supplier Name is Required'
      });
    }else if(!this.mobile_no.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Mobile Number is Required'
      });
    }else  if(!this.email.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Email is Required'
      });
    }else if(!this.address1.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Address 1 is Required'
      });
    }else if(!this.dist.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'District is Required'
      });
    }else if(!this.state.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'State is Required'
      });
    }else{
      const datatosend = {
        "s_name":this.s_name.value,
        "c_name":this.c_name.value,
        "gst_no":this.gst_no.value,
        "opening_balance" : this.opening_balance.value,
        "mobile_no2":this.mobile_no2.value,
        "email":this.email.value,
        "mobile_no":this.mobile_no.value,
          "address":
            {
            "address1":this.address1.value,
            "address2":this.address2.value,
            "state":this.state.value,
            "pin_code":this.pin_code.value,
            "dist":this.dist.value
            }
        }
        this.spinner.show();
            this.dataService.Create('suppliers',datatosend).subscribe((data) => {
              this.spinner.hide();
              const anyData = data as any;
              if(anyData.status === 200){
                form.reset();
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Supplier Created Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
              // showDialog6();
            }, error => {
              this.spinner.hide();
              Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'Please Try Again'
              });
            });

    }
    
    

  }



}
