import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

declare function showDialog6(): any;
declare function showDialog3(): any;
declare function showDialog11(): any;


@Component({
  selector: 'app-updateoperator',
  templateUrl: './updateoperator.component.html',
  styleUrls: ['./updateoperator.component.scss']
})
export class UpdateoperatorComponent implements OnInit {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;

  // operatorForm = new FormGroup({f_name: new FormControl(), l_name: new FormControl(), mobile_no: new FormControl(), gender: new FormControl(), dob: new FormControl()});


  operatorid : string;
  itemList: any;
  branchList: any;
  roleList: any;
  roleTooltip: any;

  first_name = new FormControl('');
  last_name = new FormControl('');
  gender = new FormControl('');
  dob = new FormControl('');
  branch = new FormControl('');
  operator_type = new FormControl('');
  address1 = new FormControl('');
  address2 = new FormControl('');
  dist = new FormControl('');
  state = new FormControl('');
  pin_code = new FormControl('');



  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
    ) {
      this.itemList = [] ;
      this.branchList = [];
      this.roleList = [];
    this.getOperatorDetails(localStorage.getItem('activeid'));
    this.getBranchList();
    this.getRoleList();

    // this.spinner.show();
        // this.dataService.GetOrderwiz().subscribe((data) => {
        //   // this.spinner.hide();
        //   console.log(JSON.stringify(data));
        //   showDialog6();
        // }, error => {
        //   // this.spinner.hide();
        //   showDialog3();
        // });

   }

  ngOnInit(){

  }
  setTooltip(){
    this.roleList.forEach(element => {
      if(element.id == this.operator_type.value){
        this.roleTooltip = element.description;
      }
    });
  }

  getBranchList(){
    this.spinner.show();
    this.dataService.GetList('branchs').subscribe((data) => {
      const anyData = data as any;
      this.branchList = anyData.branchs;
      this.spinner.hide();
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

  getRoleList(){
    this.spinner.show();
    this.dataService.GetList('operators/operator/roles').subscribe((data) => {
      const anyData = data as any;
      this.roleList = anyData.roles;
      this.spinner.hide();
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

  getOperatorDetails(operatorid){
    this.spinner.show();
    this.dataService.GetDetails('operators/operator-by-id/', operatorid).subscribe((data) => {
      this.spinner.hide();
      const anyData = data as any;
      // console.log(JSON.stringify(anyData.operator));
      if(anyData.status === 200){
        this.itemList = anyData.operator;
        this.first_name.setValue(this.itemList.first_name);
        this.last_name.setValue(this.itemList.last_name);
        this.gender.setValue(this.itemList.gender);
        this.dob.setValue(this.itemList.dob);
        this.address1.setValue(this.itemList.address1);
        this.address2.setValue(this.itemList.address2);
        this.dist.setValue(this.itemList.dist);
        this.state.setValue(this.itemList.state);
        this.pin_code.setValue(this.itemList.pin_code);
        this.branch.setValue(this.itemList.branch_id);
        this.operator_type.setValue(this.itemList.role_id);
        
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }


    }, error =>{
      this.spinner.hide();
      showDialog3();
    });
  }

  onSubmit(form: NgForm) {
      // alert('Success');
      // alert(JSON.stringify(this.operatorForm.value));
     const datatosend = {
       
       "operator_type": this.operator_type.value,
       "branch" : this.branch.value,

     }
      this.spinner.show();
        this.dataService.Edit('operators/', datatosend, localStorage.getItem('activeid')).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
          if(anyData.status === 200){
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Record Updated Successfully'
            });
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: anyData.error[Object.keys(anyData.error)[0]]
            });
          }
          
        }, error => {
          this.spinner.hide();
          showDialog3();
        });
    
  }

}
