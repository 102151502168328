import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { DatePipe } from '@angular/common';

declare function price_in_words(invoice_amount): any;

@Component({
  selector: 'app-billingotherhistoryprint',
  templateUrl: './billingotherhistoryprint.component.html',
  styleUrls: ['./billingotherhistoryprint.component.scss']
})
export class BillingotherhistoryprintComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  sub: any;

  dtOptions: DataTables.Settings = {};
  // persons: Person[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  public loading = false;

  itemsList: any;
  exportList: any;
  operatorid: string;
  dataForExcel = [];
  newExportList : any = [];
  invoiceDetail: any = [];
  total_net_discount: number = 0;
  total_mrp: number = 0;
  total_net_amount: number = 0;
  total_net_bv: number = 0;
  total_gst: number = 0;
  invoice_no: any;
  price_in_word: any;
  order_date: any;
  user_name: any;
  mobile_no: any;
  gst_no: any;
  email: any;
  address: any;
  order_list: any =[];

  branch_address: any;
  branch_mobile: any;
  branch_email: any;
  branch_gst: any;
  branch_detail: any;
  

  fromDate: any;
  toDate: any;



  constructor(
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    public ete: ExportExcelService,
    private datePipe: DatePipe

  ) {
    this.itemsList = [];
    this.exportList = []
    
    this.branch_detail = [];
    this.fromDate = '2021-02-01 ';
    this.toDate = '2021-02-22';

    this.getBranchDetail();

   


   }



  ngOnInit(): void {
    this.getDetails();
    
  }
  getBranchDetail(){
    //loggedInOperatorId
    this.spinner.show();
    this.dataService.GetList('operators/profile').subscribe((data) => {
      const anyData = data as any;
      this.branch_detail = anyData.operator.branch;
      this.branch_address = this.branch_detail.address1 + ", " + this.branch_detail.address2 + ", "  + this.branch_detail.dist + ", " + this.branch_detail.state + ", PIN : " + this.branch_detail.pin_code;
      this.branch_mobile = this.branch_detail.mobile_no;
      this.branch_email = this.branch_detail.email;
      this.branch_gst = this.branch_detail.gst_no;

      
     
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  
  }

  ngOnDestroy(): void {
   
  }

  ngAfterViewInit(): void{
    // this.dtTrigger.next();
  }

  getDetails(){
    this.spinner.show();
    const datatosend = {
      "other_order_id" : sessionStorage.getItem('order_id')
    }
    this.dataService.Create('billing/get-other-user-invoice-by-id', datatosend).subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.invoiceDetail = anyData.invoice;
        this.invoice_no = this.invoiceDetail.invoice_no;
        this.gst_no = this.invoiceDetail.gst_no;
        this.total_net_bv = this.invoiceDetail.bv_amount;
        this.total_net_amount = this.invoiceDetail.amount;
        this.price_in_word = price_in_words(this.invoiceDetail.amount);
        this.order_date = this.invoiceDetail.order_date;
        // this.user_name = this.invoiceDetail.mlmuser.first_name + ' ' + this.invoiceDetail.mlmuser.last_name;
        // this.mobile_no = this.invoiceDetail.mlmuser.mobile_no;
        // this.email = this.invoiceDetail.mlmuser.email;
        // this.address = this.invoiceDetail.mlmuser.addresses[0].address1+'<br>'+this.invoiceDetail.mlmuser.addresses[0].address2+'<br>'+this.invoiceDetail.mlmuser.addresses[0].dist+'<br>'+this.invoiceDetail.mlmuser.addresses[0].state+'<br>PIN - '+this.invoiceDetail.mlmuser.addresses[0].pin_code;
        for(let i=0; i<this.invoiceDetail.otherorderdetail.length; i++){

          const datalist = {
            "description" : this.invoiceDetail.otherorderdetail[i].stock.phorder.product.product_name,
            "hsn_code" : this.invoiceDetail.otherorderdetail[i].stock.phorder.product.hsn_code,
            "rate" : (this.invoiceDetail.otherorderdetail[i].stock.phorder.sale_price-this.invoiceDetail.otherorderdetail[i].discount)*(100/(this.invoiceDetail.otherorderdetail[i].stock.phorder.tax.tax_percent+100)),
            "gst_percent" : this.invoiceDetail.otherorderdetail[i].stock.phorder.tax.tax_percent,
            "gst" : (this.invoiceDetail.otherorderdetail[i].stock.phorder.sale_price-this.invoiceDetail.otherorderdetail[i].discount)-(this.invoiceDetail.otherorderdetail[i].stock.phorder.sale_price-this.invoiceDetail.otherorderdetail[i].discount)*(100/(this.invoiceDetail.otherorderdetail[i].stock.phorder.tax.tax_percent+100)),
            "discount" : this.invoiceDetail.otherorderdetail[i].discount,
            "quantity" : this.invoiceDetail.otherorderdetail[i].quantity,
            "total_amount": (this.invoiceDetail.otherorderdetail[i].stock.phorder.sale_price-this.invoiceDetail.otherorderdetail[i].discount)*this.invoiceDetail.otherorderdetail[i].quantity
          }
          console.log(datalist);
          this.order_list.push(datalist);
          this.total_net_discount = this.total_net_discount + (this.invoiceDetail.otherorderdetail[i].discount*this.invoiceDetail.otherorderdetail[i].quantity);
          this.total_mrp = this.total_mrp + ((this.invoiceDetail.otherorderdetail[i].stock.phorder.sale_price-this.invoiceDetail.otherorderdetail[i].discount)*(100/(this.invoiceDetail.otherorderdetail[i].stock.phorder.tax.tax_percent+100))*this.invoiceDetail.otherorderdetail[i].quantity);
          this.total_gst = this.total_gst + ((this.invoiceDetail.otherorderdetail[i].stock.phorder.sale_price-this.invoiceDetail.otherorderdetail[i].discount)-(this.invoiceDetail.otherorderdetail[i].stock.phorder.sale_price-this.invoiceDetail.otherorderdetail[i].discount)*(100/(this.invoiceDetail.otherorderdetail[i].stock.phorder.tax.tax_percent+100))*this.invoiceDetail.otherorderdetail[i].quantity);

        }

        
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
     
    }, error => {
      this.spinner.hide();
    });
  }

  // calculate_discount(amount, id){

  //   alert(id);
  //   this.net_discount = this.net_discount + Number(amount);
  //   alert(this.net_discount);

  // }

  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
  var popupWin = window.open('', '_blank', 'width=1200,height=1200');
  popupWin.document.open();
  popupWin.document.write('<html><head><script type="text/javascript">function PrintWindow(){ window.print(); CheckWindowState();}  function CheckWindowState(){ if(document.readyState=="complete") {  window.close();  }  else  {  setTimeout("CheckWindowState()", 2000)  }  }  </script><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="PrintWindow()">' + printContents + '</body></html>');
  popupWin.document.close();
    // var printContents = document.getElementById(divName).innerHTML;
    // var originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents;

    // window.print();

    // document.body.innerHTML = originalContents;
}
}
