import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-taxinvoice',
  templateUrl: './taxinvoice.component.html',
  styleUrls: ['./taxinvoice.component.scss']
})
export class TaxinvoiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
