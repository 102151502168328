import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, NgForm, FormArray, FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-supplieraddressadd',
  templateUrl: './supplieraddressadd.component.html',
  styleUrls: ['./supplieraddressadd.component.scss']
})
export class SupplieraddressaddComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;

  addressForm: FormGroup;
  address1 = new FormControl('');
  address2 = new FormControl('');
  dist = new FormControl('');
  state = new FormControl('');
  pin_code = new FormControl('');

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) {
    
   }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm){
    if(!this.address1.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Address 1 is required'
      });
    }else if(!this.dist.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'District is Required'
      });
    }else if(!this.state.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'State is Required'
      });
    }else{
      const datatosend = {
        address: {
          "address1": this.address1.value,
          "address2": this.address2.value,
          "dist": this.dist.value,
          "state": this.state.value,
          "pin_code": this.pin_code.value
        }
      }
      this.spinner.show();
      this.dataService.CreateWithID('suppliers/addresses/',datatosend, localStorage.getItem('activeid')).subscribe((data) => {
        this.spinner.hide();
        // alert("success");
        const anyData = data as any;
        if(anyData.status === 200){
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Address Created Successfully'
          });
        }else{        
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: anyData.error[Object.keys(anyData.error)[0]]
          });
        }
       
      }, error => {
        this.spinner.hide();
        // alert("fail");
        Swal.fire({
          icon: 'error',
          title: 'Network Error',
          text: 'Please Try Again'
        });
      });
    }
  }

}
