import { Component, OnInit } from '@angular/core';

import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ExportExcelService } from 'src/app/services/export-excel.service';

@Component({
  selector: 'app-expensetype',
  templateUrl: './expensetype.component.html',
  styleUrls: ['./expensetype.component.scss']
})
export class ExpensetypeComponent implements OnInit {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  sub: any;

  dtOptions: DataTables.Settings = {};
  // persons: Person[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();



  public loading = false;

  itemsList: any;
  newitemsList: any =[];
  exportList: any = [];
  dataForExcel = [];
  newExportList : any = [];
  creditList: any = [];
  debitList: any = [];
  gstList: any = [];
  curdate: any;
  fromDate: any;
  toDate: any;
  net_balance: number = 0;

  net_due_balance: number = 0;

  supplier_detail: any;
  payment_type: any;
  remarks: any;
  gross_amount: any = [];
  cgst_amount: any = [];
  sgst_amount: any = [];
  igst_amount: any = [];
  gst_amount: any = [];
  ms_name: any = [];
  description: any = [];
  add_ms_name: string;
  add_description: string;


  
  transaction_id: any;
  transaction_amount: any;
  transaction_date: any;
  invoice_date: any;
  invoice_no: any;
  gst_id: any;
  operatorid: string;
  myDate = new Date();
  paymentTypeList: any = []; 

  constructor(
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    public ete: ExportExcelService

  ) {

    this.transaction_amount = [];
    this.transaction_date = [];
    this.transaction_id = [];
    this.payment_type = [];
    this.remarks = [];
    this.invoice_date = [];
    this.invoice_no = [];
    this.gst_id = [];

   }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.itemsList = [];
    this.getItemsList();
    // this.sub = this.route.params.subscribe(params => {
    //   sessionStorage.setItem('activeMenuId', params['id']);
    // });   
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    // this.sub.unsubscribe();
  }

  ngAfterViewInit(): void{
    // this.dtTrigger.next();
  }
  getItemsList(){
    this.net_due_balance = 0;
    this.spinner.show();
    this.dataService.GetList('miscexptypes').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.itemsList = anyData.miscexpensetypes;
        for(let i=0; i< this.itemsList.length; i++){
          this.ms_name[i] = this.itemsList[i].misc_exp_type_name;
        }
        // console.log(this.net_due_balance);
        this.dtTrigger.next();
        this.dtTrigger.unsubscribe();
        // console.log(JSON.stringify(this.itemsList));
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }     
    }, error => {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Network Error, Try Again'
      });
    });
  }



  onSubmit(){
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Submit it",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Submit it!'
    }).then((result) => {
      if (result.isConfirmed) {        
        const datatosend = {
          "misc_exp_type_name" : this.add_ms_name   
        }
        this.spinner.show();
        this.dataService.Create('miscexptypes', datatosend).subscribe((data) => {
          const anyData = data as any;
          this.spinner.hide();
          if(anyData.status === 200){
           this.getItemsList();
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: anyData.error[Object.keys(anyData.error)[0]]
            });
          }
         
        }, error => {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Network Error, Try Again'
          });
        });
      }
    })
  }

  update(id, sl){
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Update this record",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!'
    }).then((result) => {
      if (result.isConfirmed) {        
        const datatosend = {
          "misc_exp_type_name" : this.ms_name[sl]
        }
        this.spinner.show();
        this.dataService.Edit('miscexptypes/', datatosend, id).subscribe((data) => {
          const anyData = data as any;
          this.spinner.hide();
          if(anyData.status === 200){
           this.getItemsList();
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: anyData.error[Object.keys(anyData.error)[0]]
            });
          }
         
        }, error => {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Network Error, Try Again'
          });
        });
      }
    })    
  }

 
}
