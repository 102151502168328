
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Add Items</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Items &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active">Add Items</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<div class="card card-box">
								<div class="card-body" id="bar-parent">
									<form id="form_sample_1" class="form-horizontal" [formGroup]="productForm" (ngSubmit)="onSubmit()">

										<!-- <p>
										  <label for="name">Product Name:</label>
										  <input type="text" id="name" name="name" formControlName="name" class="form-control">
										</p> -->
					
										<table class="table table-bordered" formArrayName="products">
										  <tr style="background: #efefef;">
											<th colspan="2">Invoice No.: <input type="text" [formControl]="invoice_no" class="form-control"></th>
											<th colspan="2">Invoice Date: <input type="date" [formControl]="purchase_date" class="form-control"></th>
											<th colspan="2">Total Amount: <input type="text" [formControl]="total_amount" class="form-control"></th>
											<th colspan="2">Supplier: <select class="form-control" [formControl]="supplier_id">
												<option value="">Select...</option>
												<option *ngFor="let c of supplierList" [value]="c.id">{{c.s_name}}</option>
											  </select></th>
											<th colspan="1"><button type="button" (click)="addItem()" class="btn btn-primary">Add Item</button></th>
										  </tr>
										  <tr *ngFor="let item of products().controls; let i=index" [formGroupName]="i">
											<td colspan="2">
												Product Name :
												<select class="form-control" formControlName="product_id">
													<option value="">Select...</option>
													<option *ngFor="let item of itemsList" [value]="item.id">{{item.product_name}}</option>
												  </select>
											</td>
											<td>
												Purchase Price:
												<input type="text" formControlName="unit_price" class="form-control">
											</td>
											<td>
												Sale Price:
												<input type="text" formControlName="sale_price" class="form-control">
											</td>
											<td>
												Fixed Discount:
												<input type="text" formControlName="fixed_discount" class="form-control">
											</td>
											<td>
												BV:
												<input type="text" formControlName="bv" class="form-control">
											</td>
											<td>
												GST:
												<select name="" formControlName="gst_percent" id="" class="form-control">
													<option value="">Select GST</option>
													<option *ngFor="let gst of gstList" [value]="gst.id">{{gst.tax_percent}}</option>
												</select>
											</td>
											<td>
												Quantity:
												<input type="text" formControlName="quantity" class="form-control">
											</td>
											<td>
												<button (click)="removeItem(i)" class="btn btn-danger">Remove</button>
											</td>
										  </tr>
										</table>
										<button class="btn btn-primary" type="button"  (click)="sumTotal()">Calculate</button> Total Sum = {{total_sum}}
										<button type="submit" style="float: right;" class="btn btn-success">Submit</button>
					
									  </form>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>
</body>

