<div class="sidebar-container slim-scroll">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu"  style="padding-top: 20px; overflow: auto;">
        <div id="remove-scroll" class="left-sidemenu">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 col-xs-12" id="sidebar">
                        <div class="list-group" style="padding-bottom: 90px;">
                            <div class="user-panel">
                                <div class="pull-left image">
                                    <img *ngIf="sidemenubar.image_path" style="height: 80px; width: 100px;" src="https://twenty20mvision.com/twenty20/twenty20server/public/{{sidemenubar.image_path}}" class="img-circle user-img-circle"
                                        alt="User Image" />
                                        <img *ngIf="!sidemenubar.image_path"  style="height: 80px; width: 100px;" src="../../assets/images/user.png" class="img-circle user-img-circle"
                                        alt="User Image" />
                                </div>
                                <div class="pull-left info">
                                    <a href="#"><i class="fa fa-circle user-online"></i><span class="txtOnline">
                                            {{sidemenubar.userrole}}</span></a><br><span class="txtOnline" style="color:#0000aa; font-size: 10px;">{{sidemenubar.userbranch}}</span>
                                </div>
                            </div>


                            
                            

                            <!-- <li class="nav-item " *ngFor="let item of sidemenubar.sidemenu; let i=index" [ngClass]="{'active': currenturl === 'item.url'}">
                                <a href="/{{item.url}}" class="nav-link nav-toggle" *ngIf="(item.children | json) == '[]'">
                                    <i class="material-icons">{{item.icon_name}}</i>
                                    <span class="title">{{item.title}}</span>
                                    <span *ngIf="currenturl === 'item.url'" class="selected"></span>
                                    <span *ngIf="(item.children | json) != '[]'" class="arrow"></span>
                                </a>
                                <a class="nav-link nav-toggle" *ngIf="(item.children | json) != '[]'">
                                    <i class="material-icons">{{item.icon_name}}</i>
                                    <span class="title">{{item.title}}</span>
                                    <span *ngIf="currenturl === 'item.url'" class="selected"></span>
                                    <span *ngIf="(item.children | json) != '[]'" class="arrow"></span>
                                </a>
                            
                                <ul *ngIf="(item.children | json) != '[]'" class="sub-menu">
                                    <li *ngFor="let subitem of item.children; let j=index">
                                        <a href="/{{subitem.url}}" class="nav-link ">
                                            <i class="material-icons">{{subitem.icon_name}}</i>
                                            <span class="title"> {{subitem.title}}</span>
                                            <span class="selected"></span>
                                        </a>
                                    </li>
                                </ul>
                            </li> -->


                            <span  *ngFor="let item of sidemenubar.sidemenu; let i=index">
                                <a  *ngIf="(item.children | json) == '[]'" href="/{{item.url}}" class="list-group-item collapsed" data-parent="#sidebar"><i class="fa fa-{{item.icon_name}}"></i> <span class="hidden-sm-down"> {{item.title}}</span></a>
                                <a  *ngIf="(item.children | json) != '[]'" href="#menu{{i}}" class="list-group-item collapsed" data-toggle="collapse" data-parent="#sidebar" aria-expanded="false"><i class="fa fa-{{item.icon_name}}"></i> <span class="hidden-sm-down"> {{item.title}} </span></a>
                                <div  *ngIf="(item.children | json) != '[]'" class="collapse" id="menu{{i}}">
                                    <a *ngFor="let subitem of item.children; let j=index" href="/{{subitem.url}}" class="list-group-item"><i class="fa fa-{{subitem.icon_name}}"></i> {{subitem.title}}</a>
                                </div>
                            </span>












                            <!-- <a href="#menu1" class="list-group-item collapsed" data-toggle="collapse" data-parent="#sidebar" aria-expanded="false"><i class="fa fa-dashboard"></i> <span class="hidden-sm-down">Item 1</span> </a>
                            <div class="collapse" id="menu1">
                                <a href="#menu1sub1" class="list-group-item" data-toggle="collapse" aria-expanded="false">Subitem 1 </a>
                                <div class="collapse" id="menu1sub1">
                                    <a href="#" class="list-group-item" data-parent="#menu1sub1">Subitem 1 a</a>
                                    <a href="#" class="list-group-item" data-parent="#menu1sub1">Subitem 2 b</a>
                                    <a href="#menu1sub1sub1" class="list-group-item" data-toggle="collapse" aria-expanded="false">Subitem 3 c </a>
                                    <div class="collapse" id="menu1sub1sub1">
                                        <a href="#" class="list-group-item" data-parent="#menu1sub1sub1">Subitem 3 c.1</a>
                                        <a href="#" class="list-group-item" data-parent="#menu1sub1sub1">Subitem 3 c.2</a>
                                    </div>
                                    <a href="#" class="list-group-item" data-parent="#menu1sub1">Subitem 4 d</a>
                                    <a href="#menu1sub1sub2" class="list-group-item" data-toggle="collapse"  aria-expanded="false">Subitem 5 e </a>
                                    <div class="collapse" id="menu1sub1sub2">
                                        <a href="#" class="list-group-item" data-parent="#menu1sub1sub2">Subitem 5 e.1</a>
                                        <a href="#" class="list-group-item" data-parent="#menu1sub1sub2">Subitem 5 e.2</a>
                                    </div>
                                </div>
                                <a href="#" class="list-group-item" data-parent="#menu1">Subitem 2</a>
                                <a href="#" class="list-group-item" data-parent="#menu1">Subitem 3</a>
                            </div>
                            <a href="#" class="list-group-item collapsed" data-parent="#sidebar"><i class="fa fa-film"></i> <span class="hidden-sm-down">Item 2</span></a>
                            <a href="#menu3" class="list-group-item collapsed" data-toggle="collapse" data-parent="#sidebar" aria-expanded="false"><i class="fa fa-book"></i> <span class="hidden-sm-down">Item 3 </span></a>
                            <div class="collapse" id="menu3">
                                <a href="#" class="list-group-item" data-parent="#menu3">3.1</a>
                                <a href="#menu3sub2" class="list-group-item" data-toggle="collapse" aria-expanded="false">3.2 </a>
                                <div class="collapse" id="menu3sub2">
                                    <a href="#" class="list-group-item" data-parent="#menu3sub2">3.2 a</a>
                                    <a href="#" class="list-group-item" data-parent="#menu3sub2">3.2 b</a>
                                    <a href="#" class="list-group-item" data-parent="#menu3sub2">3.2 c</a>
                                </div>
                                <a href="#" class="list-group-item" data-parent="#menu3">3.3</a>
                            </div>
                            <a href="#" class="list-group-item collapsed" data-parent="#sidebar"><i class="fa fa-heart"></i> <span class="hidden-sm-down">Item 4</span></a>
                            <a href="#" class="list-group-item collapsed" data-parent="#sidebar"><i class="fa fa-list"></i> <span class="hidden-sm-down">Item 5</span></a>
                            <a href="#" class="list-group-item collapsed" data-parent="#sidebar"><i class="fa fa-clock-o"></i> <span class="hidden-sm-down">Link</span></a>
                            <a href="#" class="list-group-item collapsed" data-parent="#sidebar"><i class="fa fa-th"></i> <span class="hidden-sm-down">Link</span></a>
                            <a href="#" class="list-group-item collapsed" data-parent="#sidebar"><i class="fa fa-gear"></i> <span class="hidden-sm-down">Link</span></a>
                            <a href="#" class="list-group-item collapsed" data-parent="#sidebar"><i class="fa fa-calendar"></i> <span class="hidden-sm-down">Link</span></a>
                            <a href="#" class="list-group-item collapsed" data-parent="#sidebar"><i class="fa fa-envelope"></i> <span class="hidden-sm-down">Link</span></a>
                            <a href="#" class="list-group-item collapsed" data-parent="#sidebar"><i class="fa fa-bar-chart-o"></i> <span class="hidden-sm-down">Link</span></a>
                            <a href="#" class="list-group-item collapsed" data-parent="#sidebar"><i class="fa fa-star"></i> <span class="hidden-sm-down">Link</span></a> -->
                        </div>
                    </div>
                    
                </div>
            </div>          
              
        </div> 
    </div>
</div>