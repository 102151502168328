import { ProducteditComponent } from './pages/productedit/productedit.component';
import { BrandeditComponent } from './pages/brandedit/brandedit.component';
import { BrandComponent } from './pages/brand/brand.component';
import { ProductcategoryeditComponent } from './pages/productcategoryedit/productcategoryedit.component';
import { ProductcategoryaddComponent } from './pages/productcategoryadd/productcategoryadd.component';
import { ProductcategoryComponent } from './pages/productcategory/productcategory.component';
import { BrancheditComponent } from './pages/branchedit/branchedit.component';
import { BranchComponent } from './pages/branch/branch.component';
import { SuppliereditComponent } from './pages/supplieredit/supplieredit.component';
import { SupplieraddComponent } from './pages/supplieradd/supplieradd.component';
import { SupplierComponent } from './pages/supplier/supplier.component';
import { componentFactoryName } from '@angular/compiler';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { DashboardComponent } from '../app/pages/dashboard/dashboard.component';
import { ChangepwdComponent } from '../app/pages/changepwd/changepwd.component';
import { LoginComponent } from '../app/pages/login/login.component';
import { AuthGuard} from '../app/auth/auth.guard';
import { LogoutComponent } from './pages/logout/logout.component';
import { OperatorComponent } from './pages/operator/operator.component';
import { AddoperatorComponent } from './pages/addoperator/addoperator.component';
import { UpdateoperatorComponent } from './pages/updateoperator/updateoperator.component';
import { UserComponent } from './pages/user/user.component';
import { AdduserComponent } from './pages/adduser/adduser.component';
import { UpdateuserComponent } from './pages/updateuser/updateuser.component';
import { BranchaddComponent } from './pages/branchadd/branchadd.component';
import { ProductsubcategoryComponent } from './pages/productsubcategory/productsubcategory.component';
import { ProductsubcategoryaddComponent } from './pages/productsubcategoryadd/productsubcategoryadd.component';
import { ProductsubcategoryeditComponent } from './pages/productsubcategoryedit/productsubcategoryedit.component';
import { ProductaddComponent } from './pages/productadd/productadd.component';
import { BrandaddComponent } from './pages/brandadd/brandadd.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { UserloginComponent } from './pages/userlogin/userlogin.component';
import { ProductComponent } from './pages/product/product.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { BranchaddressComponent } from './pages/branchaddress/branchaddress.component';
import { OperatoraddressComponent } from './pages/operatoraddress/operatoraddress.component';
import { OperatoraddressaddComponent } from './pages/operatoraddressadd/operatoraddressadd.component';
import { OperatoraddresseditComponent } from './pages/operatoraddressedit/operatoraddressedit.component';
import { SupplieraddressComponent } from './pages/supplieraddress/supplieraddress.component';
import { SupplieraddressaddComponent } from './pages/supplieraddressadd/supplieraddressadd.component';
import { SupplieraddresseditComponent } from './pages/supplieraddressedit/supplieraddressedit.component';
import { BranchaddressaddComponent } from './pages/branchaddressadd/branchaddressadd.component';
import { BranchaddresseditComponent } from './pages/branchaddressedit/branchaddressedit.component';
import { KycComponent } from './pages/kyc/kyc.component';
import { KycverificationComponent } from './pages/kycverification/kycverification.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileupdateComponent } from './pages/profileupdate/profileupdate.component';
import { PurchaseheaderComponent } from './pages/purchaseheader/purchaseheader.component';
import { PurchaseorderComponent } from './pages/purchaseorder/purchaseorder.component';
import { PurchaseheaderaddComponent } from './pages/purchaseheaderadd/purchaseheaderadd.component';
import { PurchaseheadereditComponent } from './pages/purchaseheaderedit/purchaseheaderedit.component';
import { PurchaseorderaddComponent } from './pages/purchaseorderadd/purchaseorderadd.component';
import { PurchaseordereditComponent } from './pages/purchaseorderedit/purchaseorderedit.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { StockavailableComponent } from './pages/stockavailable/stockavailable.component';
import { StocktransferComponent } from './pages/stocktransfer/stocktransfer.component';
import { BillingmlmComponent } from './pages/billingmlm/billingmlm.component';
import { TaxinvoiceComponent } from './pages/taxinvoice/taxinvoice.component';
import { StocktransferaddComponent } from './pages/stocktransferadd/stocktransferadd.component';
import { HomeComponent } from './pages/home/home.component';
import { StocktranfereditComponent } from './pages/stocktranferedit/stocktranferedit.component';
import { StocktransferdetailComponent } from './pages/stocktransferdetail/stocktransferdetail.component';
import { StockrequestComponent } from './pages/stockrequest/stockrequest.component';
import { PaymentuserpaymentComponent } from './pages/paymentuserpayment/paymentuserpayment.component';
import { PaymentsupplierpaymentComponent } from './pages/paymentsupplierpayment/paymentsupplierpayment.component';
import { PaymentsupplierduesComponent } from './pages/paymentsupplierdues/paymentsupplierdues.component';
import { ReportlevelwiseComponent } from './pages/reportlevelwise/reportlevelwise.component';
import { StockrequestdetailComponent } from './pages/stockrequestdetail/stockrequestdetail.component';
import { BillingotheruserComponent } from './pages/billingotheruser/billingotheruser.component';
import { BillingmlmhistoryComponent } from './pages/billingmlmhistory/billingmlmhistory.component';
import { BillingotherhistoryComponent } from './pages/billingotherhistory/billingotherhistory.component';
import { StocktransferhistoryComponent } from './pages/stocktransferhistory/stocktransferhistory.component';
import { BillingmlmhistoryprintComponent } from './pages/billingmlmhistoryprint/billingmlmhistoryprint.component';
import { BillingotherhistoryprintComponent } from './pages/billingotherhistoryprint/billingotherhistoryprint.component';
import { StocktransferhistoryprintComponent } from './pages/stocktransferhistoryprint/stocktransferhistoryprint.component';
import { ReportsalesComponent } from './pages/reportsales/reportsales.component';
import { SearchstocktransferComponent } from './pages/searchstocktransfer/searchstocktransfer.component';
import { PurchaseordercustomeditComponent } from './pages/purchaseordercustomedit/purchaseordercustomedit.component';
import { StockreturnComponent } from './pages/stockreturn/stockreturn.component';
import { PurchasereturnComponent } from './pages/purchasereturn/purchasereturn.component';
import { ReportpurchaseComponent } from './pages/reportpurchase/reportpurchase.component';
import { ReportsupplierComponent } from './pages/reportsupplier/reportsupplier.component';
import { ReportstocktransferComponent } from './pages/reportstocktransfer/reportstocktransfer.component';
import { StockreturnaddComponent } from './pages/stockreturnadd/stockreturnadd.component';
import { StockreturnrequestComponent } from './pages/stockreturnrequest/stockreturnrequest.component';
import { StockreturnrequestdetailComponent } from './pages/stockreturnrequestdetail/stockreturnrequestdetail.component';
import { StockreturneditComponent } from './pages/stockreturnedit/stockreturnedit.component';
import { StockreturndetailComponent } from './pages/stockreturndetail/stockreturndetail.component';
import { PurchasereturnaddComponent } from './pages/purchasereturnadd/purchasereturnadd.component';
import { PurchasereturndetailComponent } from './pages/purchasereturndetail/purchasereturndetail.component';
import { ExpensetypeComponent } from './pages/expensetype/expensetype.component';
import { DatewisereportComponent } from './report/datewisereport/datewisereport.component';
import { MiscexpenseallComponent } from './pages/miscexpenseall/miscexpenseall.component';
import { MiscexpenseaddComponent } from './pages/miscexpenseadd/miscexpenseadd.component';
import { MiscexpenseeditComponent } from './pages/miscexpenseedit/miscexpenseedit.component';
import { BranchwisereportComponent } from './report/branchwisereport/branchwisereport.component';

const routes: Routes = [
 {
  path: '',
  component: LoginComponent

 },
 {
   path: 'home',
   component: HomeComponent,
   canActivate: [AuthGuard]
 },
 {
   path: 'dashboard',
   component: DashboardComponent,
   canActivate: [AuthGuard]
 },
 {
   path: 'changepassword',
   component: ChangepwdComponent,
   canActivate: [AuthGuard]
 },
 {
  path: 'operator/login',
  component: LoginComponent
},
{
  path: 'user/login',
  component: UserloginComponent
},
{
  path: 'logout',
  component: LogoutComponent
},
{
  path: 'operator',
  component: OperatorComponent,
  canActivate: [AuthGuard]
},
{
  path: 'operator/add',
  component: AddoperatorComponent,
  canActivate: [AuthGuard]
},
{
  path: 'operator/update',
  component: UpdateoperatorComponent,
  canActivate: [AuthGuard]
},
{
  path: 'operator/address',
  component: OperatoraddressComponent,
  canActivate: [AuthGuard]
},
{
  path: 'operator/address/add',
  component: OperatoraddressaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'operator/address/edit',
  component: OperatoraddresseditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'supplier',
  component: SupplierComponent,
  canActivate: [AuthGuard]
},
{
  path: 'supplier/add',
  component: SupplieraddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'supplier/update',
  component: SuppliereditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'supplier/address',
  component: SupplieraddressComponent,
  canActivate: [AuthGuard]
},
{
  path: 'supplier/address/add',
  component: SupplieraddressaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'supplier/address/edit',
  component: SupplieraddresseditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'branch',
  component: BranchComponent,
  canActivate: [AuthGuard]
},
{
  path: 'branch/add',
  component: BranchaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'branch/update',
  component: BrancheditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'branch/address',
  component: BranchaddressComponent,
  canActivate: [AuthGuard]
},
{
  path: 'branch/address/add',
  component: BranchaddressaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'branch/address/edit',
  component: BranchaddresseditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'product/category',
  component: ProductcategoryComponent,
  canActivate: [AuthGuard]
},
{
  path: 'productcategory/add',
  component: ProductcategoryaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'productcategory/update',
  component: ProductcategoryeditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'product/subcategory',
  component: ProductsubcategoryComponent,
  canActivate: [AuthGuard]
},
{
  path: 'productsubcategory/add',
  component: ProductsubcategoryaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'productsubcategory/update',
  component: ProductsubcategoryeditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'roduct/brand',
  component: BrandComponent,
  canActivate: [AuthGuard]
},
{
  path: 'brand/add',
  component: BrandaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'brand/update',
  component: BrandeditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'product/item',
  component: ProductComponent,
  canActivate: [AuthGuard]
},
{
  path: 'product/add',
  component: ProductaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'product/edit',
  component: ProducteditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'user',
  component: UserComponent,
  canActivate: [AuthGuard]
},
{
  path: 'user/add',
  component: AdduserComponent,
  canActivate: [AuthGuard]
},
{
  path: 'user/update',
  component: UpdateuserComponent,
  canActivate: [AuthGuard]
},
{
  path: "kyc",
  component: KycComponent,
  canActivate: [AuthGuard]
},
{
  path: "kyc/verify",
  component: KycverificationComponent,
  canActivate: [AuthGuard]
},
{
  path: 'registration',
  component: RegistrationComponent
},
{
  path: 'resetpassword',
  component: ResetpasswordComponent
},
{
  path: 'profile',
  component: ProfileComponent,
  canActivate: [AuthGuard]
},
{
  path: 'profile/edit',
  component: ProfileupdateComponent,
  canActivate: [AuthGuard]
},
{
  path: 'purchase/header',
  component: PurchaseheaderComponent,
  canActivate: [AuthGuard]
},
{
  path: 'purchase/header/add',
  component: PurchaseheaderaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'purchase/header/edit',
  component: PurchaseheadereditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/purchase',
  component: PurchaseorderComponent,
  canActivate: [AuthGuard]
},
{
  path: 'purchaseorder/add',
  component: PurchaseorderaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'purchaseorder/edit',
  component: PurchaseordereditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'purchaseorder/customedit',
  component: PurchaseordercustomeditComponent,
  canActivate: [AuthGuard]
},
{
  path : 'stock/available',
  component: StockavailableComponent,
  canActivate: [AuthGuard]
},
{
  path : 'stock/transfer',
  component: StocktransferComponent,
  canActivate: [AuthGuard]
},
{
  path : 'stock/transfer/add',
  component : StocktransferaddComponent,
  canActivate : [AuthGuard]
},
{
  path: 'stock/transfer/edit',
  component: StocktranfereditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/transfer/customedit',
  component: SearchstocktransferComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/transfer/detail',
  component: StocktransferdetailComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/request',
  component: StockrequestComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/request/detail',
  component: StockrequestdetailComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/transferhistory',
  component: StocktransferhistoryComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/transferhistory/print',
  component: StocktransferhistoryprintComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/stockreturn',
  component: StockreturnComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/return/add',
  component: StockreturnaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/return/edit',
  component: StockreturneditComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/return/detail',
  component: StockreturndetailComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/stockreturnreview',
  component: StockreturnrequestComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/stockreturnrequestdetail',
  component: StockreturnrequestdetailComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/purchasereturn',
  component: PurchasereturnComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/purchasereturn/add',
  component: PurchasereturnaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'stock/purchasereturn/detail',
  component: PurchasereturndetailComponent,
  canActivate: [AuthGuard]
},
{
  path: 'billing/mlm',
  component: BillingmlmComponent,
  canActivate: [AuthGuard]
},
{
  path: 'billing/other',
  component: BillingotheruserComponent,
  canActivate: [AuthGuard]
},
{
  path: 'billing/mlmhistory',
  component: BillingmlmhistoryComponent,
  canActivate: [AuthGuard]
},
{
  path: 'billing/otherhistory',
  component: BillingotherhistoryComponent,
  canActivate: [AuthGuard]
},
{
  path: 'billing/mlmhistory/print',
  component: BillingmlmhistoryprintComponent,
  canActivate: [AuthGuard]
},
{
  path: 'billing/otherhistory/print',
  component: BillingotherhistoryprintComponent,
  canActivate: [AuthGuard]
},
{
  path: 'taxinvoice',
  component: TaxinvoiceComponent,
  canActivate: [AuthGuard]
},
{
  path: 'payment/userpayment',
  component: PaymentuserpaymentComponent,
  canActivate: [AuthGuard]
},
{
  path: 'payment/supplierpayment',
  component: PaymentsupplierpaymentComponent,
  canActivate: [AuthGuard]
},
{
  path: 'payment/supplierdues',
  component: PaymentsupplierduesComponent,
  canActivate: [AuthGuard]
},
{
  path: 'report/mlm-user',
  component: ReportlevelwiseComponent,
  canActivate: [AuthGuard]
},
{
  path: 'report/sales',
  component: ReportsalesComponent,
  canActivate: [AuthGuard]
},
{
  path: 'report/purchasereport',
  component: ReportpurchaseComponent,
  canActivate: [AuthGuard]
},
{
  path: 'report/supplierreport',
  component: ReportsupplierComponent,
  canActivate: [AuthGuard]
},
{
  path: 'report/stocktransferreport',
  component: ReportstocktransferComponent,
  canActivate: [AuthGuard]
},
{
  path: 'expense/expensetype',
  component: ExpensetypeComponent,
  canActivate: [AuthGuard]
},
{
  path: 'report/expensereportbydate',
  component: DatewisereportComponent,
  canActivate: [AuthGuard]
},
{
  path: 'report/expensereportbybranch',
  component: BranchwisereportComponent,
  canActivate: [AuthGuard]
},
{
  path: 'expense/expenseentry',
  component: MiscexpenseallComponent,
  canActivate: [AuthGuard]
},
{
  path: 'expense/expenseentry/add',
  component: MiscexpenseaddComponent,
  canActivate: [AuthGuard]
},
{
  path: 'expense/expenseentry/edit',
  component: MiscexpenseeditComponent,
  canActivate: [AuthGuard]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
