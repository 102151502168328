
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Purchase Order List</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Purchase Order &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Purchase Order List</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="tabbable-line">
								<!-- <ul class="nav nav-tabs">
                                    <li class="active">
                                        <a href="#tab1" data-toggle="tab"> List View </a>
                                    </li>
                                    <li>
                                        <a href="#tab2" data-toggle="tab"> Grid View </a>
                                    </li>
                                </ul> -->
								<a id="auto_trigger" href="/purchaseorder/edit"></a>
								<ul class="nav customtab nav-tabs" role="tablist">
									<!-- <li class="nav-item"><a href="#tab1" class="nav-link active" data-toggle="tab">List
											View</a></li>
									<li class="nav-item"><a href="#tab2" class="nav-link" data-toggle="tab">Grid
											View</a></li> -->
								</ul>
								<div class="tab-content">
									<div class="tab-pane active fontawesome-demo" id="tab1">
										<div class="row">
											<div class="col-md-12">
												<div class="card card-box">
													<div class="card-head">
														<header>All Headers</header>
														<div class="tools">
															<a class="fa fa-repeat btn-color box-refresh"
																href="javascript:;"></a>
															<a class="t-collapse btn-color fa fa-chevron-down"
																href="javascript:;"></a>
															<a class="t-close btn-color fa fa-times"
																href="javascript:;"></a>
														</div>
													</div>
													<div class="card-body ">
														<div class="row">
															<div class="col-md-6 col-sm-6 col-6">
																<div class="btn-group">
																	<a href="purchaseorder/add" id="addRow"
																		class="btn btn-info">
																		Add New <i class="fa fa-plus"></i>
																	</a>&nbsp; &nbsp;
																	<a href="purchaseorder/customedit" id="addRow"
																		class="btn btn-danger">
																		Custom Edit <i class="fa fa-pencil"></i>
																	</a>
																</div>
															</div>
															<div class="col-md-6 col-sm-6 col-6">
																<div class="btn-group pull-right">
																	<!-- <a class="btn deepPink-bgcolor  btn-outline dropdown-toggle"
																		data-toggle="dropdown">Tools
																		<i class="fa fa-angle-down"></i>
																	</a>
																	<ul class="dropdown-menu pull-right">
																		<li>
																			<a href="javascript:;">
																				<i class="fa fa-print"></i> Print </a>
																		</li>
																		<li>
																			<a href="javascript:;">
																				<i class="fa fa-file-pdf-o"></i> Save as
																				PDF </a>
																		</li>
																		<li>
																			<a href="javascript:;">
																				<i class="fa fa-file-excel-o"></i>
																				Export to Excel </a>
																		</li>
																	</ul> -->
																</div>
															</div>
														</div>
														<div class="table-scrollable">
															<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" 	class="table table-striped table-bordered table-hover table-checkable order-column valign-middle btn-sweetalert"
																>
																<thead>
																	<tr>
																		<th>#</th>
																		<th> Invoice Number </th>
																		<th> Purchase Date </th>
																		<th> Total Amount </th>
																		<th> Supplier </th>
																		<th> Action </th>
																	</tr>
																</thead>
																<tbody>
																	<tr class="odd gradeX" *ngFor="let item of itemsList; let i = index">
																		<td class="patient-img">
																			{{i+1}}
																		</td>
																		<td class="left">{{item.invoice_no}}</td>
																		<td class="left">{{item.purchase_date | date: 'dd-MM-yyyy'}}</td>
																		<td class="left"> {{item.total_amount}} </td>
																		<td class="left"> {{item.supplier.s_name}} </td>
																		<td>
																			<button type="button" class="btn btn-primary btn-xs" (click)="editPurchaseOrder(item.id)">
																				<i class="fa fa-pencil"></i>
																			</button>
																			<!-- <button type="button" *ngIf="item.status == 1" class="btn btn-success btn-xs" (click)="changeStatus(item.id, item.status)">
																				<i class="fa fa-eye "></i>
																			</button>
																			<button type="button" *ngIf="item.status == 0" class="btn btn-secondary btn-xs" (click)="changeStatus(item.id, item.status)">
																				<i class="fa fa-eye-slash "></i>
																			</button> -->
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="tab-pane" id="tab2">
										<div class="row">
											<div class="col-md-4" *ngFor="let item of itemsList; let i = index">
												<div class="card card-box">
													<div class="card-body no-padding ">
														<div class="doctor-profile">
															<img src="../../../assets/images/logo4.png" class="doctor-pic"
																alt="">
															<div class="profile-usertitle">
																<div class="doctor-name">{{ item.first_name}} {{item.last_name}} </div>
																<div class="name-center"> {{ item.operator_type }} </div>
															</div>
															<p>{{item.address}} <br /> {{item.dist}} <br /> {{item.state}} <br/> {{item.pin_code}}</p>
															<div>
																<p><i class="fa fa-phone"></i><a
																		href="tel:{{item.mobile_no}}"> {{item.mobile_no}}</a></p>
															</div>
															<div class="profile-userbuttons">
																<a href="#"
																	class="btn btn-circle deepPink-bgcolor btn-sm">Read
																	More</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>

</body>

