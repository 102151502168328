<div class="limiter">
    <div class="container-login100 page-background">
        <div class="wrap-login100">
            <form class="login100-form validate-form" #loginForm="ngForm" (ngSubmit)="onFormSubmit(loginForm)">
                <span class="logo">
                    <img alt="" src="../../../assets/images/logo4.png">
                </span>
                <span class="login100-form-title p-b-34 p-t-27">
                    Log in
                </span>
                <div class="wrap-input100 validate-input" data-validate="Enter Mobile No.">
                    <input class="input100" type="text" name="username" ngModel placeholder="Username">
                    <span class="focus-input100" data-placeholder="&#xf207;"></span>
                </div>
                <div class="wrap-input100 validate-input" data-validate="Enter password">
                    <input class="input100" type="password" name="password" ngModel placeholder="Password">
                    <span class="focus-input100" data-placeholder="&#xf191;"></span>
                </div>
                <div class="container-login100-form-btn">
                    <button class="login100-form-btn" type="submit">
                        Login
                    </button>
                </div>
                <div class="text-center p-t-30">
                    <span (click)="forgotPassword()" class="txt1 mouse-hover" >
                        Forgot Password?
                    </span>
                </div>
            </form>

            <!-- <a #myLink href="/dashboard"></a> -->
        </div>
    </div>
</div>