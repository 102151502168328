import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'twenty20';
  hasToken: boolean = false;

 

  constructor (private authService: AuthService, private router: Router) {
    
    // alert('hello');

}
  // logout() {
  //   this.authService.logoutUser();
  //   this.router.navigate(['home']);
  // }


}
