import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  operatorDetail: any;
  imageURL: string;
  is_mobile_verified: boolean;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { 
    this.operatorDetail = [];
    this. getUserDetail();
    this.is_mobile_verified = false;
  }



  ngOnInit(): void {
  }

  getUserDetail(){
    //loggedInOperatorId
    this.spinner.show();
    this.dataService.GetList('operators/profile').subscribe((data) => {
      const anyData = data as any;
      this.operatorDetail = anyData.operator;
      this.imageURL = 'https://twenty20mvision.com/twenty20/twenty20server/public/'+this.operatorDetail.image_path;
      if(anyData.is_mobile_verified === 1){
        this.is_mobile_verified = true;
      }
      console.log(this.operatorDetail);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  
  }

  getOperatorAddress(){

    localStorage.setItem('activeid', sessionStorage.getItem('loggedInOperatorId'));
    this.router.navigate(['operator/address']);
  }

  updateProfile(){
    this.router.navigate(['profile/edit']);
  }

  verifyMobile(){
    const mobiledata = {
      "first_name" : this.operatorDetail.first_name,
      "mobile_no" : this.operatorDetail.mobile_no
    };
    this.spinner.show();
    this.dataService.Create('send-otp-by-mobile', mobiledata).subscribe((data) => {
      const mobileData = data as any;
      this.spinner.hide();
      if(mobileData.status === 200){
        Swal.fire({
          title: 'Enter OTP',
          text: 'Check your Mobile for OTP',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          timer: 300000,
          timerProgressBar: true,
          showCancelButton: false,
          confirmButtonText: 'Verify',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          preConfirm: (otp) => {
            // this.dataService.GetList
            const otpdata = {
              "mobile_no" : this.operatorDetail.mobile_no,
              "otp" : otp
            };
            this.spinner.show();
            this.dataService.Create('verify-mobile-by-otp', otpdata).subscribe((data) => {
              const otpData = data as any;
              this.spinner.hide();
              if(otpData.status === 200){
                  const datatosend = {
                    "mobile_no" : this.operatorDetail.mobile_no
                  };            
                this.spinner.show();
                this.dataService.ProfileUpdate('operators/update-mobile', datatosend).subscribe((data) => {
                  this.spinner.hide();
                  const anyData = data as any;
                  if(anyData.status === 200){
                    Swal.fire({
                      icon: 'success',
                      title: 'Success',
                      text: 'Successfully Changed'
                    });
                  }else{
                    Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: anyData.error[Object.keys(anyData.error)[0]]
                    });
                  }              
                }, error => {
                  this.spinner.hide();
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Network Error, Please try again'
                  });
                });
              }else{
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: otpData.error[Object.keys(otpData.error)[0]]
                });
              }
            }, error => {
              this.spinner.hide();
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Network Error, Please try again'
              });
            });
          }                
        })
      }else{        
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: mobileData.error[Object.keys(mobileData.error)[0]]
        });  
      }
    }, error => {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Network Error, Please try again'
      });
    });

  }

  changeMobile(){
    this.dataService.ChangeEmailMobile(
      'send-otp-by-mobile',
      'verify-mobile-by-otp',
      'operators/update-mobile',
      '',
      this.operatorDetail.first_name,
      'Enter Valid Mobile Number',
      'An OTP will be send to this Number',
      'text',
      'change'
    );

  }

  changeEmail(){
    this.dataService.ChangeEmailMobile(
      'send-otp-by-email',
      'verify-email-by-otp',
      'operators/update-email',
      '',
      this.operatorDetail.first_name,
      'Enter Valid Email Address',
      'An OTP will be send to this email',
      'email',
      'change'
    );

  }

}
