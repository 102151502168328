import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { AuthService } from 'src/app/auth/auth.service';

import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { DataService } from 'src/app/services/data.service';
import { EnvService } from 'src/app/services/env.service';


declare function showDialog3(): any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  invalidCredentialMsg: string;
    username:string;
    password:string;
    retUrl:string="home";
    user_mobile: string;
    datatosend: any;
    datatosend2: any;
    datatosend3: any;
    new_mobile: string;
    operatorDetail: any;

  // username: string;
  // password: string;

  constructor(
    private authService: AuthService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private spinner: NgxSpinnerService, 
    private dataService: DataService,
    private envService: EnvService
    ) {
    // this.spinner.show();
    this.operatorDetail = [];

   }

  //  @ViewChild('myLink') myDiv: ElementRef<HTMLElement>;

  //  triggerFalseClick() {
  //   let el: HTMLElement = this.myDiv.nativeElement;
  //   el.click();
  // }


   ngOnInit() {
    this.activatedRoute.queryParamMap
            .subscribe(params => {
        this.retUrl = params.get('retUrl');
        console.log( 'LoginComponent/ngOnInit '+ this.retUrl);
    });
}

async onFormSubmit(loginForm) {
  this.spinner.show();
  await this.authService.login(loginForm.value.username, loginForm.value.password).then((data) =>{
    if(data){
      // this.spinner.hide();
      this.dataService.GetList('operators/profile').subscribe((data) => {
        const anyData = data as any;
        this.spinner.hide();
        this.operatorDetail = anyData;
        if(this.operatorDetail.status === 200){
          console.log(this.operatorDetail);
        sessionStorage.setItem('branch_id', this.operatorDetail.branch_id);
          const userDetail = {
            "username" : this.operatorDetail.operator.first_name + ' ' + this.operatorDetail.operator.last_name,
            "userrole" : this.operatorDetail.operator.role.alias,
            "userbranch" : this.operatorDetail.operator.branch.branch_name,
            "image_path" : this.operatorDetail.operator.image_path
          }
          console.log(userDetail);
          
          this.dataService.currentItem = userDetail;
          sessionStorage.setItem('currentItem', JSON.stringify(userDetail));
    
           const sidemenubar = {
          "userrole" : userDetail.userrole,
          "userbranch" : userDetail.userbranch,
          "image_path" : userDetail.image_path,
          "sidemenu" : JSON.parse(sessionStorage.getItem('menuTree'))
        }
        console.log(sidemenubar);
        console.log("menubar");

        
        this.dataService.sidemenu = sidemenubar;
        sessionStorage.setItem('sidemenu', JSON.stringify(sidemenubar));
        console.log(sidemenubar);
        this.router.navigateByUrl('home');
        // this.router.navigate(['dashboard']);
        }else{
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Try Again'
            // text: anyData.error[Object.keys(anyData.error)[0]]
          });
        }
        // console.log(this.operatorDetail);
        
      }, error => {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Network Error, Please try again'
        });
      });
    
    // this.triggerFalseClick();
    }
  },
  (err)=>{
    this.spinner.hide();
    console.log(err);
    this.router.navigate( ['login']);
  });

    // if(this.authService.login(loginForm.value.username, loginForm.value.password)){
    //   this.router.navigate( ['dashboard']);
    // }else {
    //   this.router.navigate( ['login']);
    // }
  }


  forgotPassword() {
    Swal.fire({
      title: 'Enter Your Mobile No.',
      text: 'An OTP will be send to your Mobile',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Send OTP',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        this.user_mobile = login;
        
        const datatosend = {
          "first_name" : 'user',
          "mobile_no" : login
        };
        // return fetch('https://twenty20.w3weavers.com/api/send-otp-by-mobile',
        return fetch(this.envService.apiUrl+'send-otp-by-mobile',
          {
            headers : {
              'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE',
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(datatosend)
          }
        )
          .then(response => {
            // console.log(response[0]);
            if (!response.ok) {
              throw new Error(response.statusText+'Check Mobile')
            }
             return response.json()
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {

        Swal.fire({
          title: 'Enter OTP',
          text: 'Check your mobile for OTP',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          timer: 300000,
          timerProgressBar: true,
          showCancelButton: false,
          confirmButtonText: 'Verify',
          showLoaderOnConfirm: true,
          preConfirm: (login) => {
            // this.dataService.GetList
            const datatosend = {
              "mobile_no" : this.user_mobile,
              "otp" : login
            };
            // return fetch('https://twenty20.w3weavers.com/api/verify-mobile-by-otp',
            return fetch(this.envService+'verify-mobile-by-otp',
              {
                headers : {
                  'Access-Control-Allow-Methods': 'POST, GET, PUT, DELETE',
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(datatosend)
              }
            )
              .then(response => {
                if (!response.ok) {
                  throw new Error(response.statusText)
                }
                return response.json()
              })
              .catch(error => {
                Swal.showValidationMessage(
                  `Request failed: ${error}`
                )
              })
          },
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl('resetpassword',{ state: { mobile: this.user_mobile } });
          }
        })
      }
    })
  }




}
