import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

declare function showDialog7(): any;
declare function showDialog6(): any;
declare function showDialog3(): any;
declare function deleteRecord(url, id): any;

@Component({
  selector: 'app-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss']
})
export class OperatorComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;

  //  dtOptions: DataTables.Settings = {};
  // persons: Person[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  public loading = false;

  itemsList: any;
  addrList: any;
  operatorid: string;
  sub: any;

  constructor(
    public dataService: DataService,
    private envService: EnvService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute

  ) {


   }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    // this.dtTrigger.next();
    this.itemsList = [];
    this.addrList = [];
    this.getOperatorsList();
    // this.sub = this.route.params.subscribe(params => {
    //   sessionStorage.setItem('activeMenuId', params['id']);
    // });   
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    // this.sub.unsubscribe();
  }
  ngAfterViewInit(): void{
    // this.dtTrigger.next();
  }

  rerender(): void {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //      dtInstance.destroy();
  //      this.dtTrigger.next();     
  //  });
  }


  getOperatorsList(){
    this.spinner.show();
    this.dataService.GetList('operators-all').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.itemsList = anyData.operators;
        console.log(JSON.stringify(this.addrList));
        this.dtTrigger.next();
        this.dtTrigger.unsubscribe();
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
     
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

  getOperatorAddress(operatorid){
    localStorage.setItem('activeid', operatorid);
    this.router.navigate(['operator/address']);
  }


  editOperator(operatorid){
    localStorage.setItem('activeid', operatorid);
    this.router.navigate(['operator/update']);
  }


  deleteOperator(operator_id, status){
    let new_status;
    if(status === 1){
      new_status = 0;
    }else{
      new_status = 1;
    }
    const datatosend = {
      "operator_id" : operator_id,
      "status" : new_status
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are going to change status!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!',
    }).then((result) => {
      // alert(result.value);
      if (result.value) {
        this.spinner.show();
        this.dataService.Create('operators/operator/update-status', datatosend).subscribe((data) => {
          this.spinner.hide();
          // showDialog6();
          this.getOperatorsList();
        }, error => {
          this.spinner.hide();
          showDialog3();
        });

      }
    });
  }

}
