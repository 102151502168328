
<body	class="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Profile</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Operators &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active">Profile</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<!-- BEGIN PROFILE SIDEBAR -->
							<div class="profile-sidebar col-md-6">
								<div class="card card-topline-aqua">
									<div class="card-body no-padding height-9">
										<div class="row">
											<div class="profile-userpic">
												<img style="height: 200px; width:200px;" src="{{imageURL}}" class="img-responsive" alt=""> </div>
										</div>
										<div class="profile-usertitle">
											<div class="profile-usertitle-name"> {{operatorDetail.first_name}} {{operatorDetail.last_name}} </div>
											<!-- <div class="profile-usertitle-job"> Jr. Professor </div> -->
										</div>
										<ul class="list-group list-group-unbordered">
											<li class="list-group-item">
												<b>Email: </b> 
												<span class="mouse-hover" (click)="changeEmail()" style="background: #009c00; padding: 2px 5px; color: #fff; margin-left:10px; border-radius: 2px; float: right;"><i class="fa fa-edit" aria-hidden="true"></i> Change</span>
												<a class="pull-right">{{operatorDetail.email}}</a>
											</li>
											<li class="list-group-item">
												<b>Mobile: </b> 
												<span class="mouse-hover" (click)="changeMobile()" style="background: #009c00; margin-left: 10px; float: right; padding: 2px 5px; color: #fff; border-radius: 2px;"><i class="fa fa-edit" aria-hidden="true"></i> Change</span><span *ngIf="is_mobile_verified"><i class="fa fa-check" style="color: #00ff00; margin-left: 10px; float:right;" aria-hidden="true"></i></span><span class="mouse-hover" (click)="verifyMobile()"  *ngIf="!is_mobile_verified" style="background: #ff7300; margin-left: 10px; padding: 2px 5px; color: #fff; float:right; border-radius: 2px;">Verify</span>
												<a class="pull-right">{{operatorDetail.mobile_no}}</a>
											</li>
											<li class="list-group-item">
												<b>DOB:</b> <a class="pull-right">{{operatorDetail.dob | date: 'dd-MM-yyyy'}}</a>
                                            </li>
                                            <li class="list-group-item">
												<b>Gender:</b> <a class="pull-right">{{operatorDetail.gender}}</a>
											</li>
										</ul>
										<!-- END SIDEBAR USER TITLE -->
										<!-- SIDEBAR BUTTONS -->
										<div class="profile-userbuttons">
											<!-- <button type="button" (click)="getOperatorAddress()"
												class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary">Addresses</button>&nbsp; -->
											<button type="button" (click)="updateProfile()"
												class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-pink">Update</button>
										</div>
										<!-- END SIDEBAR BUTTONS -->
									</div>
								</div>
								
							</div>
							<!-- END BEGIN PROFILE SIDEBAR -->
							
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>
</body>

