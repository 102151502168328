import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

declare function showDialog6(): any;
declare function showDialog3(): any;

@Component({
  selector: 'app-productsubcategoryadd',
  templateUrl: './productsubcategoryadd.component.html',
  styleUrls: ['./productsubcategoryadd.component.scss']
})
export class ProductsubcategoryaddComponent implements OnInit {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  itemsList: any;
  categoryid: any;
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
  ) {
    this.getProductCategoriesList();
   }

  ngOnInit(){
  }

  getProductCategoriesList(){
    this.spinner.show();
    this.dataService.GetList('productcategories').subscribe((data) => {
      const anyData = data as any;
      this.itemsList = anyData.categories;
      // this.dtTrigger.next();
      this.spinner.hide();
    }, error => {
      showDialog3();
      this.spinner.hide();
    });
  }

  // public saveCode(e): void {
  //   let name = e.target.value;
  //   let list = this.itemsList.filter(x => x.category_name === name)[0];
  //   console.log(list.id);
  //   this.categoryid = list.id;
  // }



  onSubmit(form: NgForm) {
    const datatosend = {
      "category_id": form.value.category_id,
      "sub_category_name":form.value.subcategory_name
    }
    this.spinner.show();
        this.dataService.Create('productsubcategories',datatosend).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
              if(anyData.status === 200){
                form.reset();
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Product Subcategory Created Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
        }, error => {
          this.spinner.hide();
          showDialog3();
        });
    //  alert(JSON.stringify(datatosend));

  }



}
