import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, NgForm, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-purchaseordercustomedit',
  templateUrl: './purchaseordercustomedit.component.html',
  styleUrls: ['./purchaseordercustomedit.component.scss']
})
export class PurchaseordercustomeditComponent implements OnInit {

  productForm: FormGroup;
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  itemsList: any;
  purchaseorderDetail: any;
  purchaseorderItems: any;
  gstList: any;
  purchase_header_id: any;

  // invoice_no: any;
  // purchase_date: any;
  // total_amount: any;
  // supplier_id: any;
  invoice_no = new FormControl('');
  purchase_date = new FormControl('');
  total_amount = new FormControl('');
  supplier_id = new FormControl('');

  
  supplierList: any;
  selectedKeywordList: any =[];
  selectedProductList: any = [];
  productsList: any = [];
  headerList: any = [];
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.productForm = this.fb.group({
      // invoice_no: '',
      // purchase_date: '',
      // total_amount: '',
      // supplier_id: '',
      products: this.fb.array([])
    });
    this.supplierList = [];
    this.itemsList = [];
    this.purchaseorderDetail = [];
    this.purchaseorderItems = [];
    this.gstList = [];
    this.getSuppliersList();
    this.getProductsList();
    // this.getPurchaseorderDetails();
    this.getGSTList();
    this.getProductByKeyword();
   }

  ngOnInit(): void {
  }

  getGSTList(){
    this.dataService.GetList('operators/get-gst-types').subscribe((data) => {
      const anyData = data as any;
      this.gstList = anyData.taxes;
    });
  }

  getSuppliersList(){
    this.dataService.GetList('phorder/get-suppliers-for-stock-entry').subscribe((data) => {
      const anyData = data as any;
      this.supplierList = anyData.suppliers;
    });
    // alert(id);
  }

  products() : FormArray {
    // return this.productForm.get("quantities") as FormArray
    return this.productForm.get("products") as FormArray
  }

  // addItem() {
  //   this.products().push(this.dataService.newProduct());
  // }

  // removeItem(i:number) {
  //   this.products().removeAt(i);
  // }

  getProductByKeyword(){
    this.dataService.GetList('get-product-list-for-custom-edit').subscribe((data) => {
      const anyData = data as any;
      this.selectedKeywordList = anyData.products;
    }, error => {
      // this.spinner.hide();
    });
}

getSelectedProductByKeyword(product_name){ 
  this.products().clear();
  this.headerList = []
  this.invoice_no.setValue('');
  //this.datePipe.transform(this.userDetails.dob, 'yyyy-MM-dd')
  this.purchase_header_id = null;
  this.purchase_date.setValue('');
  this.total_amount.setValue(0);
  this.supplier_id.setValue('');
  // this.productsList = [];
    for(let i=0; i<this.selectedKeywordList.length; i++){
      if(this.selectedKeywordList[i].name === product_name){
        // this.selectedProductId = this.selectedKeywordList[i].id;
        this.spinner.show()
        this.dataService.GetDetails('get-purchase-order-by-product-id/', this.selectedKeywordList[i].id).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
          this.selectedProductList = anyData.purchase_order;
          if(this.selectedProductList.length !== 0){
            for(let i = 0; i < this.selectedProductList.length; i++){
              for(let j = 0; j < this.selectedProductList[i].phorder.length; j++){
                // if(this.selectedProductList[i].phorder[j].stock !== null){
                  this.headerList.push(this.selectedProductList[i].phorder[j]);
                // }
              }
            }
          }else{
            Swal.fire({
              icon: 'warning',
              title: 'Alert',
              text: 'Out of Stock!'
            });
          }
          
          
         
        }, error => {
          this.spinner.hide();
        });
      }
    }

  
}

editItem(index_no){
  this.products().clear();
  // alert(index_no);
  // if(this.selectedProductList[].phorder[0].stock !== null){
    this.invoice_no.setValue(this.selectedProductList[0].phorder[index_no].phheader.invoice_no);
    //this.datePipe.transform(this.userDetails.dob, 'yyyy-MM-dd')
    this.purchase_header_id = this.selectedProductList[0].phorder[index_no].phheader.id;
    this.purchase_date.setValue(this.datePipe.transform(this.selectedProductList[0].phorder[index_no].phheader.purchase_date, 'yyyy-MM-dd'));
    this.total_amount.setValue(0);
    this.supplier_id.setValue(this.selectedProductList[0].phorder[index_no].phheader.supplier_id);
    const producttoshow =new FormGroup({
      "id" : new FormControl(this.selectedProductList[0].phorder[index_no].id),
      "product_id" : new FormControl(this.selectedProductList[0].phorder[index_no].product_id),
      "unit_price" : new FormControl(this.selectedProductList[0].phorder[index_no].unit_price),
      "sale_price" : new FormControl(this.selectedProductList[0].phorder[index_no].sale_price),
      "fixed_discount" : new FormControl(this.selectedProductList[0].phorder[index_no].fixed_discount),
      "bv" : new FormControl(this.selectedProductList[0].phorder[index_no].bv),
      "gst_percent" : new FormControl(this.selectedProductList[0].phorder[index_no].gst_id),
      "quantity" :new FormControl(this.selectedProductList[0].phorder[index_no].quantity)
    });
    // this.productsList.push(producttoshow);
    this.products().push(producttoshow);
  //  }else{
  //   Swal.fire({
  //     icon: 'warning',
  //     title: 'Alert',
  //     text: 'Sold Out!'
  //   });
  //  }
}

  getProductsList(){
    this.spinner.show();
    this.dataService.GetList('products').subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.itemsList = anyData.products;
      
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
    }, error => {

      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Network Error, Please Try Again.'
      });
    });
  }

  getPurchaseorderDetails(){
    this.dataService.GetDetails('purchaseorders/', localStorage.getItem('activeid')).subscribe((data) => {
      const anyData = data as any;
      this.purchaseorderDetail = anyData.purchase_header[0];
      this.invoice_no.setValue(this.purchaseorderDetail.invoice_no);
      //this.datePipe.transform(this.userDetails.dob, 'yyyy-MM-dd')
      this.purchase_date.setValue(this.datePipe.transform(this.purchaseorderDetail.purchase_date, 'yyyy-MM-dd'));
      this.total_amount.setValue(this.purchaseorderDetail.total_amount);
      this.supplier_id.setValue(this.purchaseorderDetail.supplier_id);
      this.purchaseorderItems = this.purchaseorderDetail.phorder;
      for(let i = 0; i < this.purchaseorderItems.length; i++) {
        if(this.purchaseorderItems[i].stock !== null){
          const newarray = new FormGroup({
            "id" : new FormControl(this.purchaseorderItems[i].id),
            "product_id" : new FormControl(this.purchaseorderItems[i].product_id),
            "unit_price" : new FormControl(this.purchaseorderItems[i].unit_price),
            "sale_price" : new FormControl(this.purchaseorderItems[i].sale_price),
            "fixed_discount" : new FormControl(this.purchaseorderItems[i].fixed_discount),
            "bv" : new FormControl(this.purchaseorderItems[i].bv),
            "gst_percent" : new FormControl(this.purchaseorderItems[i].gst_id),
            "quantity" :new FormControl(this.purchaseorderItems[i].quantity)
            // "previousquantity" : new FormControl(this.purchaseorderItems[i].quantity)
          });
          this.products().push(newarray);
        }
      } 
      // console.log(this.itemList);
    });
  }

  onSubmit() {
    const datatosend = {
      "purchase_header":{
        "invoice_no": this.invoice_no.value,
        "purchase_date": this.purchase_date.value,
        "total_amount": this.total_amount.value,
        "supplier_id": this.supplier_id.value,
      },
      "purchase_list": this.productForm.value.products
    }
    console.log(datatosend);

    // console.log(this.productForm.value);
      this.spinner.show();
        this.dataService.Edit('purchaseorders/', datatosend, this.purchase_header_id).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
              if(anyData.status === 200){
                this.products().clear();
                this.invoice_no.setValue('');
              //this.datePipe.transform(this.userDetails.dob, 'yyyy-MM-dd')
              this.purchase_header_id = null;
              this.purchase_date.setValue('');
              this.total_amount.setValue(0);
              this.supplier_id.setValue('');
              this.headerList = [];
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Record Updated Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
        }, error => {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Network Error, Please Try Again.'
          });
        });
  }

}
