
	<div class="page-wrapper">
		<!-- start header -->
		<app-header [item]="currentItem"></app-header>
		<!-- end header -->
		<!-- start color quick setting -->
		<!-- end color quick setting -->
		<!-- start page container -->
		<div class="page-container">
			<!-- start sidebar menu -->
			<app-sidemenu [sidemenubar]="sidemenu"></app-sidemenu>
			<!-- end sidebar menu -->
			<!-- start page content -->
			<div class="page-content-wrapper">
                <div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">{{reportName}} Report</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li class="active"><i class="fa fa-home"></i>&nbsp; Home &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> Report &nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active"> {{reportName}} Report</li>
							</ol>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="tabbable-line">
								<ul class="nav customtab nav-tabs" role="tablist">
								</ul>
								<div class="tab-content">
									<div class="tab-pane active fontawesome-demo" id="tab1">
										<div class="row">
											<div class="col-md-12">
												<div class="card card-box">
													<div class="card-head">
														<header>All Records</header>
														<div class="tools">
															<a class="fa fa-repeat btn-color box-refresh"
																href="javascript:;"></a>
															<a class="t-collapse btn-color fa fa-chevron-down"
																href="javascript:;"></a>
															<a class="t-close btn-color fa fa-times"
																href="javascript:;"></a>
														</div>
													</div>
													<div class="card-body ">
														<div class="row">
															<div class="col-md-12 col-sm-12 col-lg-12 col-sx-12 col-12">
																<div class="">
																	<table class="col-md-12 col-sm-12 col-lg-12 col-sx-12 col-12">
																		<tr>
																			<td class="col-md-4 col-sm-4 col-lg-4 col-sx-4 col-4">
                                                                                <p *ngIf="pageurl != '/workprogressreport'">  
                                                                                    <label for="name">Branch Name:</label>
                                                                                    <select class="form-control input-height" id="branch_id" name="branch_id" [(ngModel)]="branch_id">
                                                                                        <option value="">Select...</option>
                                                                                        <option *ngFor="let item of branchList; let i = index" value="{{item.id}}">{{item.branch_name}}</option>
                                                                                    </select>
                                                                                  </p>
                                                                            </td>
																			<td class="col-md-4 col-sm-4 col-lg-4 col-sx-4 col-4">
                                                                                <button *ngIf="pageurl != '/workprogressreport'" class="btn btn-primary" (click)="getItemsList()">Search</button>
                                                                            </td>
                                                                        <td class="col-md-4 col-sm-4 col-lg-4 col-sx-4 col-4">
                                                                            <div class="btn-group pull-right">
                                                                                <a class="btn deepPink-bgcolor  btn-outline dropdown-toggle"
                                                                                    data-toggle="dropdown">Tools
                                                                                    <i class="fa fa-angle-down"></i>
                                                                                </a>
                                                                                <ul class="dropdown-menu pull-right">
                                                                                    <li>
                                                                                        <a href="javascript:;" (click)="printDiv('printableArea')">
                                                                                            <i class="fa fa-print"></i> Print/Save as PDF </a>
                                                                                    </li>
                                                                                    <!-- <li>
                                                                                        <a href="javascript:;" (click)="exportAsPDF('exportTable');">
                                                                                            <i class="fa fa-file-pdf-o"></i> Save as
                                                                                            PDF </a>
                                                                                    </li> -->
                                                                                    <li>
                                                                                        <a href="javascript:;" (click)="exportToExcel()">
                                                                                            <i class="fa fa-file-excel-o"></i>
                                                                                            Export to Excel </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </td></tr>
																	</table>
																</div>
															</div>
														</div>
														<div class="table-scrollable">
															<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" 	class="table table-striped table-bordered table-hover table-checkable order-column valign-middle btn-sweetalert">
																<thead>
																	<tr>
																		<th *ngFor="let item of headerList; let i = index">{{item}}</th>
																	</tr>
																</thead>
																<tbody>
																	<tr class="odd gradeX" *ngFor="let item of dataForView; let i = index">
																		<td *ngFor="let data of item; let j = index" class="left">{{data}}</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			<!-- end page content -->
			<!-- start chat sidebar -->

            <div id="printableArea" style="visibility: hidden;">
				<p style="text-align: center; padding: 10px;"><strong>{{reportName}} Report</strong></p>
                <table style="border: solid thin #666; border-collapse: collapse; text-align: center;">
                    <tr>
                        <th style="border: solid thin #666; padding: 5px; height: 40px;" *ngFor="let item of headerList; let i = index">{{item}}</th>
                    </tr>
                    <tr *ngFor="let item of dataForView; let i = index">
                        <td *ngFor="let data of item; let j = index" style="border: solid thin #666; padding: 5px; height: 35px;">{{data}}</td>
                    </tr>
                </table>                                                            
            </div>
			<!-- end chat sidebar -->
		</div>
		<!-- end page container -->
		<!-- start footer -->
		<app-footer></app-footer>
		<!-- end footer -->
	</div>


