import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
 
  apiUrl = 'https://twenty20.twenty20mvision.com/api/';
  

  constructor() {
    
   }
}
