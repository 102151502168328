import { DataService } from './../../services/data.service';

import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-purchaseheaderadd',
  templateUrl: './purchaseheaderadd.component.html',
  styleUrls: ['./purchaseheaderadd.component.scss']
})
export class PurchaseheaderaddComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;

  invoice_no = new FormControl('');
  purchase_date = new FormControl('');
  supplier_id = new FormControl('');
  total_amount = new FormControl('');

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

}
