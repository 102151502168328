import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;

  dtOptions: DataTables.Settings = {};
  // persons: Person[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  public loading = false;

  itemsList: any;
  operatorid: string;

  constructor(
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router

  ) {


   }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.itemsList = [];
    this.getKYCList();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getKYCList(){
    this.spinner.show();
    this.dataService.GetList('operators/users').subscribe((data) => {
      const anyData = data as any;
      this.itemsList = anyData.operators;
      this.dtTrigger.next();
      this.spinner.hide();
    }, error => {
      // showDialog3();
      this.spinner.hide();
    });
  }




}
