import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/observable/of';
// import 'rxjs/add/operator/map';
// import { of } from 'rxjs';
import { Router } from '@angular/router';
import { EnvService } from '../services/env.service';
import { DataService } from '../services/data.service';

import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isloggedIn: boolean;
    private userName: string;
    hasToken: boolean;
    operatorDetail: any;
    currentItem: any;

  constructor(private router: Router, envService: EnvService, private spinner: NgxSpinnerService, private dataService: DataService) {
    this.isloggedIn = false;
    this.operatorDetail = [];
   }


  async login(username: string, password: string) {
    const logindata = {
      'mobile_no' : username,
      'password' : password
    };
    this.spinner.show();
    await this.dataService.SendLoginData(logindata).toPromise().then(data =>{
      const obj = JSON.stringify(data);
      // alert(JSON.stringify(data));
      const parseObj = JSON.parse(obj);
      if(parseObj.status === 200){
        this.spinner.hide();
        this.isloggedIn = true;
        sessionStorage.setItem('token', parseObj.token);
        sessionStorage.setItem('isUserloggedIn', 'loggedin');
        sessionStorage.setItem('loggedInOperatorId', parseObj.id);
        sessionStorage.setItem('menuTree', JSON.stringify(parseObj.menuTree));

        // this.dataService.GetList('operators/profile').subscribe((data) => {
        //   const anyData = data as any;
        //   // this.spinner.hide();
        //   this.operatorDetail = anyData;
        //   if(this.operatorDetail.status === 200){
        //     const userDetail = {
        //       "username" : this.operatorDetail.operator[0].first_name + ' ' + this.operatorDetail.operator[0].last_name,
        //       "userrole" : this.operatorDetail.operator[0].role.role_name
        //     }
        //     console.log(userDetail);
            
        //     this.dataService.currentItem = userDetail;
        //     sessionStorage.setItem('currentItem', JSON.stringify(userDetail));
      
        //      const sidemenubar = {
        //     "userrole" : userDetail.userrole,
        //     "sidemenu" : parseObj.menuTree
        //   }
        //   console.log(sidemenubar);
        //   console.log("menubar");

          
        //   this.dataService.sidemenu = sidemenubar;
        //   sessionStorage.setItem('sidemenu', JSON.stringify(sidemenubar));
        //   console.log(sidemenubar);
        //   }else{
        //     Swal.fire({
        //       icon: 'error',
        //       title: 'Error',
        //       text: anyData.error[Object.keys(anyData.error)[0]]
        //     });
        //   }
        //   // console.log(this.operatorDetail);
          
        // }, error => {
        //   // this.spinner.hide();
        //   Swal.fire({
        //     icon: 'error',
        //     title: 'Error',
        //     text: 'Network Error, Please try again'
        //   });
        // });

        // this.dataService.sidemenu = parseObj.menuTree;
        console.log(parseObj.menuTree);
        
        // sessionStorage.setItem('sideMenu', JSON.stringify(parseObj.menuTree));
       
        // alert(parseObj.menuTree[0].id);
        // sessionStorage.setItem('activeMenuId', parseObj.menuTree[0].id);
      
      

      
        // this.getUserDetail();

      } else{        
        this.spinner.hide();
        this.isloggedIn = false;
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: parseObj.error[Object.keys(parseObj.error)[0]]
        });
      }
    }, (err)=>{
      this.spinner.hide();
      this.isloggedIn = false;
    });
    return this.isloggedIn;
  }


  isUserLoggedIn(): boolean {
    return this.isloggedIn;
  }

isAdminUser(): boolean {
    // tslint:disable-next-line: triple-equals
    if (this.userName == 'Admin') {
        return true;
    }
    return false;
}

logoutUser(): void{
    this.isloggedIn = false;
}

getUserDetail(){
  //loggedInOperatorId
  this.spinner.show();
  this.dataService.GetList('operators/profile').subscribe((data) => {
    const anyData = data as any;
    this.spinner.hide();
    this.operatorDetail = anyData;
    if(this.operatorDetail.status === 200){
      const userDetail = {
        "username" : this.operatorDetail.operator[0].first_name + ' ' + this.operatorDetail.operator[0].last_name,
        "userrole" : this.operatorDetail.operator[0].role.role_name
      }
     
      this.currentItem = userDetail;
      sessionStorage.setItem('currentItem', JSON.stringify(userDetail));

       const sidemenubar = {
      "userrole" : JSON.parse(sessionStorage.getItem('currentItem')).userrole,
      "sidemenu" : JSON.parse(sessionStorage.getItem('sideMenu'))
    }
    this.dataService.sidemenu = sidemenubar;
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: anyData.error[Object.keys(anyData.error)[0]]
      });
    }
    // console.log(this.operatorDetail);
    
  }, error => {
    this.spinner.hide();
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Network Error, Please try again'
    });
  });

}
}
