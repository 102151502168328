import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-stockrequestdetail',
  templateUrl: './stockrequestdetail.component.html',
  styleUrls: ['./stockrequestdetail.component.scss']
})
export class StockrequestdetailComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  itemsList: any;
  newitemsList: any;
  isPending: boolean = false;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    
    this.itemsList = [];
    this.newitemsList = [];
    this.getDetails(localStorage.getItem('activeid'));
    if(sessionStorage.getItem('requeststatus') === 'pending'){
      this.isPending = true;
    }
  }

  ngOnInit(): void {
  }

  
  getDetails(id){
    this.spinner.show();
    this.dataService.GetDetails('operators/get-stock-transfer-detail-by-id/', id).subscribe((data) => {
      this.spinner.hide();
      const anyData = data as any;

      this.itemsList = anyData.stock_transfer_detail.stockorderdetail;
      console.log(this.itemsList);
      for(let i=0; i<this.itemsList.length; i++){
        const itemdata = {
          "product_name" : this.itemsList[i].stock.phorder.product.product_name,
          "hsn_code" : this.itemsList[i].stock.phorder.product.hsn_code,
          "qty" : this.itemsList[i].quantity,
          "unit_price" : this.itemsList[i].stock.phorder.unit_price,
          "tax_percent" : this.itemsList[i].stock.phorder.tax.tax_percent,
          "total_price" : (Math.floor((Number(this.itemsList[i].stock.phorder.unit_price)+(Number(this.itemsList[i].stock.phorder.unit_price*(Number(this.itemsList[i].stock.phorder.tax.tax_percent)/100)))*Number(this.itemsList[i].quantity))*100)/100)
        }
  
        this.newitemsList.push(itemdata);
      }
      

      
     
    }, error =>{
      this.spinner.hide();
      // showDialog3();
    });
  }

  rejectStockRequest(){
    Swal.fire({
      icon: 'error',
      title: 'Reject',
      text: 'Remarks',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: (toverify) => {          
        const datatosend = {
          "description" : toverify
        }      
     
        this.spinner.show();
        this.dataService.CreateWithID('reject-stock/', datatosend, localStorage.getItem('activeid')).subscribe((data) => {
          const anyData = data as any;
          this.spinner.hide();
          if(anyData.status === 200){
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Stock Transfer Request Rejected Successfully.'
            });
            this.isPending = false;
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: anyData.error[Object.keys(anyData.error)[0]]
            });
          }
        }, error => {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Network Error, Please Try Again.'
          });
        });

      }
    });

  }

  acceptStockRequest(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Accept it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.dataService.GetDetails('create-stock-for-accepted-request/', localStorage.getItem('activeid')).subscribe((data) => {
          const anyData = data as any;
          this.spinner.hide();
          if(anyData.status === 200){
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Stock Transfer Request Accepted Successfully.'
            });
            this.isPending = false;
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: anyData.error[Object.keys(anyData.error)[0]]
            });
          }
        }, error => {
          this.spinner.hide();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Network Error, Please Try Again.'
          });
        });
      }
    })



  
    
  }

}
