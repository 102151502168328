import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras } from '@angular/router';
import { EnvService } from 'src/app/services/env.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-operatoraddress',
  templateUrl: './operatoraddress.component.html',
  styleUrls: ['./operatoraddress.component.scss']
})
export class OperatoraddressComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;

  dtOptions: DataTables.Settings = {};
  // persons: Person[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
  itemsList: any;

  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { 
    this.itemsList = [];
    this.getAddressList();
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  editAddress(addressid){
    sessionStorage.setItem('activeid', addressid);
    this.router.navigate(['operator/address/edit']);

  }

  getAddressList(){
    this.spinner.show();
    this.dataService.GetDetails('operators/addresses/', localStorage.getItem('activeid')).subscribe((data) => {
      const anyData = data as any;
      this.itemsList = anyData.addresses;
      // console.log(JSON.stringify(this.addrList));
      this.dtTrigger.next();
      this.spinner.hide();
    }, error => {
      // showDialog3();
      this.spinner.hide();
    });
  }

  deleteAddress(addrid){ 
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.dataService.Delete('operators/addresses/', addrid).subscribe((data) => {
          const anyData = data as any;
          this.spinner.hide();
          if(anyData.status === 200){
            Swal.fire(
              'Deleted!',
              'Your record has been deleted.',
              'success'
            )
            this.getAddressList();
          }else{
            this.spinner.hide();
            Swal.fire(
              'Fail!',
              'Unable to delete Record, Please try again.',
              'error'
            )
          }
        }, error => {
          this.spinner.hide();
          // alert("Please Try Again!");
          Swal.fire({
            icon: 'error',
            title: 'Network Error',
            text: 'Please Try Again'
          });
        });
      }
    })
  }

}
