import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { DatePipe } from '@angular/common';


declare function showDialog6(): any;
declare function showDialog3(): any;
declare function showDialog11(): any;
declare function price_in_words(invoice_amount): any;

@Component({
  selector: 'app-stocktransferhistoryprint',
  templateUrl: './stocktransferhistoryprint.component.html',
  styleUrls: ['./stocktransferhistoryprint.component.scss']
})
export class StocktransferhistoryprintComponent {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  sub: any;

  dtOptions: DataTables.Settings = {};
  // persons: Person[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  public loading = false;

  itemsList: any;
  exportList: any;
  operatorid: string;
  dataForExcel = [];
  newExportList : any = [];
  invoiceDetail: any = [];
  total_net_discount: number = 0;
  total_mrp: number = 0;
  total_net_amount: number = 0;
  total_net_bv: number = 0;
  total_gst: number = 0;
  invoice_no: any;
  order_date: any;
  user_name: any;
  mobile_no: any;
  email: any;
  address: any;
  order_list: any =[];
  

  fromDate: any;
  toDate: any;

  net_total_mrp: number = 0;
  net_total_gst: number = 0;



  constructor(
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    public ete: ExportExcelService,
    private datePipe: DatePipe

  ) {
    this.itemsList = [];
    this.exportList = []
    this.fromDate = '2021-02-01 ';
    this.toDate = '2021-02-22';

   


   }



  ngOnInit(): void {
    this.getDetails();
    
  }

  ngOnDestroy(): void {
   
  }

  ngAfterViewInit(): void{
    // this.dtTrigger.next();
  }

  getDetails(){
    this.spinner.show();
    const datatosend = {
      "branch_stock_order_id" : sessionStorage.getItem('order_id')
    }
    this.dataService.Create('billing/get-stock-transfer-invoice-by-id', datatosend).subscribe((data) => {
      const anyData = data as any;
      this.spinner.hide();
      if(anyData.status === 200){
        this.invoiceDetail = anyData.invoice;
        this.invoice_no = this.invoiceDetail.invoice_no;
        this.total_net_bv = this.invoiceDetail.bv_amount;
        this.total_net_amount = this.invoiceDetail.amount;
        this.order_date = this.invoiceDetail.order_date;
        for(let i=0; i<this.invoiceDetail.stockorderdetail.length; i++){

          const datalist = {
            "description" : this.invoiceDetail.stockorderdetail[i].stock.phorder.product.product_name,
            "hsn_code" : this.invoiceDetail.stockorderdetail[i].stock.phorder.product.hsn_code,
            "gst_percent" : this.invoiceDetail.stockorderdetail[i].stock.phorder.tax.tax_percent,
            "gst_amount" : (Number(this.invoiceDetail.stockorderdetail[i].stock.phorder.unit_price)*(Number(this.invoiceDetail.stockorderdetail[i].stock.phorder.tax.tax_percent)/100)),
            "rate" : this.invoiceDetail.stockorderdetail[i].stock.phorder.unit_price,
            "quantity" : this.invoiceDetail.stockorderdetail[i].quantity,
            "total_amount": ((Number(this.invoiceDetail.stockorderdetail[i].stock.phorder.unit_price)*Number(this.invoiceDetail.stockorderdetail[i].quantity))+((Number(this.invoiceDetail.stockorderdetail[i].stock.phorder.unit_price)*(Number(this.invoiceDetail.stockorderdetail[i].stock.phorder.tax.tax_percent)/100))*Number(this.invoiceDetail.stockorderdetail[i].quantity)))
          }
          this.order_list.push(datalist);
          // this.total_net_discount = this.total_net_discount + (this.invoiceDetail.stockorderdetail[i].stock.phorder.fixed_discount*this.invoiceDetail.stockorderdetail[i].quantity);
          this.total_mrp = this.total_mrp + ((Number(this.invoiceDetail.stockorderdetail[i].stock.phorder.unit_price)*Number(this.invoiceDetail.stockorderdetail[i].quantity)));

          this.total_gst = this.total_gst + (Number(this.invoiceDetail.stockorderdetail[i].stock.phorder.unit_price)*(Number(this.invoiceDetail.stockorderdetail[i].stock.phorder.tax.tax_percent)/100)*Number(this.invoiceDetail.stockorderdetail[i].quantity));

        }
        this.net_total_gst = Math.floor(this.total_gst*100)/100;
        this.net_total_mrp = Math.floor(this.total_mrp*100)/100;

        
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: anyData.error[Object.keys(anyData.error)[0]]
        });
      }
     
    }, error => {
      this.spinner.hide();
    });
  }

  // calculate_discount(amount, id){

  //   alert(id);
  //   this.net_discount = this.net_discount + Number(amount);
  //   alert(this.net_discount);

  // }

  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
  var popupWin = window.open('', '_blank', 'width=1200,height=1200');
  popupWin.document.open();
  popupWin.document.write('<html><head><script type="text/javascript">function PrintWindow(){ window.print(); CheckWindowState();}  function CheckWindowState(){ if(document.readyState=="complete") {  window.close();  }  else  {  setTimeout("CheckWindowState()", 2000)  }  }  </script><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="PrintWindow()">' + printContents + '</body></html>');
  popupWin.document.close();
    // var printContents = document.getElementById(divName).innerHTML;
    // var originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents;

    // window.print();

    // document.body.innerHTML = originalContents;
}
}
