import { DataService } from './../../services/data.service';

import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';


declare function showDialog6(): any;
declare function showDialog3(): any;
declare function showDialog11(): any;

@Component({
  selector: 'app-productadd',
  templateUrl: './productadd.component.html',
  styleUrls: ['./productadd.component.scss']
})
export class ProductaddComponent {
  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  // name = 'Angular';
  categoryList: any;
  category_id: any;
  subcategory_id: any;
  subcategoryList: any;
  brandList: any;
  supplierList: any;
  
  imageurl: any;
  message: string;
  public imagePath;

  productForm = new FormGroup({category_id: new FormControl(), subcategory_id: new FormControl(), product_name: new FormControl(), model_no: new FormControl(), hsn_code: new FormControl(), product_code: new FormControl(), image_path: new FormControl(), description: new FormControl(), brand_id: new FormControl()});

  constructor(private fb:FormBuilder, private dataService: DataService, private spinner: NgxSpinnerService) {


    this.categoryList = [];
    this.subcategoryList = [];
    this.brandList = [];
    this.supplierList = [];
    this.getProductCategoriesList();
    this.getSuppliersList();
    this.getBrandsList();
    // this.addProduct();
  }

  getBrandsList(){
    this.dataService.GetList('brands').subscribe((data) => {
      const anyData = data as any;
      this.brandList = anyData.brands;
    });
    // alert(id);
  }

  getSuppliersList(){
    this.dataService.GetList('suppliers').subscribe((data) => {
      const anyData = data as any;
      this.supplierList = anyData.suppliers;
    });
    // alert(id);
  }

  readURL(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imageurl = reader.result;
      this.productForm.patchValue({
        image_path: reader.result
      });

    }
  }


  getSelectedProductCategory(id: number){
    this.category_id = id;
    this.spinner.show();
    this.dataService.GetDetails('subcategories-by-category/', id).subscribe((data) => {
      const anyData = data as any;
      this.subcategoryList = anyData.subcategories;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
    // alert(id);
  }

  getSelectedProductSubcategory(id: number){
    this.subcategory_id = id;
    // alert(id);
  }

  getProductCategoriesList(){
    this.spinner.show();
    this.dataService.GetList('productcategories').subscribe((data) => {
      const anyData = data as any;
      this.categoryList = anyData.categories;
      // this.dtTrigger.next();
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  products() : FormArray {
    // return this.productForm.get("quantities") as FormArray
    return this.productForm.get("products") as FormArray
  }

  addProduct() {
    this.products().push(this.dataService.newProduct());
  }

  removeProduct(i:number) {
    this.products().removeAt(i);
  }

  onSubmit() {
    // if (this.productForm.valid) {
      // alert('Success');
      // alert(JSON.stringify(this.operatorForm.value));
      console.log(this.productForm.value);
      this.spinner.show();
        this.dataService.Create('products', JSON.stringify(this.productForm.value)).subscribe((data) => {
          this.spinner.hide();
          const anyData = data as any;
              if(anyData.status === 200){
                this.productForm.reset();
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Product Added Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
          // this.getProductDetails(localStorage.getItem('activeid'));
        }, error => {
          this.spinner.hide();
          showDialog3();
        });
    // } else{
    //   showDialog11();
    // }
  }
}
