import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reportstocktransfer',
  templateUrl: './reportstocktransfer.component.html',
  styleUrls: ['./reportstocktransfer.component.scss']
})
export class ReportstocktransferComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  sub: any;

  dtOptions: DataTables.Settings = {};
  // persons: Person[] = [];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();

  public loading = false;

  itemsList: any;
  newitemsList: any =[];
  exportList: any;
  operatorid: string;
  dataForExcel = [];
  newExportList : any = [];
  counter : number;
  curdate: any;
  fromDate: any;
  toDate: any;
  user_type: string = 'mlm';

  empPerformance = [
    { ID: 10011, NAME: "A", DEPARTMENT: "Sales", MONTH: "Jan", YEAR: 2020, SALES: 132412, CHANGE: 12, LEADS: 35, DATE: "18-02-2021" },
    { ID: 10012, NAME: "A", DEPARTMENT: "Sales", MONTH: "Feb", YEAR: 2020, SALES: 232324, CHANGE: 2, LEADS: 443, DATE: "18-02-2021" },
    { ID: 10013, NAME: "A", DEPARTMENT: "Sales", MONTH: "Mar", YEAR: 2020, SALES: 542234, CHANGE: 45, LEADS: 345, DATE: "18-02-2021" },
    { ID: 10014, NAME: "A", DEPARTMENT: "Sales", MONTH: "Apr", YEAR: 2020, SALES: 223335, CHANGE: 32, LEADS: 234, DATE: "18-02-2021" },
    { ID: 10015, NAME: "A", DEPARTMENT: "Sales", MONTH: "May", YEAR: 2020, SALES: 455535, CHANGE: 21, LEADS: 12, DATE: "18-02-2021" },
  ];

  constructor(
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    public ete: ExportExcelService,
    private datePipe: DatePipe

  ) {
    this.itemsList = [];
    this.exportList = []
    this.curdate = new Date();
    this.fromDate = this.curdate.getFullYear()+'-'+this.curdate.getMonth()+'-'+(this.curdate.getDate());
    this.toDate = this.curdate.getFullYear()+'-'+(this.curdate.getMonth()+1)+'-'+(this.curdate.getDate()+1);


   }

 exportToExcel(){
this.counter = 1;
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

    this.spinner.show();
    const datatosend = {
      "from" : this.fromDate,
      "to" : this.toDate
    }
    // if(this.user_type === 'mlm'){
      this.dataService.Create('report/stock-transfer-report', datatosend).subscribe((data) => {
        const anyData = data as any;
        this.spinner.hide();
        if(anyData.status === 200){
          this.dataForExcel = [];
          this.exportList = anyData.stock_transfer_report;
          for(let i=0; i< this.exportList.length; i++){
            for(let j=0; j< this.exportList[i].stockorderdetail.length; j++){
              const datalist = {
                "sr_no" : this.counter++,
                "branch_name" : this.exportList[i].destbranch.branch_name,
                "bill_no" : this.itemsList[i].invoice_no,
                "purchase_date" : this.datePipe.transform(this.exportList[i].order_date, 'dd-MM-yyyy'),
                "brand_name" : this.exportList[i].stockorderdetail[j].stock.phorder.product.brand.brand_name,
                "product_name" : this.exportList[i].stockorderdetail[j].stock.phorder.product.product_name,
                "quantity" : this.exportList[i].stockorderdetail[j].quantity,
                "item_rate" : this.exportList[i].stockorderdetail[j].stock.phorder.unit_price,
                "taxable_value" : Number(this.exportList[i].stockorderdetail[j].stock.phorder.unit_price)*Number(this.exportList[i].stockorderdetail[j].quantity),
                "gst_rate" : this.exportList[i].stockorderdetail[j].stock.phorder.tax.tax_percent,
                "sgst" : Math.floor((((Number(this.exportList[i].stockorderdetail[j].stock.phorder.unit_price)*Number(this.exportList[i].stockorderdetail[j].quantity)*Number(this.exportList[i].stockorderdetail[j].stock.phorder.tax.tax_percent))/100)/2)*100)/100,
                "cgst" : Math.floor((((Number(this.exportList[i].stockorderdetail[j].stock.phorder.unit_price)*Number(this.exportList[i].stockorderdetail[j].quantity)*Number(this.exportList[i].stockorderdetail[j].stock.phorder.tax.tax_percent))/100)/2)*100)/100,
                "igst" : 0,
                "total_price" : Math.floor(((Number(this.exportList[i].stockorderdetail[j].stock.phorder.unit_price)*Number(this.exportList[i].stockorderdetail[j].quantity))+(Number(this.exportList[i].stockorderdetail[j].stock.phorder.unit_price)*Number(this.exportList[i].stockorderdetail[j].quantity)*Number(this.exportList[i].stockorderdetail[j].stock.phorder.tax.tax_percent)/100))*100)/100
              }
              this.newExportList.push(datalist);
            }
          }

          //Original Cost – [Original Cost x {100/(100+GST%)}]
          this.newExportList.forEach((row: any) => {
            this.dataForExcel.push(Object.values(row))
          })
  
          // alert(JSON.stringify(Object.keys(this.exportList[0])));
      
          let reportData = {
            title: 'Stock Transfer Report -' + dd + '-' + mm + '-' + yyyy,
            data: this.dataForExcel,
            headers: ["SR NO.","BRANCH NAME","INVOICE NO.","DATE","BRAND","PRODUCT NAME","QUANTITY","ITEM RATE","TAXABLE VALUE","GST RATE","SGST","CGST","IGST","TOTAL VALUE"]
            // headers: Object.keys(this.exportList[0])
          }
      
          this.ete.exportExcel(reportData);
          // this.dtTrigger.next();
          // this.dtTrigger.unsubscribe();
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: anyData.error[Object.keys(anyData.error)[0]]
          });
        }
       
      }, error => {
        // showDialog3();
        this.spinner.hide();
      });
    // }
  }


  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    this.itemsList = [];
    // this.getItemsList();
    // this.dtTrigger.next();
    // this.sub = this.route.params.subscribe(params => {
    //   sessionStorage.setItem('activeMenuId', params['id']);
    // });   
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    // this.sub.unsubscribe();
  }

  ngAfterViewInit(): void{
    // this.dtTrigger.next();
  }

  viewInvoiceDetail(id){
    sessionStorage.setItem('order_id', id);
    this.router.navigate(['stock/transferhistory/print']);
  }

  getItemsList(){
    this.newitemsList = [];
    this.spinner.show();
    const datatosend = {
      "from" : this.fromDate,
      "to" : this.toDate
    }
    // if(this.user_type === 'mlm'){
      this.dataService.Create('report/stock-transfer-report', datatosend).subscribe((data) => {
        const anyData = data as any;
        this.spinner.hide();
        if(anyData.status === 200){
          this.itemsList = anyData.stock_transfer_report;
          for(let i=0; i< this.itemsList.length; i++){
            for(let j=0; j< this.itemsList[i].stockorderdetail.length; j++){
              const datalist = {
                "branch_name" : this.itemsList[i].destbranch.branch_name,
                "invoice_no" : this.itemsList[i].invoice_no,
                "product_name" : this.itemsList[i].stockorderdetail[j].stock.phorder.product.product_name,
                "purchase_date" : this.itemsList[i].order_date,
                "quantity" : this.itemsList[i].stockorderdetail[j].quantity,
                "unit_price" : Math.floor((Number(this.itemsList[i].stockorderdetail[j].stock.phorder.unit_price))*Number(this.itemsList[i].stockorderdetail[j].quantity)*100)/100
              }
              this.newitemsList.push(datalist);
            }
          }
          this.dtTrigger.next();
          this.dtTrigger.unsubscribe();
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: anyData.error[Object.keys(anyData.error)[0]]
          });
        }
       
      }, error => {
        // showDialog3();
        this.spinner.hide();
      });
    // }
  }
}
