import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

declare function showDialog6(): any;
declare function showDialog3(): any;
declare function showDialog11(): any;

@Component({
  selector: 'app-supplieredit',
  templateUrl: './supplieredit.component.html',
  styleUrls: ['./supplieredit.component.scss']
})
export class SuppliereditComponent implements OnInit {

  sidemenu = this.dataService.sidemenu;
  currentItem = this.dataService.currentItem;
  s_name = new FormControl('');
  c_name = new FormControl('');
  email = new FormControl('');
  mobile_no = new FormControl('');
  mobile_no2 = new FormControl('');
  gst_no = new FormControl('');
  address1 = new FormControl('');
  address2 = new FormControl('');
  dist = new FormControl('');
  state = new FormControl('');
  pin_code = new FormControl('');
  opening_balance = new FormControl('');


  itemList: any;



  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
    ) {
      this.itemList = [] ;
    this.getDetails(localStorage.getItem('activeid'));
   }

  ngOnInit(){

  }

  getDetails(id){
    this.spinner.show();
    this.dataService.GetDetails('suppliers/', id).subscribe((data) => {
      this.spinner.hide();
      const anyData = data as any;
      console.log(JSON.stringify(anyData.user));

      this.itemList = anyData.supplier;
      this.s_name.setValue(this.itemList.s_name);
      this.c_name.setValue(this.itemList.c_name);
      this.email.setValue(this.itemList.email);
      this.mobile_no.setValue(this.itemList.mobile_no);
      this.mobile_no2.setValue(this.itemList.mobile_no2);
      this.gst_no.setValue(this.itemList.gst_no);
      this.address1.setValue(this.itemList.address1);
      this.address2.setValue(this.itemList.address2);
      this.dist.setValue(this.itemList.dist);
      this.state.setValue(this.itemList.state);
      this.pin_code.setValue(this.itemList.pin_code);
      this.opening_balance.setValue(this.itemList.opening_balance);
     
    }, error =>{
      this.spinner.hide();
      showDialog3();
    });
  }

  onSubmit(form: NgForm) {
    if(!this.s_name.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Supplier Name is Required'
      });
    }else if(!this.mobile_no.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Mobile Number is Required'
      });
    }else  if(!this.email.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Email is Required'
      });
    }else if(!this.address1.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Address 1 is Required'
      });
    }else if(!this.dist.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'District is Required'
      });
    }else if(!this.state.value){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'State is Required'
      });
    }else{
      const datatosend = {
        "s_name":this.s_name.value,
        "c_name":this.c_name.value,
        "gst_no":this.gst_no.value,
        "mobile_no2":this.mobile_no2.value,
        "email":this.email.value,
        "opening_balance": this.opening_balance.value,
        "mobile_no":this.mobile_no.value,
          "address":
            {
            "address1":this.address1.value,
            "address2":this.address2.value,
            "state":this.state.value,
            "pin_code":this.pin_code.value,
            "dist":this.dist.value
            }
        }
        this.spinner.show();
            this.dataService.Edit('suppliers/',datatosend, localStorage.getItem('activeid')).subscribe((data) => {
              this.spinner.hide();
              const anyData = data as any;
              if(anyData.status === 200){
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Supplier Updated Successfully'
                });
              }else{        
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: anyData.error[Object.keys(anyData.error)[0]]
                });

              }
              // showDialog6();
            }, error => {
              this.spinner.hide();
              Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'Please Try Again'
              });
            });

    }
    
    

  }

}

